import { createContext, useContext } from 'react';

export interface PersistContextType {
  get: (key: string) => Promise<string | null>;
  set: (key: string, value: string) => Promise<void>;
  remove: (key: string) => Promise<void>;
}

export const PersistContext = createContext<PersistContextType | null>(null);

export const usePersistContext = () => useContext(PersistContext) as PersistContextType;
