import { useQuery as useRqQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { get } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { QUERY_KEYS } from '@/core/lib/query/query.context';
import type { UsersAuthResult } from '@/core/types/auth';

const useRenewAccessToken = () => {
  const { DONNONS_API_URL } = useEnvContext();
  const { renew, logout, accessToken } = useAuthContext();

  const [shouldQuery, setShouldQuery] = useState(false);

  const query = useRqQuery({
    queryKey: QUERY_KEYS.tokenRenew(accessToken as string),
    queryFn: () => get<UsersAuthResult>(`${DONNONS_API_URL}${ApiVersions.V1}/token/renew`, accessToken),
    enabled: !!accessToken && shouldQuery,
  });

  useEffect(() => {
    if (query.isError) {
      const logoutOnError = async () => {
        await logout();
      };

      logoutOnError();
    }
  }, [query.isError]);

  useEffect(() => {
    if (query.isSuccess && query.data) {
      setShouldQuery(false);

      const renewOnSuccess = async (data: UsersAuthResult) => {
        await renew({
          accessToken: data.access_token,
          accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
          refreshToken: null,
          refreshExpiresAt: null,
        });
      };

      renewOnSuccess(query.data);
    }
  }, [query.isSuccess, query.data]);

  return { ...query, mutate: () => setShouldQuery(true) };
};

export default useRenewAccessToken;
