import type { MessagePayload } from 'firebase/messaging';
import { getMessaging, getToken, isSupported as isMessagingSupported, onMessage } from 'firebase/messaging';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import firebaseApp from '@/core/lib/notification/firebase';
import type { NotificationContextType, NotificationData, RegisterArgs } from '@/core/lib/notification/notification.context';
import { NotificationContext, PushActionType } from '@/core/lib/notification/notification.context';

const webPushToNotificationData = (data?: MessagePayload | { FCM_MSG: MessagePayload }): NotificationData | null => {
  if (!data) {
    return null;
  }

  const toCheck = 'FCM_MSG' in data ? data.FCM_MSG.data : data.data;

  switch (toCheck?.type) {
    case PushActionType.MSG_GIVER:
      return { uuid: toCheck.uuid, conversation: Number(toCheck.conversation), type: PushActionType.MSG_GIVER };

    case PushActionType.MSG_TAKER:
      return { uuid: toCheck.uuid, conversation: Number(toCheck.conversation), type: PushActionType.MSG_TAKER };

    case PushActionType.SAVED_SEARCH:
      return { uuid: toCheck.uuid, donation: Number(toCheck.donation), type: PushActionType.SAVED_SEARCH };

    case PushActionType.SAVED_SEARCHES:
      return { uuid: toCheck.uuid, type: PushActionType.SAVED_SEARCHES };

    default:
      return null;
  }
};

const NotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [fnRef, setFnRef] = useState<null | ((event: MessageEvent) => void)>(null);

  useEffect(() => {
    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registrations = await navigator.serviceWorker.getRegistrations();

          const isFirebaseInstalled = registrations
            .map(async registration => {
              if (registration.active?.scriptURL.includes('firebase')) {
                await registration.update();
                return true;
              }
              await registration.unregister();
              return false;
            })
            .some(b => b);

          if (!isFirebaseInstalled) {
            try {
              await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                scope: '/',
              });
            } catch (err) {
              console.error(err);
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    };

    registerServiceWorker();
  }, []);

  const register = useCallback(async ({ setToken, pushNotification }: RegisterArgs) => {
    const isSupported = await isMessagingSupported();

    // eslint-disable-next-line no-constant-binary-expression,valid-typeof
    if (typeof window !== undefined && 'Notification' in window && isSupported) {
      let granted: boolean = false;

      if (typeof Notification === 'undefined' || Notification.permission === 'default') {
        granted = false;
      } else if (Notification.permission === 'granted') {
        granted = true;
      } else {
        const res = await Notification.requestPermission();

        if (res === 'granted') {
          granted = true;
        }
      }

      if (granted) {
        const messaging = getMessaging(firebaseApp);

        try {
          const value = await getToken(messaging);

          setToken(value);

          onMessage(messaging, async message => {
            const push = webPushToNotificationData(message);
            if (push) pushNotification(push);
          });

          const handle = (event: MessageEvent) => {
            if (event?.data?.action === 'notificationclick-sw') {
              const push = webPushToNotificationData(event.data.action.data);
              if (push) pushNotification(push);
            }
          };

          setFnRef(handle);

          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', handle);
          }
        } catch {
          // do nothing
        }
      }
    }
  }, []);

  const unregister = useCallback(async () => Promise.resolve(), []);

  useEffect(() => {
    return () => {
      if (fnRef && 'serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', fnRef);
      }
    };
  }, [fnRef]);

  const requestPermission = async () => {
    if (typeof Notification === 'undefined') {
      return 'denied';
    }

    return Notification.requestPermission();
  };

  const getIsReceiveGrantedAndIsDenied = async () => {
    const permission = await requestPermission();
    return { isGranted: permission === 'granted', isDenied: permission === 'denied' || permission === 'default' };
  };

  const value = useMemo(() => ({ register, unregister, getIsReceiveGrantedAndIsDenied, requestPermission }) satisfies NotificationContextType, []);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
