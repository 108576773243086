import Head from 'next/head';
import React from 'react';

import { useEnvContext } from '@/core/lib/env/env.context';
import { useNativeContext } from '@/core/lib/native/native.context';
import { useRouterContext } from '@/core/lib/router/router.context';
import type { SeoMeta } from '@/core/types/meta';

interface MetaTitleProps {
  meta: SeoMeta;
}

const MetaTitle: React.FC<MetaTitleProps> = ({ meta }) => {
  const { isNative } = useNativeContext();
  const { BASE_URL } = useEnvContext();
  const { currentPathname } = useRouterContext();

  if (isNative) {
    return null;
  }

  return (
    <Head>
      <title key="title">{meta.title}</title>
      {meta.canonical && (
        <>
          <link key="canonical" rel="canonical" href={`${BASE_URL}${currentPathname}${meta.canonical}`} />
          <link key="alternate" rel="alternate" hrefLang="fr" href={`${BASE_URL}${currentPathname}${meta.canonical}`} />
        </>
      )}
      <meta key="description" name="description" lang="fr" content={meta.description} />

      <meta key="og-title" property="og:title" content={meta.title} />
      <meta key="og-description" property="og:description" content={meta.description} />
      <meta key="og-image" property="og:image" content={`${BASE_URL}${meta.image}`} />

      {/* Twitter */}
      <meta key="twitter-title" name="twitter:title" content={meta.title} />
      <meta key="twitter-description" name="twitter:description" content={meta.description} />
      <meta key="twitter-image" name="twitter:image:src" content={meta.image} />
    </Head>
  );
};

export default MetaTitle;
