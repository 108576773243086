import { debounce, isEqual } from 'lodash';
import type { ForwardedRef } from 'react';
import { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import useDisclosure from '@/core/hooks/useDisclosure';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import useOnClickOutside from '@/core/hooks/useOnClickOutside';
import type { LocDomain } from '@/core/lib/new-architecture/domain/locs.domain';
import Store from '@/core/lib/new-architecture/store';
import type { NewInputProps } from '@/core/types/components';
import type { LocationForm } from '@/core/types/geo';

type UseGeoInputParams = NewInputProps<LocationForm | null> & {
  forwardedRef: ForwardedRef<HTMLInputElement>;
  type: 'search' | 'limited';
  value: LocationForm | null;
};

const useGeoInput = (params: UseGeoInputParams) => {
  const { forwardedRef, type, onChange, onBlur, value, ...rest } = params;

  const isLg = useIsLg();
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSearching, setIsSearching] = useState(false);

  const [selectedItem, setSelectedItem] = useState<LocDomain | null>(null);
  const [stagedItem, setStagedItem] = useState<LocDomain | null>(null);

  const [distance, setDistance] = useState(20);
  const onChangeDistance = (newDistance: number) => setDistance(newDistance);
  useEffect(() => {
    if (stagedItem && stagedItem?.getLocationFormDistance() !== distance) {
      setStagedItem(prev => {
        const clone = prev?.clone();
        clone?.setLocationFormDistance(distance);
        return clone ?? null;
      });
    }

    // to trigger the onChange on radius change on mobile
    if (stagedItem && selectedItem && stagedItem.getLocationFormDistance() !== selectedItem.getLocationFormDistance() && !isLg) {
      onChange?.(stagedItem.getLocationForm());
      onBlur?.(stagedItem.getLocationForm());
    }
  }, [distance, stagedItem]);

  const isStageAndSelectedEqual = useMemo(() => isEqual(stagedItem?.getLocationForm(), selectedItem?.getLocationForm()), [stagedItem, selectedItem, distance]);

  const { data } = Store.locs.useDetail(value?.loc);

  useEffect(() => {
    if (value && data) {
      data.setLocationFormDistance(value.distance);
      setDistance(value.distance ?? 0);
      setSelectedItem(data);
      setStagedItem(data);
    }
  }, [value, data]);

  useImperativeHandle(forwardedRef, () => inputRef.current as HTMLInputElement);

  const [search, setSearch] = useState('');

  const { data: current } = Store.current.useCurrent();
  const { data: locs, isLoading } = Store.locs.useLoc({ search, range: type === 'limited' ? 'limited' : 'large' });

  const onSearch = debounce((searchVal: string) => {
    if (searchVal === '') {
      setIsSearching(false);
    } else {
      setIsSearching(true);
    }
    setSearch(searchVal);
  }, 500);

  useOnClickOutside([inputRef, divRef], () => {
    if (data && value) {
      data.setLocationFormDistance(value.distance);
      setStagedItem(data);
    } else if (!value) {
      setStagedItem(value);
    }
    setIsSearching(false);
    onClose();
  });

  const onSelect = (selected: LocDomain) => (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();

    setIsSearching(false);

    setSearch('');
    if (type === 'search') {
      setStagedItem(selected);
    } else if (type === 'limited') {
      onChange?.(selected.getLocationForm());
      onBlur?.(selected.getLocationForm());
      onClose();
    }
  };

  const onClean = () => {
    setSelectedItem(null);
    setIsSearching(false);
    setStagedItem(null);
    onChange?.(null);
    onBlur?.(null);
    if (isLg) {
      inputRef.current?.focus();
      onOpen();
    }
  };

  const items = locs?.getLocs() ?? null;
  const userItem = current?.getLoc() ?? null;

  return {
    isOpen,
    isSearching,
    isStageAndSelectedEqual,
    isLoading,
    onOpen,
    onClose,
    selectedItem,
    stagedItem,
    userItem,
    items,
    search,
    onSelect,
    onSearch,
    onClean,
    onChangeDistance,
    refs: { inputRef, divRef },
    ...rest,
  };
};

export default useGeoInput;
