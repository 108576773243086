import { useCallback, useState } from 'react';

export interface UseDisclosureReturn {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onToggle: () => void;
}

const useDisclosure = (defaultIsOpen?: boolean): UseDisclosureReturn => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
};

export default useDisclosure;
