const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

const computeDistance = ({ lat: lat1, lon: lon1 }: { lat: number; lon: number }, { lat: lat2, lon: lon2 }: { lat: number; lon: number }): number | null => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  const distance = d - (d % 1); // distance en km entiers
  return distance;
};

export default computeDistance;
