import { createContext, useContext } from 'react';

export enum FFName {
  DonationRequests = 'DONATION_REQUESTS',
}
export interface FFContextType {
  ff: (name: FFName) => boolean;
}

export const FFContext = createContext<FFContextType | null>(null);

export const useFFContext = () => useContext(FFContext) as FFContextType;
