import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const CheckSquare: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M15 18.3333L20 23.3333L36.6667 6.66663" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M35 20V31.6667C35 32.5507 34.6488 33.3986 34.0237 34.0237C33.3986 34.6488 32.5507 35 31.6667 35H8.33333C7.44928 35 6.60143 34.6488 5.97631 34.0237C5.35119 33.3986 5 32.5507 5 31.6667V8.33333C5 7.44928 5.35119 6.60143 5.97631 5.97631C6.60143 5.35119 7.44928 5 8.33333 5H26.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckSquare;
