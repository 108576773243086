import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Filter: React.FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 1H1.00002L9.00002 10.46V17L13 19V10.46L21 1Z" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Filter;
