import useMutation from '@/core/hooks/api/useMutation';
import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import getToken from '@/core/lib/new-architecture/query/token';
import type { Mutation, Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/user', '/device'] as const,
  register: (device: string | null) => [...keys.base, device] as const,
};

const useRegister: Query<null, { device: string | null }> = ({ data: { device }, token }) => {
  return useQuery({
    queryKey: keys.register(device),
    queryFn: async () => Api.mutation.push.register(device as string, await getToken(token)),
    enabled: !!device,
  });
};

const useUnregister: Mutation<
  null,
  {
    device: string;
    token: string;
  }
> = () => {
  return useMutation({
    mutationFn: ({ device, token }) => Api.mutation.push.unregister(device, token),
  });
};

export default { useRegister, useUnregister };
