import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const CrossHair: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M19.9999 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33337 19.9999 3.33337C10.7952 3.33337 3.33325 10.7953 3.33325 20C3.33325 29.2048 10.7952 36.6667 19.9999 36.6667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M36.6667 20H30" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.99992 20H3.33325" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 10V3.33337" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 36.6667V30" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CrossHair;
