import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Block: React.FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.21667 8.21673L31.7833 31.7834M36.6666 20.0002C36.6666 29.2049 29.2047 36.6668 19.9999 36.6668C10.7952 36.6668 3.33325 29.2049 3.33325 20.0002C3.33325 10.7954 10.7952 3.3335 19.9999 3.3335C29.2047 3.3335 36.6666 10.7954 36.6666 20.0002Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Block;
