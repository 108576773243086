import { createContext, useContext } from 'react';

import type { ToastStatus } from '@/core/components/Toast/Toast';

export interface ToastContextType {
  open: (content: string, delay: number, status: ToastStatus) => void;
}

export const ToastContext = createContext<ToastContextType | null>(null);

export const useToastContext = () => useContext(ToastContext);
