import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

interface RenewOrRefreshParams {
  accessExpiresAt: Dayjs | null;
  refreshExpiresAt: Dayjs | null;
}

interface RenewOrRefreshReturn {
  shouldRenew: boolean;
  shouldRefresh: boolean;
  isAccessValid: boolean;
  isRefreshValid: boolean;
}

const renewOrRefresh = ({ accessExpiresAt, refreshExpiresAt }: RenewOrRefreshParams): RenewOrRefreshReturn => {
  const timeBeforeRefreshExpiration = refreshExpiresAt?.isValid() ? (refreshExpiresAt.diff(dayjs(), 'minute') ?? 0) : 0;
  const isRefreshValid = timeBeforeRefreshExpiration > 0;

  const timeBeforeAccessExpiration = accessExpiresAt?.diff(dayjs(), 'minute') ?? 0;
  const isAccessValid = timeBeforeAccessExpiration > 0;

  if (accessExpiresAt) {
    // if there is less than 45 minutes before the access expires we renew it
    if (timeBeforeAccessExpiration >= 1 && timeBeforeAccessExpiration <= 45) {
      return { shouldRenew: true, shouldRefresh: false, isAccessValid, isRefreshValid };
    }

    // if there is more than 45 minutes before the access expires we don't do anything
    if (timeBeforeAccessExpiration > 45) {
      return { shouldRenew: false, shouldRefresh: false, isAccessValid, isRefreshValid };
    }
  }

  // if there is no access expiration date
  // and there is still time before the refresh expires we refresh
  if (timeBeforeRefreshExpiration >= 1) {
    return { shouldRenew: false, shouldRefresh: true, isAccessValid, isRefreshValid };
  }

  return { shouldRenew: false, shouldRefresh: false, isAccessValid, isRefreshValid };
};

export default renewOrRefresh;
