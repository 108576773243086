import { Camera, CameraResultType } from '@capacitor/camera';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import Donate from '@/core/components/Donate';
import HintMessage from '@/core/components/HintMessage';
import Icons from '@/core/components/Icons';
import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import useMutation from '@/core/hooks/useMutation';
import { useEnvContext } from '@/core/lib/env/env.context';
import type { APIError } from '@/core/lib/fetch';
import { image } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { resizeDonationV2 } from '@/core/lib/image/resize';
import { useNativeContext } from '@/core/lib/native/native.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { InputProps } from '@/core/types/components';
import { type UploadResult, uploadResultSchema } from '@/core/types/donnons';

interface PhotoItemProps {
  n: number;
  upload: UploadResult | undefined;
  isLoading: boolean;
  onClick: () => void;
}

const PhotoItem: React.FC<PhotoItemProps> = ({ n, upload, isLoading, onClick }) => {
  const { t } = useTranslationContext(['common']);

  const ref = useRef<HTMLInputElement>(null);

  useKeyDownHandler([ref], 'Enter', () => {
    if (ref.current?.contains(document.activeElement)) {
      onClick();
    }
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      ref={ref}
      role="textbox"
      aria-label={t('inputs.photo.aria', { ns: 'common', n: n.toString() })}
      tabIndex={0}
      className={`relative size-full border-2 border-stroke-primary bg-cover bg-center ${
        upload ? 'border-solid bg-bg-pale' : 'border-dashed bg-bg-primary hover:border-solid hover:bg-bg-pale'
      } flex cursor-pointer items-center justify-center rounded-2 px-3 py-4`}
      onClick={onClick}
      style={{
        backgroundImage: !isLoading && upload ? `url(${upload.url.replace('{{size}}', '2').replace('{{ext}}', 'jpg')}` : undefined,
      }}
    >
      {isLoading && (
        <div className="flex size-12 items-center justify-center">
          <Icons icon="spinner" color="button-default" />
        </div>
      )}

      {!isLoading && !upload && (
        <div className="flex size-12 items-center justify-center">
          <Donate type="empty" />
        </div>
      )}

      {!isLoading && upload && (
        <div className="flex size-12 items-center justify-center">
          <Donate type="clean" />
        </div>
      )}

      <div className="absolute bottom-1 right-1 flex size-4 items-center justify-center rounded-full border-2 border-stroke-button-give-default bg-bg-give">
        <p className="inline-flex items-center justify-center self-center text-center align-middle font-sans text-12 leading-2 text-content-button-primary">{n}</p>
      </div>
    </div>
  );
};

export interface PhotoInputProps extends Omit<InputProps<UploadResult[] | undefined>, 'placeholder' | 'max'> {}

const PhotoInput: React.FC<PhotoInputProps> = ({ id, value, onBlur, onChange }) => {
  const { t } = useTranslationContext(['common']);
  const { DONNONS_API_URL } = useEnvContext();
  const { isNative } = useNativeContext();

  const [uploadError, setUploadError] = useState<string | null>(null);

  const { mutateAsync } = useMutation<UploadResult, FormData>({
    schema: uploadResultSchema,
    mutationFn: async (data, token) => {
      return image<UploadResult, FormData>(`${DONNONS_API_URL}${ApiVersions.V1}/donation/photo`, data, token);
    },
  });

  const [localValue, setLocalValue] = useState<{ upload: UploadResult | undefined; isLoading: boolean }[]>(
    [0, 1, 2, 3, 4].map(i => ((value ?? [])[i] ? { upload: (value ?? [])[i], isLoading: false } : { upload: undefined, isLoading: false })),
  );

  const setNIsLoading = (i: number, loading: boolean) => {
    const copy = [...localValue];
    copy[i] = { ...copy[i], isLoading: loading };
    setLocalValue(copy);
  };

  const setNUuid = (i: number, upload: UploadResult | undefined) => {
    const copy = [...localValue];
    copy[i] = { upload, isLoading: false };
    setLocalValue(copy);
  };

  const setNAreLoading = (nbUploads: number) => {
    let nbUploadsToLoad = nbUploads;
    const photos = localValue.map(uploadResult => {
      if (uploadResult.upload) {
        return uploadResult;
      }

      if (nbUploadsToLoad > 0) {
        nbUploadsToLoad -= 1;
        return { upload: undefined, isLoading: true };
      }
      return { upload: undefined, isLoading: false };
    });
    setLocalValue(photos);
  };

  const setNUuids = (uploads: UploadResult[]) => {
    const photos = localValue.map(uploadResult => {
      if (uploadResult.upload) {
        return uploadResult;
      }
      const isLoading = false;
      if (uploads.length > 0) {
        const upload = uploads.splice(0, 1)[0];
        return { upload, isLoading };
      }
      return { upload: undefined, isLoading };
    });
    setLocalValue(photos);
  };

  useEffect(() => {
    const localValueTransformed = localValue.map(v => v.upload).filter(e => !!e) as UploadResult[];

    if (!isEqual(value, localValueTransformed)) {
      if (onChange) onChange(localValueTransformed);
      if (onBlur) onBlur(localValueTransformed);
    }
  }, [localValue, value]);

  const handleError = (error: Error, index: number) => {
    switch (error.message) {
      case 'MIN_SIZE':
        setUploadError('image_min_size');
        break;
      case 'MAX_SIZE':
        setUploadError('image_max_size');
        break;
      case 'INVALID_FILETYPE':
        setUploadError('image-only');
        break;
      case 'INVALID_FILE_SIZE':
        setUploadError('file_type');
        break;
      case 'MAX-LENGTH':
        setUploadError('image_max_length');
        break;
      case 'RESIZE':
      case 'DEFAULT':
        setUploadError('resize');
        break;
      case 'User denied access to camera':
        setUploadError('camera-denied');
        break;
      case 'User denied access to photos':
        setUploadError('photos-denied');
        break;
      case 'User cancelled photos app':
        // not an error
        break;
      default:
        setUploadError('unknown');
        break;
    }
    setNIsLoading(index, false);
  };

  const onClickForNative = (i: number) => async () => {
    setUploadError(null);

    if (localValue[i].upload) {
      setNUuid(i, undefined);
      return;
    }

    try {
      const capacitorImage = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        promptLabelHeader: t('inputs.photo.promptLabelHeader', { ns: 'common' }),
        promptLabelCancel: t('inputs.photo.promptLabelCancel', { ns: 'common' }),
        promptLabelPhoto: t('inputs.photo.promptLabelPhoto', { ns: 'common' }),
        promptLabelPicture: t('inputs.photo.promptLabelPicture', { ns: 'common' }),
      });

      const blobCheck = await (await fetch(capacitorImage.webPath as string)).blob();
      if (!blobCheck.type.includes('image')) {
        setUploadError('image-only');
        return;
      }

      setNIsLoading(i, true);
      try {
        const blob = await resizeDonationV2(blobCheck);

        const formData = new FormData();
        formData.append('photo', blob, capacitorImage.webPath);
        formData.append('Content-Type', 'image/jpeg');

        try {
          const res = await mutateAsync(formData);

          setNUuid(i, res);
        } catch (err) {
          const errors = (err as APIError).json?.errors;
          if (errors) {
            setUploadError(errors.photo.code);
          }
          setNIsLoading(i, false);
        }
      } catch (error) {
        // setUploadError('resize');
        // setNIsLoading(i, false);
        // if ((err as Error).message.includes('DEFAULT')) {
        //   Bugsnag.notify(err as Error);
        // }

        if ((error as Error).message === 'MIN_SIZE') {
          throw error;
        }

        // we know send it nonetheless, unless it is min size
        try {
          const formData = new FormData();
          formData.append('photo', blobCheck, capacitorImage.webPath);
          formData.append('Content-Type', 'image/jpeg');
          const res = await mutateAsync(formData);

          setNUuid(i, res);
        } catch (err) {
          const errors = (err as APIError).json?.errors;
          if (errors) {
            setUploadError(errors.photo.code);
          }
          setNIsLoading(i, false);
        }
      }
    } catch (err) {
      handleError(err as Error, i);
    }
  };

  const onClickForWebBrowser = (index: number) => async () => {
    setUploadError(null);

    if (localValue[index].upload) {
      setNUuid(index, undefined);
      return;
    }
    try {
      const { photos } = await Camera.pickImages({ limit: 5 });
      const nbLocalValue = localValue.filter(uploadResult => !!uploadResult.upload).length;
      const isMaxLength = photos.length + nbLocalValue > 5;
      if (isMaxLength) throw Error('MAX-LENGTH');

      setNAreLoading(photos.length);
      const promises = photos.map(async photo => {
        const blobCheck = await (await fetch(photo.webPath as string)).blob();
        if (!blobCheck.type.includes('image')) {
          throw Error('INVALID_FILETYPE');
        }

        try {
          const formData = new FormData();
          const blob = await resizeDonationV2(blobCheck);
          formData.append('photo', blob, photo.webPath);
          formData.append('Content-Type', 'image/jpeg');
          return await mutateAsync(formData);
        } catch (error) {
          if ((error as Error).message === 'MIN_SIZE') {
            throw error;
          } else {
            const formData = new FormData();
            formData.append('photo', blobCheck, photo.webPath);
            formData.append('Content-Type', 'image/jpeg');
            return mutateAsync(formData);
          }
        }
      });
      const results = await Promise.all(promises);
      setNUuids(results);
    } catch (error) {
      handleError(error as Error, index);
    }
  };

  const onClick = isNative ? onClickForNative : onClickForWebBrowser;

  return (
    <div className="flex flex-col space-y-2">
      <p className="text-caption-primary font-medium text-content-primary">{t('inputs.photo.label', { ns: 'common' })}</p>
      <div id={id} className="grid w-full grid-cols-3 gap-2 lg:grid-cols-5">
        {localValue.map(({ upload, isLoading }, index) => (
          <div key={`${id}-${upload?.uuid}-${index}`} className="col-span-1 aspect-square">
            <PhotoItem n={index + 1} onClick={onClick(index)} isLoading={isLoading} upload={upload} />
          </div>
        ))}
      </div>
      {uploadError && <HintMessage isError message={t(`inputs.photo.${uploadError}`, { ns: 'common' })} />}
    </div>
  );
};

export default PhotoInput;
