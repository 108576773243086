import * as yup from 'yup';

export const documentsIndexResult = yup.object({
  versions: yup.array(yup.string().required()).required(),
});

export type DocumentsIndexResult = yup.InferType<typeof documentsIndexResult>;

export const documentsFileResult = yup.object({
  content: yup.string().required(),
  version: yup.string().required(),
});

export type DocumentsFileResult = yup.InferType<typeof documentsFileResult>;
