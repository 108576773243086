import React from 'react';

import * as auth from '@/core/lib/new-architecture/context/auth.context';
import * as push from '@/core/lib/new-architecture/context/push.context';

const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <push.PushProvider>
      <auth.AuthProvider>{children}</auth.AuthProvider>
    </push.PushProvider>
  );
};

const Context = {
  auth,
  push,
  ContextProvider,
};

export default Context;
