import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const ArrowUp: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M20 31.6667V8.33337" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.33325 20L19.9999 8.33337L31.6666 20" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArrowUp;
