import * as yup from 'yup';

export enum ReportDonationReasons {
  DONATION_ERROR = 'donation_error',
  DONATION_DANGER = 'donation_danger',
  DONATION_SCAM = 'donation_scam',
  DONATION_OTHER = 'donation_other',
}

export enum ReportUserReasons {
  USER_AVATAR = 'user_avatar',
  USER_NAME = 'user_name',
  USER_MESSAGE = 'user_message',
  USER_OTHER = 'user_other',
}

type ReportReasons = ReportDonationReasons | ReportUserReasons;

export const reportsReportFormSchema = yup.object({
  user: yup.string().optional(),
  donation: yup.number().optional(),
  reasons: yup
    .array(
      yup
        .mixed<ReportReasons>()
        .oneOf([
          ReportDonationReasons.DONATION_ERROR,
          ReportDonationReasons.DONATION_DANGER,
          ReportDonationReasons.DONATION_SCAM,
          ReportDonationReasons.DONATION_OTHER,
          ReportUserReasons.USER_AVATAR,
          ReportUserReasons.USER_NAME,
          ReportUserReasons.USER_MESSAGE,
          ReportUserReasons.USER_OTHER,
        ])
        .required(),
    )
    .required(),
  comment: yup.string().when('reasons', {
    is: (reasons: ReportReasons[]) => reasons.includes(ReportDonationReasons.DONATION_OTHER) || reasons.includes(ReportUserReasons.USER_OTHER),
    then: schema => schema.required(),
    otherwise: schema => schema.optional(),
  }),
});

export type ReportsReportForm = Omit<yup.InferType<typeof reportsReportFormSchema>, 'reasons'> & { reasons: ReportReasons[] };
