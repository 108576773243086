import { useQueryClient } from '@tanstack/react-query';

import useInfiniteQuery from '@/core/hooks/api/useInfiniteQuery';
import { CONVERSATION_SIZE } from '@/core/lib/constants';
import Api from '@/core/lib/new-architecture/api';
import getToken from '@/core/lib/new-architecture/query/token';
import type { InfiniteQuery } from '@/core/lib/new-architecture/query/types.query';

interface ConversationParams {
  id?: number;
  from?: number;
  size?: number;
}

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/conversation'] as const,
  conversation: (data: ConversationParams) => [...keys.base, data.id, data.size, data.from] as const,
};

type AwaitedConversation = Awaited<ReturnType<typeof Api.query.conversation.get>>;

const useConversation: InfiniteQuery<AwaitedConversation, ConversationParams> = ({ data, token, enabled }) => {
  return useInfiniteQuery({
    queryKey: keys.conversation(data),
    queryFn: async ({ pageParam }) => Api.query.conversation.get({ id: data.id!, from: pageParam as number, size: data.size ?? CONVERSATION_SIZE }, await getToken(token)),
    retry: 0,
    initialPageParam: data.from ?? 0,
    getNextPageParam: lastPage => {
      const lastLength = lastPage.messages.length;

      if (lastLength === 0) return undefined;

      const size = data.size ?? CONVERSATION_SIZE;
      return (data.from ?? 0) + size;
    },
    enabled: enabled && !!data.id,
    refetchInterval: 1000 * 30, // stale time of 30 seconds
    refetchOnMount: 'always',
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  return { invalidate };
};

export default { useConversation, useInvalidate };
