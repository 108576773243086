import type React from 'react';
import type { AriaAttributes, HTMLAttributeAnchorTarget } from 'react';
import { createContext, useContext } from 'react';

import type { AbstractRoute } from '@/core/lib/router/route';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}
interface NextHistoryState {
  url: string;
  as: string;
  options: TransitionOptions;
}

type BeforePopStateCallback = (state: NextHistoryState) => boolean;

export enum RoutesEnum {
  HOME = '/',

  // taskbar
  CATALOGUE = '/catalogue',
  CATALOGUE_CATEGORY_LOC = '/catalogue/:cat/:loc',
  CATALOGUE_CATEGORY = '/catalogue/categorie/:cat',
  CATALOGUE_LOC = '/catalogue/ville/:loc',

  FAVORITES = '/favoris',
  CONVERSATIONS = '/conversations',
  USER = '/utilisateur',

  DONATION = '/don/:id',
  MODIFY_DONATION = '/don/:id/modifier',

  CONVERSATION = '/conversation/:id',
  CONVERSATION_FROM_DONATION = '/conversation/donation/:donation',

  // profil
  PROFILE = '/profil',
  PUBLIC_PROFILE = '/profil/public/:uuid',
  HELP = '/profil/aide',
  COUNTER = '/profil/compteur',
  POSTS = '/profil/mes-annonces',
  SETTINGS = '/profil/parametres',
  MODIFY = '/profil/modifier',
  MODIFY_NOTIFICATIONS = '/profil/notifications',
  MODIFY_EMAIL = '/profil/email',
  MODIFY_EMAIL_CODE = '/profil/email/code',
  MODIFY_PASSWORD = '/profil/mot-de-passe',
  DELETE_ACCOUNT = '/profil/supprimer-mon-compte',

  // links
  CGU = '/cgu',

  // TO-DO remove
  ABOUT = '/about',

  CALLBACK_GOOGLE = '/authentification/callback/google',
  CALLBACK_APPLE = '/authentification/callback/apple',
  CALLBACK_FACEBOOK = '/authentification/callback/facebook',
}

export interface LinkProps extends AriaAttributes {
  children: React.ReactNode;
  href: AbstractRoute;
  className?: string;
  target?: HTMLAttributeAnchorTarget;
  shallow?: boolean;
}

export interface PushParams {
  [key: string]: string;
}

export interface RouterQuery {
  [key: string]: string;
}

export interface RouterContextType {
  back: (fallback?: AbstractRoute) => void;
  push: (route: AbstractRoute) => void;
  replace: (route: AbstractRoute) => void;
  Link: React.FC<LinkProps>;
  currentPage: AbstractRoute;
  currentPathname: string;
  query: RouterQuery;
  beforePopState?: (cb: BeforePopStateCallback) => void;
}

export const RouterContext = createContext<RouterContextType | null>(null);

export const useRouterContext = () => useContext(RouterContext) as RouterContextType;
