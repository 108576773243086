import React from 'react';

import Icons from '@/core/components/Icons';
import type { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';

export interface MenuItemProps {
  leftIcon?: React.ReactElement;
  content: string;
  href: AbstractRoute;
  border?: boolean;
  noPadding?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ href, leftIcon, content, border, noPadding }) => {
  const { Link } = useRouterContext();
  return (
    <Link href={href} className={`flex w-full items-center justify-between space-x-1 ${border ? 'border-b' : ''} border-stroke-tertiary bg-bg-primary ${noPadding ? 'py-4' : 'p-4'}`}>
      <div className="flex items-center space-x-3">
        <div>{leftIcon}</div>
        <div className="flex flex-col items-start">
          <p className="text-body-secondary font-medium text-content-primary">{content}</p>
        </div>
      </div>
      <div className="mx-2 flex items-center">
        <Icons icon="chevron-right" size="20" />
      </div>
    </Link>
  );
};

export default MenuItem;
