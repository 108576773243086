import React, { forwardRef, useEffect } from 'react';

import useTextInput from '@/core/hooks/inputs/useTextInput';
import type { NewInputProps } from '@/core/types/components';

interface TextAreaInputProps extends NewInputProps<string> {}

const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputProps>((props, forwardedRef) => {
  const {
    isFocused,
    isError,
    isSuccess,
    isCleanable,
    isDisabled,
    cleanAttrs: { onClick: onClean },
    ...attrs
  } = useTextInput<TextAreaInputProps, HTMLTextAreaElement>({ forwardedRef, ...props });

  const isFocusedClasses = isFocused
    ? 'bg-bg-primary ring-2 ring-stroke-default-focus/20 border border-stroke-default-focus'
    : `${isCleanable || isSuccess ? 'border-stroke-secondary' : 'border border-bg-secondary'}`;

  const isErrorClasses = isError ? 'border !border-stroke-danger-focus ring-2 !ring-stroke-danger-focus/20' : '';

  const withValueClasses = isCleanable || isSuccess ? `bg-bg-primary pr-8 border ${isFocused ? 'border-stroke-default-focus' : 'border-stroke-secondary'}` : 'pr-3';

  const isDisabledClasses = isDisabled ? 'border-transparent bg-bg-pale' : '';

  useEffect(() => {
    if (isDisabled) {
      onClean();
    }
  }, [isDisabled]);

  return (
    <div className="relative w-full">
      <textarea
        className={`text-body-secondary placeholder:text-body-secondary z-0 w-full rounded-2 bg-bg-primary py-2 pl-3 text-16 font-normal text-content-primary outline-none placeholder:font-normal placeholder:text-content-placeholder ${isFocusedClasses} ${isErrorClasses} ${withValueClasses} ${isDisabledClasses}`}
        {...attrs}
      />
    </div>
  );
});

export default TextAreaInput;
