import auth from '@/core/lib/new-architecture/store/auth.store';
import catalogue from '@/core/lib/new-architecture/store/catalogue.store';
import categories from '@/core/lib/new-architecture/store/categories.store';
import circular from '@/core/lib/new-architecture/store/circular.store';
import conversationCreate from '@/core/lib/new-architecture/store/conversation.create.store';
import conversation from '@/core/lib/new-architecture/store/conversation.store';
import conversations from '@/core/lib/new-architecture/store/conversations.store';
import current from '@/core/lib/new-architecture/store/current.store';
import documents from '@/core/lib/new-architecture/store/documents.store';
import donation from '@/core/lib/new-architecture/store/donation.store';
import favorites from '@/core/lib/new-architecture/store/favorites.store';
import home from '@/core/lib/new-architecture/store/home.store';
import locs from '@/core/lib/new-architecture/store/locs.store';
import suggestion from '@/core/lib/new-architecture/store/suggestion.store';
import user from '@/core/lib/new-architecture/store/user.store';
import userDonations from '@/core/lib/new-architecture/store/userDonations.store';

const Store = {
  auth,
  categories,
  user,
  donation,
  catalogue,
  circular,
  current,
  documents,
  conversation: {
    ...conversation,
    create: conversationCreate,
  },
  conversations,
  userDonations,
  favorites,
  locs,
  home,
  suggestion,
};

export default Store;
