import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Lock: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M11.6667 18.3333V11.6666C11.6667 9.45645 12.5447 7.33683 14.1075 5.77403C15.6703 4.21123 17.7899 3.33325 20 3.33325C22.2102 3.33325 24.3298 4.21123 25.8926 5.77403C27.4554 7.33683 28.3334 9.45645 28.3334 11.6666V18.3333M8.33333 18.3333H31.6667C33.5076 18.3333 35 19.8256 35 21.6666V33.3333C35 35.1742 33.5076 36.6666 31.6667 36.6666H8.33333C6.49238 36.6666 5 35.1742 5 33.3333V21.6666C5 19.8256 6.49238 18.3333 8.33333 18.3333Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Lock;
