import type { HTMLAttributes } from 'react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import type { IconName } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { InputProps } from '@/core/types/components';

export interface TextInputProps extends InputProps<string> {
  leftIcon?: React.ReactElement;
  rightIconName?: IconName;
  onClick?: () => void;
  isValid?: boolean;
  type?: 'text' | 'email';
  autocomplete?: 'name' | 'email' | 'username' | 'address-level2';
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  readOnly?: boolean;
  onClean?: () => void;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      inputMode,
      readOnly,
      rightIconName,
      autocomplete,
      id,
      value,
      placeholder,
      type = 'text',
      max = 100,
      validation,
      isValid,
      leftIcon,
      disabled = false,
      onClick,
      onBlur,
      onChange,
      onClean,
      enterKeyHint = 'next',
      onFocus,
    },
    ref,
  ) => {
    const { t } = useTranslationContext(['common']);
    const [localValue, setLocalValue] = useState<string>(value);
    const divRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const handleParentFocus = (event: React.MouseEvent) => {
      if (divRef.current?.contains(event.target as Node)) {
        inputRef.current?.focus();
      }
    };

    const cleanInput = () => {
      setLocalValue('');
      if (onChange) onChange('');
      if (onBlur) onBlur('');
      if (onClean) onClean();
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event.target.value);
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalValue(event.target.value);
      if (onChange) {
        onChange(event.target.value);
      }
    };

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          ref={divRef}
          className={`flex w-full rounded-2 border py-2 ${value ? 'bg-primary border-content-placeholder' : 'border-bg-secondary bg-bg-primary'} focus-within:bg-primary p-2 focus-within:ring-2 ${
            validation?.isError && !isValid ? '!border-stroke-danger-focus ring-2 !ring-stroke-danger-focus/20' : ''
          } p-1 focus-within:border-stroke-default-focus focus-within:bg-bg-primary focus-within:ring-stroke-default-focus/20 ${disabled ? 'border-transparent bg-bg-pale' : ''}`}
          onClick={handleParentFocus}
        >
          {!disabled && leftIcon && <div className="ml-2 flex items-center">{leftIcon}</div>}
          <input
            id={id}
            className={`${
              readOnly ? 'hover:cursor-pointer' : 'hover:cursor-auto'
            } text-body-secondary placeholder:text-body-secondary disabled:placeholder:text-accent ml-2 w-full bg-transparent text-content-primary placeholder:font-normal placeholder:text-content-placeholder focus:outline-none disabled:text-content-disable`}
            placeholder={placeholder}
            aria-label={placeholder}
            autoComplete={autocomplete}
            type={type}
            value={localValue}
            maxLength={max}
            onBlur={handleBlur}
            ref={inputRef}
            onChange={handleChange}
            disabled={disabled}
            autoCapitalize="off"
            inputMode={inputMode}
            onClick={onClick}
            readOnly={readOnly}
            enterKeyHint={enterKeyHint}
            onFocus={onFocus}
          />
          {!disabled && !readOnly && localValue !== '' && (
            <div className="mx-2 flex items-center">
              <Icons icon="close" label={t('inputs.text.clean', { ns: 'common' })} size="20" color="placeholder" onClick={cleanInput} />
            </div>
          )}
          {rightIconName && (
            <div className="mx-2 flex items-center">
              <Icons icon={rightIconName} size="20" />
            </div>
          )}
        </div>
      </>
    );
  },
);

export default TextInput;
