import { useMutation as useRqMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { post } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import type { UsersAuthResult, UsersAuthTokenForm } from '@/core/types/auth';

const useRefreshAccessToken = () => {
  const { DONNONS_API_URL } = useEnvContext();
  const { renew, logout } = useAuthContext();

  return useRqMutation<UsersAuthResult, Error, UsersAuthTokenForm>({
    mutationFn: data => post<UsersAuthResult, UsersAuthTokenForm>(`${DONNONS_API_URL}${ApiVersions.V1}/token/refresh`, data),
    onSuccess: async data => {
      await renew({
        accessToken: data.access_token,
        accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
        refreshToken: null,
        refreshExpiresAt: null,
      });
    },
    onError: async () => {
      await logout();
    },
  });
};

export default useRefreshAccessToken;
