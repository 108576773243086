import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { ReportsReportForm } from '@/core/types/report';
import { reportsReportFormSchema } from '@/core/types/report';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const mutation = {
  report: async (data: ReportsReportForm, token: string) =>
    api.post<null, ReportsReportForm>({
      url: `${getUrl()}${ApiVersions.V1}/report`,
      schema: reportsReportFormSchema,
      data,
      token,
    }),
};

export default { mutation };
