import type { GeoResult, LocationForm, LocResult, PointResult } from '@/core/types/geo';

export interface LocDomainConstructor {
  data: LocResult;
}

export interface LocDomainInterface {
  getLabel: () => string | null;
  getLoc: () => string;
  getLocationForm: () => LocationForm;
  getLocationFormDistance: () => number;
  getLatLon: () => PointResult | null;

  isCountry: () => boolean;
  hasRadius: () => boolean;

  setLocationFormDistance: (distance?: number) => void;

  clone: () => LocDomainInterface;
}

export class LocDomain implements LocDomainInterface {
  private data: LocResult;

  private locationFormDistance = 20;

  constructor({ data }: LocDomainConstructor) {
    this.data = data;
  }

  public getLabel(): string | null {
    if (this.data.sub?.name) return this.data.sub.name;

    if (this.data.city?.name) {
      if (this.data.admin?.name) {
        return `${this.data.city.name} (${this.data.admin.name})`;
      }

      return this.data.city.name;
    }

    if (this.data.admin?.name) return this.data.admin.name;

    return this.data.country.name;
  }

  public getLoc(): string {
    if (this.data.sub) {
      return this.data.sub.alias;
    }

    if (this.data.city) {
      return this.data.city.alias;
    }

    if (this.data.admin) {
      return this.data.admin.alias;
    }

    return this.data.country.alias;
  }

  public getLatLon(): PointResult | null {
    if (!this.data.location) {
      return null;
    }
    return this.data.location;
  }

  public getLocationForm(): LocationForm {
    return {
      loc: this.getLoc(),
      lat: this.data.location?.lat,
      lon: this.data.location?.lon,
      distance: this.hasRadius() ? this.locationFormDistance : undefined,
      label: this.getLabel() ?? '',
      refId: this.data?.city?.ref_type === 'INSEE' ? (this.data?.city?.ref_id ?? undefined) : undefined,
    };
  }

  public isCountry(): boolean {
    return !this.data.sub && !this.data.city && !this.data.admin;
  }

  public hasRadius(): boolean {
    return !!this.data.sub || !!this.data.city;
  }

  public getLocationFormDistance(): number {
    return this.locationFormDistance;
  }

  public setLocationFormDistance(distance?: number): void {
    this.locationFormDistance = distance ?? 20;
  }

  public clone(): LocDomain {
    return new LocDomain({ data: this.data });
  }
}

export interface LocsDomainConstructor {
  data: GeoResult;
}

export interface LocsDomainInterface {
  getLocs: () => LocDomain[];
}

export class LocsDomain implements LocsDomainInterface {
  private data: GeoResult;

  constructor({ data }: LocsDomainConstructor) {
    this.data = data;
  }

  public getLocs() {
    return (this.data.results ?? []).reduce((acc, result) => {
      const domain = new LocDomain({ data: result });

      if (domain.isCountry()) {
        return acc;
      }

      return [...acc, new LocDomain({ data: result })];
    }, [] as LocDomain[]);
  }
}
