import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const CheckCircle: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M36.6666 18.4667V20.0001C36.6645 23.5941 35.5008 27.0912 33.3488 29.9698C31.1969 32.8484 28.1721 34.9542 24.7255 35.9732C21.279 36.9923 17.5954 36.8699 14.224 35.6244C10.8527 34.3788 7.97434 32.0769 6.01819 29.0618C4.06203 26.0468 3.1329 22.4802 3.36938 18.8939C3.60586 15.3077 4.99526 11.8939 7.33038 9.16185C9.66551 6.42976 12.8212 4.5257 16.3269 3.73364C19.8326 2.94157 23.5004 3.30395 26.7833 4.76673"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M36.6667 6.66663L20 23.35L15 18.35" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CheckCircle;
