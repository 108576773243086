import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import type { Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/loc'] as const,
  large: (search: string, size?: number) => [...keys.base, '/large', search, size] as const,
  limited: (search: string, size?: number) => [...keys.base, '/limited', search, size] as const,
  detail: (search: string) => [...keys.base, '/detail', search] as const,
};

interface UseLargeParams {
  search?: string;
  size?: number;
}

const useLarge: Query<Awaited<ReturnType<typeof Api.query.locs.large>>, UseLargeParams> = ({ data: { search, size }, enabled }) => {
  return useQuery({
    queryKey: keys.large(search!, size),
    queryFn: () => Api.query.locs.large({ search: search!, size }),
    enabled: !!search && enabled,
    retry: 0,
  });
};

useLarge.prefetch = async ({ queryClient, ...data }) => {
  if (data.search) {
    await queryClient.prefetchQuery({
      queryKey: keys.large(data.search!, data.size),
      queryFn: () => Api.query.locs.large({ search: data.search!, size: data.size }),
    });
  }
};

interface UseLimitedParams {
  search?: string;
  size?: number;
}

const useLimited: Query<Awaited<ReturnType<typeof Api.query.locs.limited>>, UseLimitedParams> = ({ data: { search, size }, enabled }) => {
  return useQuery({
    queryKey: keys.limited(search!, size),
    queryFn: () => Api.query.locs.limited({ search: search!, size }),
    enabled: !!search && enabled,
    retry: 0,
  });
};

interface UseDetailParams {
  search?: string;
}

const useDetail: Query<Awaited<ReturnType<typeof Api.query.locs.detail>>, UseDetailParams> = ({ data: { search } }) => {
  return useQuery({
    queryKey: keys.detail(search!),
    queryFn: () => Api.query.locs.detail(search!),
    enabled: !!search,
    retry: 0,
  });
};

useDetail.prefetch = async ({ queryClient, ...data }) => {
  if (data.search) {
    await queryClient.prefetchQuery({
      queryKey: keys.detail(data.search!),
      queryFn: async () => Api.query.locs.detail(data.search!),
    });
  }
};

export default { useLarge, useLimited, useDetail };
