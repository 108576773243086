import { useQueryClient } from '@tanstack/react-query';

import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import type { ConversationsQuery } from '@/core/lib/new-architecture/api/conversations.api';
import getToken from '@/core/lib/new-architecture/query/token';
import type { Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/conversations'] as const,
  conversations: (data: ConversationsQuery) => [...keys.base, JSON.stringify(data)] as const,
};

const useConversations: Query<Awaited<ReturnType<typeof Api.query.conversations.get>>, ConversationsQuery> = ({ data, token, enabled }) => {
  return useQuery({
    queryKey: keys.conversations(data),
    queryFn: async () => Api.query.conversations.get(data, await getToken(token)),
    retry: 0,
    enabled,
    refetchInterval: 1000 * 60 * 1, // stale time of 1 minute
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  return { invalidate };
};

export default { useConversations, useInvalidate };
