import { useMemo } from 'react';

import { LocDomain, LocsDomain } from '@/core/lib/new-architecture/domain/locs.domain';
import Query from '@/core/lib/new-architecture/query';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';
import type { GeoResult } from '@/core/types/geo';

const useDetail = (search?: string): StoreData<LocDomain> => {
  const { data: detail, isLoading } = Query.locs.useDetail({ data: { search } });

  const data = useMemo(() => {
    if (!detail) {
      return null;
    }

    return new LocDomain({ data: detail.result });
  }, [detail]);

  const notFound = !isLoading && !data;

  return {
    data,
    isLoading,
    notFound,
  };
};

interface UseLocParams {
  search?: string;
  range: 'large' | 'limited';
}

const useLoc = ({ search, range }: UseLocParams): StoreData<LocsDomain> => {
  const { data: large, isLoading: isLargeLoading } = Query.locs.useLarge({ data: { search }, enabled: range === 'large' });

  const { data: limited, isLoading: isLimitedLoading } = Query.locs.useLimited({ data: { search }, enabled: range === 'limited' });

  const isLoading = isLargeLoading || isLimitedLoading;

  const data = useMemo(() => {
    if (!large && !limited) {
      return null;
    }

    return new LocsDomain({ data: (large ?? limited) as GeoResult });
  }, [large, limited]);

  const notFound = !isLoading && !data;

  return {
    data,
    isLoading,
    notFound,
  };
};

export default { useDetail, useLoc };
