import useMutation from '@/core/hooks/api/useMutation';
import Api from '@/core/lib/new-architecture/api';
import type { Mutation } from '@/core/lib/new-architecture/query/types.query';
import type { UsersAuthResult } from '@/core/types/auth';

const useRenew: Mutation<UsersAuthResult, string> = ({ onSuccess }) => {
  return useMutation({
    mutationFn: token => Api.mutation.auth.renew(token),
    onSuccess: data => onSuccess?.(data),
  });
};

const useRefresh: Mutation<UsersAuthResult, string> = ({ onSuccess }) => {
  return useMutation({
    mutationFn: token => Api.mutation.auth.refresh({ token }),
    onSuccess: data => onSuccess?.(data),
  });
};

export default { useRenew, useRefresh };
