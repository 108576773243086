import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Mail: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M36.6666 10.0001C36.6666 8.16675 35.1666 6.66675 33.3333 6.66675H6.66659C4.83325 6.66675 3.33325 8.16675 3.33325 10.0001M36.6666 10.0001V30.0001C36.6666 31.8334 35.1666 33.3334 33.3333 33.3334H6.66659C4.83325 33.3334 3.33325 31.8334 3.33325 30.0001V10.0001M36.6666 10.0001L19.9999 21.6668L3.33325 10.0001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mail;
