import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface PushContextType {
  device: string | null;
  set: (value: string) => void;
  unset: () => void;
}

export const PushContext = createContext<PushContextType | null>(null);

export const usePushContext = () => useContext(PushContext) as PushContextType;

export const PushProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [device, setPush] = useState<string | null>(null);
  const set = useCallback((value: string) => setPush(value), []);
  const unset = useCallback(() => setPush(null), []);

  const value = useMemo(
    () => ({
      device,
      set,
      unset,
    }),
    [device],
  );

  return <PushContext.Provider value={value}>{children}</PushContext.Provider>;
};
