import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { ConversationListResult } from '@/core/types/conversation';
import { conversationListResult, ConversationState } from '@/core/types/conversation';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

export enum ConversationsDisplay {
  ALL = 'all',
  GIVER = 'giver',
  TAKER = 'taker',
}

export interface ConversationsQuery {
  donations?: number[];
  display: ConversationsDisplay;
  states: ConversationState[];
}

const query = {
  get: async (data: ConversationsQuery, token: string) => {
    const params = new URLSearchParams();
    params.set('display', data.display);

    if (data.donations) {
      data.donations.forEach(donation => {
        params.append('donations[]', donation.toString());
      });
    }

    if (data.states) {
      data.states.forEach(state => {
        params.append('states[]', state.toString());
      });
    }

    return api.get<ConversationListResult>({
      url: `${getUrl()}${ApiVersions.V1}/conversations${Array.from(params.entries()).length > 0 ? `?${params.toString()}` : ''}&nothing=nothing`,
      schema: conversationListResult,
      token,
    });
  },
};

export interface ModifyConversationsState {
  state: ConversationState;
  ids: number[];
}

const mutation = {
  state: async (data: ModifyConversationsState, token: string) => {
    let type: string | null = null;

    switch (data.state) {
      case ConversationState.NORMAL:
        type = 'normal';
        break;

      case ConversationState.IMPORTANT:
        type = 'important';
        break;

      case ConversationState.ARCHIVED:
        type = 'archive';
        break;

      default:
        break;
    }

    if (!type) {
      return null;
    }

    return api.put<null, { ids: number[] }>({
      url: `${getUrl()}${ApiVersions.V1}/conversations/${type}`,
      data: { ids: data.ids },
      schema: undefined,
      token,
    });
  },
};

export default { query, mutation };
