import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import Alert from '@/core/components/Alert';
import Button from '@/core/components/Button';
import FormContainer from '@/core/components/FormContainer';
import Header from '@/core/components/Header';
import HintMessage from '@/core/components/HintMessage';
import CodeInput from '@/core/components/inputs/CodeInput';
import PasswordInput from '@/core/components/inputs/PasswordInput';
import TextInput from '@/core/components/inputs/TextInput';
import Label from '@/core/components/Label';
import useForm from '@/core/hooks/useForm';
import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods, ErrorKind } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import { useAuthRoutesContext } from '@/core/pages/auth/Authentification';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';
import type { UsersAuthRecoveryEmailForm, UsersAuthRecoveryEmailResult, UsersAuthRecoveryForm, UsersAuthResult } from '@/core/types/auth';
import { usersAuthRecoveryEmailFormSchema, usersAuthRecoveryFormSchema } from '@/core/types/auth';
import { codeToTransKey, ErrorCode } from '@/core/types/donnons';

const Forgot: PageWithLayoutsAndProviders = () => {
  const { back, onClose } = useAuthRoutesContext();
  const { t } = useTranslationContext(['common', 'auth.forgot-password']);
  const { login } = useAuthContext();
  const { DONNONS_API_URL } = useEnvContext();
  const { send } = useAnalyticsContext();

  const [codeError, setCodeError] = useState<string | null>(null);
  const onCleanCodeError = () => setCodeError(null);

  const [step, setStep] = useState<'email' | 'password' | 'otp'>('email');
  const onGoOtp = (isValid: boolean) => {
    if (isValid) {
      setStep('otp');
    }
  };

  const apiDefinition = { method: ApiMethods.PUT, url: `${DONNONS_API_URL}${ApiVersions.V1}/recovery` };
  const { control, errors, onSubmit, isLoading, onCleanGlobalError, setValue } = useForm<UsersAuthResult, UsersAuthRecoveryForm>({
    schema: usersAuthRecoveryFormSchema,
    apiDefinition,
    onError: err => {
      if (err.kind === ErrorKind.BadRequest && err.json?.errors?.otp.code === ErrorCode.INVALID_OTP) {
        setCodeError(codeToTransKey(err.json?.errors?.otp.code));
      }
    },
    onSuccess: async res => {
      await login({
        accessToken: res.access_token,
        accessExpiresAt: dayjs().add(res.access_expires_in, 'second'),
        refreshToken: res.refresh_token ?? null,
        refreshExpiresAt: res.refresh_expires_in ? dayjs().add(res.refresh_expires_in, 'second') : null,
      });
      await send({ event: AnalyticsEventType.FORGOTTEN_PASSWORD });
      onClose({ success: true });
    },
  });

  const apiDefinitionMail = { method: ApiMethods.POST, url: `${DONNONS_API_URL}${ApiVersions.V1}/recovery` };
  const {
    control: controlMail,
    errors: errorsMail,
    onCleanGlobalError: onCleanGlobalErrorMail,
    onSubmit: onSubmitMail,
    isLoading: isLoadingMail,
    watch,
  } = useForm<UsersAuthRecoveryEmailResult, UsersAuthRecoveryEmailForm>({
    schema: usersAuthRecoveryEmailFormSchema,
    apiDefinition: apiDefinitionMail,
    onSuccess: (_, res) => {
      setValue('email', res.email);
      setStep('password');
    },
  });

  const email = watch('email');

  const onSubmitPassword = () => {
    onGoOtp(errors.password.isSuccess || false);
  };

  const onBack = () => {
    switch (step) {
      case 'email':
        back();
        break;
      case 'password':
        setStep('email');
        break;
      case 'otp':
        setStep('password');
        break;
      default:
        back();
        break;
    }
  };

  useKeyDownHandler([], 'Enter', event => {
    event.preventDefault();
    switch (step) {
      case 'email':
        onSubmitMail();
        break;
      case 'password':
        onSubmitPassword();
        break;
      case 'otp':
        onSubmit();
        break;
      default:
        break;
    }
  });

  return (
    <div className="flex h-full flex-col gap-4 md:gap-7">
      <Header content={t('nav-content', { ns: 'auth.forgot-password' })} iconBefore="arrow-left" onBefore={onBack} />

      {step === 'email' && (
        <div className="inline-flex h-full flex-col gap-4 pt-3">
          <p className="text-body-secondary font-normal text-content-primary">{t('email.description', { ns: 'auth.forgot-password' })}</p>
          {errorsMail.global.isError && errorsMail.global.message && (
            <Alert status="error" onClose={onCleanGlobalErrorMail}>
              {t(errorsMail.global.message, { ns: 'common' })}
            </Alert>
          )}
          <FormContainer>
            <Controller
              control={controlMail}
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errorsMail.email;
                return (
                  <Label htmlFor="email">
                    <span>{t('email.form.label', { ns: 'auth.forgot-password' })}</span>
                    <TextInput
                      id="email"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder={t('email.form.placeholder', { ns: 'auth.forgot-password' })}
                      validation={errors.email}
                      autocomplete="email"
                      inputMode="email"
                    />
                    <HintMessage isError={isError} isSuccess={isSuccess} message={message} />
                  </Label>
                );
              }}
              name="email"
              defaultValue=""
            />
          </FormContainer>

          <div className="mt-auto flex w-full justify-end">
            <div className="max-w-max">
              <Button type="submit" onClick={onSubmitMail} isLoading={isLoadingMail}>
                {t('email.form.submit.text', { ns: 'auth.forgot-password' })}
              </Button>
            </div>
          </div>
        </div>
      )}

      {step === 'password' && (
        <div className="inline-flex h-full flex-col gap-4 px-4 pb-7 md:px-7">
          <p className="text-body-secondary font-normal text-content-primary">{t('password.description', { email, ns: 'auth.forgot-password' })}</p>

          <FormContainer>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur } }) => {
                const { isError, message, isSuccess } = errors.password;
                return (
                  <Label htmlFor="password" content={t('password.form.label', { ns: 'auth.forgot-password' })}>
                    <PasswordInput
                      id="password"
                      onChange={onChange}
                      onBlur={onBlur}
                      validation={errors.password}
                      placeholder={t('password.form.placeholder', { ns: 'auth.forgot-password' })}
                      autocomplete="new-password"
                    />
                    {!isError && <HintMessage isError={isError} isSuccess={isSuccess} message={t('inputs.password.hint', { ns: 'common' })} />}
                    {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                  </Label>
                );
              }}
              name="password"
              defaultValue=""
            />
          </FormContainer>

          <div className="mt-auto flex w-full justify-end">
            <div className="mt-5 max-w-max">
              <Button type="submit" onClick={onSubmitPassword}>
                {t('password.form.submit.text', { ns: 'auth.forgot-password' })}
              </Button>
            </div>
          </div>
        </div>
      )}

      {step === 'otp' && (
        <div className="inline-flex h-full flex-col gap-4 px-4 pb-7 md:px-7">
          <p className="text-body-secondary font-normal text-content-primary">
            {t('otp.description', { ns: 'auth.forgot-password' })} <strong className="font-medium">{email}</strong>
          </p>
          {!codeError && errors.global.isError && errors.global.message && (
            <Alert status="error" onClose={onCleanGlobalError}>
              {t(errors.global.message, { ns: 'common' })} {errors.global.code}
            </Alert>
          )}

          {codeError && (
            <Alert status="error" onClose={onCleanCodeError}>
              {t(codeError, { ns: 'common' })}
            </Alert>
          )}
          <FormContainer>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Label htmlFor="otp">
                    <CodeInput id="otp" onChange={onChange} onBlur={onBlur} value={value} />

                    <p className="text-body-secondary pt-3 text-center font-normal text-content-primary">{t('otp.received', { ns: 'auth.forgot-password' })}</p>
                  </Label>
                );
              }}
              name="otp"
              defaultValue=""
            />
          </FormContainer>

          <div className="mt-auto flex w-full justify-end">
            <div className="max-w-max">
              <Button type="submit" onClick={onSubmit} isLoading={isLoading}>
                {t('otp.form.submit.text', { ns: 'auth.forgot-password' })}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Forgot;
