import React from 'react';

import Link from '@/core/components/Link';
import Routes from '@/core/lib/router/routes';

const ErrorBoundaryFallback: React.FC = () => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-6 bg-bg-primary">
      <h1 className="text-heading text-content-primary">Une erreur est survenue.</h1>
      <Link href={new Routes.HomeRoute()}>Retour à l&rsquo;accueil</Link>
    </div>
  );
};

export default ErrorBoundaryFallback;
