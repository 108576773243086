import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Edit: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M20 33.3333H35" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M27.5 5.83339C28.163 5.17034 29.0623 4.79785 30 4.79785C30.4643 4.79785 30.924 4.8893 31.353 5.06698C31.7819 5.24466 32.1717 5.50508 32.5 5.83339C32.8283 6.16169 33.0887 6.55144 33.2664 6.98039C33.4441 7.40935 33.5355 7.86909 33.5355 8.33338C33.5355 8.79768 33.4441 9.25742 33.2664 9.68638C33.0887 10.1153 32.8283 10.5051 32.5 10.8334L11.6667 31.6667L5 33.3334L6.66667 26.6667L27.5 5.83339Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Edit;
