import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Watch: React.FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="watch">
      <path
        id="Vector"
        d="M5.99998 4.50004V6.00004L6.74998 6.75004M8.25501 8.67501L8.08001 10.59C8.05748 10.8393 7.94226 11.0711 7.75711 11.2396C7.57196 11.4081 7.33035 11.501 7.08001 11.5H4.91501C4.66468 11.501 4.42306 11.4081 4.23791 11.2396C4.05276 11.0711 3.93754 10.8393 3.91501 10.59L3.74001 8.67501M3.74501 3.32501L3.92001 1.41001C3.94246 1.16155 4.05698 0.930451 4.24107 0.762085C4.42516 0.593719 4.66554 0.500243 4.91501 0.500008H7.09001C7.34035 0.498992 7.58196 0.591907 7.76711 0.760395C7.95226 0.928882 8.06748 1.16069 8.09001 1.41001L8.26501 3.32501M9.5 6.00006C9.5 7.93305 7.933 9.50006 6 9.50006C4.067 9.50006 2.5 7.93305 2.5 6.00006C2.5 4.06706 4.067 2.50006 6 2.50006C7.933 2.50006 9.5 4.06706 9.5 6.00006Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Watch;
