import { useMutation as useRqMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { AnyObjectSchema } from 'yup';

import useRefreshAccessToken from '@/core/hooks/auth/useRefreshAccessToken';
import useRenewAccessToken from '@/core/hooks/auth/useRenewAccessToken';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import renewOrRefresh from '@/core/lib/auth/renewOrRefresh';
import type { APIDefinition, APIError } from '@/core/lib/fetch';
import { api } from '@/core/lib/fetch';

export interface UseMutationParams<TRes, TData> {
  schema?: AnyObjectSchema;
  apiDefinition?: APIDefinition<TData>;
  mutationFn?: (data: TData, token?: string) => Promise<TRes>;
  onSuccess?: (res: TRes, data: TData) => void;
  onError?: (error: APIError, data: TData) => void;
}

const useMutation = <TRes, TData>({ schema, apiDefinition, mutationFn, onSuccess, onError }: UseMutationParams<TRes, TData>) => {
  const { accessToken, accessExpiresAt, refreshToken, refreshExpiresAt } = useAuthContext();

  const renew = useRenewAccessToken();
  const refresh = useRefreshAccessToken();

  const fn = async (data: TData): Promise<TRes> => {
    let returnFn: ((token?: string) => Promise<TRes>) | undefined;

    if (mutationFn) {
      returnFn = token => mutationFn(data, token);
    }

    if (apiDefinition) {
      returnFn = token => api<TRes, TData>({ ...apiDefinition, data }, token);
    }

    if (!returnFn) {
      throw new Error('[useMutation] You must at least define one of apiDefinition or mutationFn.');
    }

    const { shouldRenew, shouldRefresh } = renewOrRefresh({ accessExpiresAt, refreshExpiresAt });

    if (accessToken) {
      if (shouldRenew) {
        renew.mutate();
        return returnFn(accessToken);
      }

      if (!shouldRenew && !shouldRefresh) {
        return returnFn(accessToken);
      }

      if (shouldRefresh && refreshToken) {
        const login = await refresh.mutateAsync({ token: refreshToken });
        return returnFn(login.access_token);
      }
    }

    return returnFn();
  };

  const validate = useCallback((data: TRes): TRes => {
    if (!schema) return data as TRes;

    try {
      return data && (schema?.validateSync(data) as TRes);
    } catch (err) {
      console.warn(`[Schema Validation Error] API : ${apiDefinition?.method} ${apiDefinition?.url}`, { data, err });
      return data as TRes;
    }
  }, []);

  return useRqMutation<TRes, APIError, TData>({
    mutationFn: fn,
    onSuccess: (res, data) => {
      validate(res);
      if (onSuccess) {
        onSuccess(res, data);
      }
    },
    onError,
  });
};

export default useMutation;
