import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { DocumentsFileResult } from '@/core/types/document';
import { documentsFileResult } from '@/core/types/document';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  cgu: async (version: string = 'latest') =>
    api.get<DocumentsFileResult>({
      url: `${getUrl()}${ApiVersions.V1}/document/cgu/${version}`,
      schema: documentsFileResult,
    }),
};

export default { query };
