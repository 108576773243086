import { ApiVersions } from '@/core/lib/fetch';
import { Put } from '@/core/lib/fetch/api';
import type { DonnonsErrorConstructor, QueryError } from '@/core/lib/fetch/error';
import { DonnonsError } from '@/core/lib/fetch/error';
import getUrl from '@/core/lib/new-architecture/api/url';
import type { SavedSearchesResult, SavedSearchForm } from '@/core/types/favorite';

type OverQuota = { type: 'OVER_QUOTA' };

export class UpsertSavedSearchError extends DonnonsError implements QueryError {
  constructor({ response, json }: DonnonsErrorConstructor) {
    super({ response, json });
  }

  public getApiError(): OverQuota | null {
    if (this.json?.error.code === 'over_quota') {
      return { type: 'OVER_QUOTA' };
    }

    return null;
  }
}

class UpsertSavedSearch extends Put {
  public async call(data: SavedSearchForm, token: string): Promise<SavedSearchesResult> {
    try {
      const { json } = await this.request(`${getUrl()}${ApiVersions.V1}/saved-search`, data, token);

      return json as SavedSearchesResult;
    } catch (err) {
      if ('CAUGHT' in (err as DonnonsError)) {
        const { response, json } = (err as DonnonsError).getResAndJson();
        throw new UpsertSavedSearchError({ response, json });
      }
      // TO-DO handle errors
      throw err;
    }
  }
}

export default UpsertSavedSearch;
