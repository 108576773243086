import type { PropsWithChildren } from 'react';
import React from 'react';

import Icons from '@/core/components/Icons';
import type { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';

const El: React.FC<PropsWithChildren & { href?: AbstractRoute }> = ({ href, children }) => {
  const { Link } = useRouterContext();

  return href ? (
    <Link href={href} className="flex w-full items-center justify-between space-x-3 rounded-2 bg-bg-pale p-3">
      {children}
    </Link>
  ) : (
    <div className="flex w-full items-center justify-between space-x-3 rounded-2 bg-bg-pale p-3">{children}</div>
  );
};

export interface MenuCardProps extends PropsWithChildren {
  leftIcon?: React.ReactElement;
  href?: AbstractRoute;
}
const MenuCard: React.FC<MenuCardProps> = ({ href, leftIcon, children }) => {
  return (
    <El href={href}>
      <div className="shrink-0">{leftIcon}</div>
      <div className="flex grow flex-col truncate">{children}</div>
      <div className="flex shrink-0 items-center">{href && <Icons icon="chevron-right" size="20" color="primary" />}</div>
    </El>
  );
};

export default MenuCard;
