import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import { type PrivateDonationItemResult, privateDonationItemResultSchema } from '@/core/types/donation';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async (id: number, token: string) =>
    api.get<PrivateDonationItemResult>({
      url: `${getUrl()}${ApiVersions.V1}/user/donation/${id}`,
      schema: privateDonationItemResultSchema,
      token,
    }),
};

export default { query };
