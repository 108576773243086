import { createContext, useContext } from 'react';

export type TFunction = (key: string, options: Partial<{ ns: string; [args: string]: string }>) => string;
export interface TranslationContextType {
  useTranslation: (ns: string[]) => { t: TFunction };
}

export const TranslationContext = createContext<TranslationContextType | null>(null);

export const useTranslationContext = (ns: string[]) => {
  const { useTranslation } = useContext(TranslationContext) as TranslationContextType;
  return useTranslation(ns);
};
