import useMutation from '@/core/hooks/api/useMutation';
import type { APIError } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import Query from '@/core/lib/new-architecture/query';
import getToken from '@/core/lib/new-architecture/query/token';
import Store from '@/core/lib/new-architecture/store';
import type { UsersChangePasswordForm, UsersDeleteAccountForm } from '@/core/types/profile';

interface OnSuccessParams {
  onSuccess: () => void;
}

const useBlock = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateUser } = Query.user.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.block>>, APIError, string>({
    mutationFn: async (uuid: string) => Api.mutation.user.block(uuid, await getToken(requestToken)),
    onSuccess: async () => {
      await Promise.all([invalidateUser(), invalidateConversation(), invalidateConversations()]);
      onSuccess();
    },
  });

  const onSubmit = async (uuid: string) => mutation.mutateAsync(uuid);

  return { onSubmit, isLoading: mutation.isPending };
};

const useUnblock = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateUser } = Query.user.useInvalidate();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.unblock>>, APIError, string>({
    mutationFn: async (uuid: string) => Api.mutation.user.unblock(uuid, await getToken(requestToken)),
    onSuccess: async () => {
      await Promise.all([invalidateUser(), invalidateConversation(), invalidateConversations()]);
      onSuccess();
    },
  });

  const onSubmit = async (uuid: string) => mutation.mutateAsync(uuid);

  return { onSubmit, isLoading: mutation.isPending };
};

const useToggleNewsletterEmail = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.user.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.newsletter>>, APIError, boolean>({
    mutationFn: async (newsletter: boolean) => Api.mutation.user.newsletter(newsletter, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
      onSuccess();
    },
  });

  const onSubmit = async (newsletter: boolean) => mutation.mutateAsync(newsletter);

  return { onSubmit, isLoading: mutation.isPending };
};

const useToggleNewsletterPush = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.user.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.push>>, APIError, boolean>({
    mutationFn: async (push: boolean) => Api.mutation.user.push(push, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
      onSuccess();
    },
  });

  const onSubmit = async (push: boolean) => mutation.mutateAsync(push);

  return { onSubmit, isLoading: mutation.isPending };
};

type PatchNewletterValues = { push: boolean; newsletter: boolean };
const usePatchNewsletter = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.user.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.notifications>>, APIError, PatchNewletterValues>({
    mutationFn: async (formValues: PatchNewletterValues) => Api.mutation.user.notifications(formValues, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
      onSuccess();
    },
  });

  const onSubmit = async (formValues: PatchNewletterValues) => mutation.mutateAsync(formValues);

  return { onSubmit, isLoading: mutation.isPending };
};

const useToggleMsgPush = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.user.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.msgPush>>, APIError, boolean>({
    mutationFn: async (push: boolean) => Api.mutation.user.msgPush(push, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
      onSuccess();
    },
  });

  const onSubmit = async (push: boolean) => mutation.mutateAsync(push);

  return { onSubmit, isLoading: mutation.isPending };
};

const useToggleMsgEmail = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.user.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.msgEmail>>, APIError, boolean>({
    mutationFn: async (push: boolean) => Api.mutation.user.msgEmail(push, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
      onSuccess();
    },
  });

  const onSubmit = async (push: boolean) => mutation.mutateAsync(push);

  return { onSubmit, isLoading: mutation.isPending };
};

const useDeleteAccount = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.deleteAccount>>, APIError, UsersDeleteAccountForm>({
    mutationFn: async (formValues: UsersDeleteAccountForm) => Api.mutation.user.deleteAccount(formValues, await getToken(requestToken)),
    onSuccess,
  });

  const onSubmit = async (formValues: UsersDeleteAccountForm) => mutation.mutateAsync(formValues);

  return { onSubmit, deleteAccountIsLoading: mutation.isPending, deleteAccountIsError: mutation.isError };
};

const useUpdatePassword = ({ onSuccess }: OnSuccessParams) => {
  const { requestToken } = Store.auth.useRequest();
  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.user.updatePassword>>, APIError, UsersChangePasswordForm>({
    mutationFn: async (formValues: UsersChangePasswordForm) => Api.mutation.user.updatePassword(formValues, await getToken(requestToken)),
    onSuccess,
  });

  const onSubmit = async (formValues: UsersChangePasswordForm) => mutation.mutateAsync(formValues);

  return { onSubmit };
};

export { useBlock, useDeleteAccount, usePatchNewsletter, useToggleMsgEmail, useToggleMsgPush, useToggleNewsletterEmail, useToggleNewsletterPush, useUnblock, useUpdatePassword };
