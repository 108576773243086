import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const SmartPhone: React.FC<IconProps> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.3333 3.3335H11.6666C9.82564 3.3335 8.33325 4.82588 8.33325 6.66683V33.3335C8.33325 35.1744 9.82564 36.6668 11.6666 36.6668H28.3333C30.1742 36.6668 31.6666 35.1744 31.6666 33.3335V6.66683C31.6666 4.82588 30.1742 3.3335 28.3333 3.3335Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 30H20.0167" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SmartPhone;
