import type { PropsWithChildren } from 'react';
import React from 'react';

import { useComponentsContext } from '@/core/lib/components/components.context';
import type { FooterLayout } from '@/core/pages/page.types';
import NavBar from '@/next/components/NavBar';

interface NextLayoutProps extends PropsWithChildren {
  footer?: FooterLayout;
}

const NextLayout: React.FC<NextLayoutProps> = ({ children, footer }) => {
  const { Footer } = useComponentsContext();

  const show = footer?.show ?? true;
  const catalogue = footer?.catalogue ?? false;

  return (
    <>
      <NavBar />
      <div className="flex min-h-[calc(100vh-84px-42px)] lg:min-h-[calc(100vh-84px)]">{children}</div>
      {show && <Footer showCatalogueLinks={catalogue} />}
    </>
  );
};

export default NextLayout;
