import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Seedling: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 36 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M30.1876 0.833252C24.0261 0.833252 18.8126 5.43742 16.9845 11.802C14.4792 7.80721 10.4167 5.16658 5.74487 5.16658H0.666748V6.79158C0.666748 15.4583 6.82821 22.4999 14.4792 22.4999H16.3751V30.0833C16.3751 30.6926 16.849 31.1666 17.4584 31.1666H18.5417C19.0834 31.1666 19.6251 30.6926 19.6251 30.0833V18.1666H21.4532C29.1042 18.1666 35.3334 11.1249 35.3334 2.45825V0.833252H30.1876ZM14.4792 19.2499C9.13029 19.2499 4.66154 14.578 3.98446 8.41658H5.74487C11.0938 8.41658 15.5626 13.1562 16.2397 19.2499H14.4792ZM21.4532 14.9166H19.6928C20.3699 8.82283 24.8386 4.08325 30.1876 4.08325H31.948C31.2709 10.2447 26.8022 14.9166 21.4532 14.9166Z"
      strokeWidth="0"
    />
  </svg>
);

export default Seedling;
