import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Award: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" className={className}>
    <path
      d="M16 20C21.1546 20 25.3333 15.8213 25.3333 10.6666C25.3333 5.51199 21.1546 1.33332 16 1.33332C10.8453 1.33332 6.66663 5.51199 6.66663 10.6666C6.66663 15.8213 10.8453 20 16 20Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.9467 18.5201L9.33337 30.6667L16 26.6667L22.6667 30.6667L21.0534 18.5067" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Award;
