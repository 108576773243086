import React, { useCallback, useMemo } from 'react';

import type { GeolocationContextType } from '@/core/lib/geolocation/geolocation.context';
import { GeolocationContext } from '@/core/lib/geolocation/geolocation.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import { getCurrentPosition, getPermission } from '@/next/lib/geolocation/async';

const GeolocationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslationContext(['common']);

  const coordinates: GeolocationContextType['coordinates'] = useCallback(async () => {
    if ('geolocation' in navigator) {
      const permission = await getPermission();

      if (permission.state === 'denied') {
        return { error: t('inputs.city.modal.permissions', { ns: 'common' }) };
      }

      try {
        const { coords } = await getCurrentPosition();
        return { lat: coords.latitude, lon: coords.longitude };
      } catch {
        return { error: t('inputs.city.modal.permissions', { ns: 'common' }) };
      }
    } else {
      return { error: t('inputs.city.modal.permissions', { ns: 'common' }) };
    }
  }, []);

  const value = useMemo(() => ({ coordinates }) satisfies GeolocationContextType, []);

  return <GeolocationContext.Provider value={value}>{children}</GeolocationContext.Provider>;
};

export default GeolocationProvider;
