import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const ChevronLeft: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M25 30L15 20L25 10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronLeft;
