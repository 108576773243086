export default <T>(value: T): T => {
  if (typeof structuredClone === 'undefined') {
    if (value instanceof Map) {
      return new Map(value) as T;
    }

    return JSON.parse(JSON.stringify(value));
  }

  return structuredClone(value);
};
