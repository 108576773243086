import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Image: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M31.6667 5H8.33333C6.49238 5 5 6.49238 5 8.33333V31.6667C5 33.5076 6.49238 35 8.33333 35H31.6667C33.5076 35 35 33.5076 35 31.6667V8.33333C35 6.49238 33.5076 5 31.6667 5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1667 16.6667C15.5475 16.6667 16.6667 15.5475 16.6667 14.1667C16.6667 12.786 15.5475 11.6667 14.1667 11.6667C12.786 11.6667 11.6667 12.786 11.6667 14.1667C11.6667 15.5475 12.786 16.6667 14.1667 16.6667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M34.9999 25.0001L26.6666 16.6667L8.33325 35.0001" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Image;
