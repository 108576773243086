import React, { createContext, useContext, useMemo, useState } from 'react';

import Modal from '@/core/components/Modal';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import makeClone from '@/core/lib/makeClone';
import { useRouterContext } from '@/core/lib/router/router.context';
import Auth from '@/core/pages/auth/Auth/Auth';
import Forgot from '@/core/pages/auth/Forgot';
import Login from '@/core/pages/auth/Login';
import Register from '@/core/pages/auth/Register';
import CGUPage from '@/core/pages/CGU';
import type { UsersAuthRegisterForm } from '@/core/types/auth';

export enum AuthRoutes {
  AUTH = '/authentification',
  LOGIN = '/authentification/connexion',
  REGISTER = '/authentification/inscription',
  FORGOT = '/authentification/mot-de-passe-oublie',
  CGU = '/cgu',
}

interface OnCloseParams {
  navigateBack?: boolean;
  success?: boolean;
}

export interface AuthRoutesContextType {
  current: AuthRoutes;
  last?: AuthRoutes;
  isOpen: boolean;
  go: (route: AuthRoutes, userAuthRegisterFormValues?: UsersAuthRegisterForm) => void;
  back: () => void;
  onClose: (params?: OnCloseParams) => void;
  authRegisterFormValues?: Omit<UsersAuthRegisterForm, 'password'>;
}

const AuthRoutesContext = createContext<AuthRoutesContextType | null>(null);

export const useAuthRoutesContext = () => useContext(AuthRoutesContext) as AuthRoutesContextType;

const Render: React.FC = () => {
  const { current, back } = useAuthRoutesContext();
  switch (current) {
    case AuthRoutes.AUTH:
      return <Auth />;
    case AuthRoutes.LOGIN:
      return <Login />;
    case AuthRoutes.REGISTER:
      return <Register />;
    case AuthRoutes.FORGOT:
      return <Forgot />;
    case AuthRoutes.CGU:
      return <CGUPage back={back} />;
    default:
      return null;
  }
};

const AuthentificationModal = () => {
  const { isOpen, onClose } = useAuthRoutesContext();

  if (!isOpen) return <Modal isOpen={isOpen} onClose={() => {}} />;

  return (
    <Modal isOpen={isOpen} onClose={() => onClose({ navigateBack: true })}>
      <Render />
    </Modal>
  );
};

const Authentification: React.FC = () => {
  const { closeAuth, isAuthOpen: isOpen } = useAuthContext();
  const { back: backRouter } = useRouterContext();

  const [history, setHistory] = useState<AuthRoutes[]>([AuthRoutes.AUTH]);
  const [authRegisterFormValues, setAuthRegisterFormValues] = useState<UsersAuthRegisterForm>();
  const current = history[history.length - 1] as AuthRoutes;
  const last = history[history.length - 2] as AuthRoutes | undefined;

  const go = (route: AuthRoutes, registerFormValues?: UsersAuthRegisterForm) => {
    if (registerFormValues) {
      setAuthRegisterFormValues(registerFormValues);
    }
    setHistory(prev => [...prev, route]);
  };

  const back = () => {
    setHistory(prev => {
      const clone = makeClone(prev);
      clone.pop();
      return clone;
    });
  };

  const onClose = (params: OnCloseParams = {}) => {
    closeAuth(params.success);
    if (params.navigateBack) {
      backRouter();
    }
  };

  const value = useMemo(
    () => ({
      current,
      last,
      isOpen,
      onClose,
      go,
      back,
      authRegisterFormValues,
    }),
    [current, last, isOpen],
  );

  return (
    <AuthRoutesContext.Provider value={value}>
      <AuthentificationModal />
    </AuthRoutesContext.Provider>
  );
};

export default Authentification;
