import React from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@/core/components/Button';
import HintMessage from '@/core/components/HintMessage';
import TextInput from '@/core/components/inputs/TextInput';
import Label from '@/core/components/Label';
import useForm from '@/core/hooks/useForm';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import Query from '@/core/lib/new-architecture/query';
import { NOT_URL_REGEX, USERNAME_AT_LEAST_2_REGEX, USERNAME_REGEX } from '@/core/lib/regex';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { UsersHasControlResult } from '@/core/types/control';

const schema = yup.object({
  username: yup
    .string()
    .matches(USERNAME_AT_LEAST_2_REGEX, 'control.username.required')
    .matches(USERNAME_REGEX, 'control.username.required')
    .matches(NOT_URL_REGEX, 'errors.inputs.has_not_url')
    .required('control.username.required'),
});

type Schema = yup.InferType<typeof schema>;

const UsernameControl: React.FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslationContext(['common']);
  const { DONNONS_API_URL } = useEnvContext();
  const { invalidate } = Query.user.useInvalidate();

  const { control, onSubmit, errors } = useForm<UsersHasControlResult, Schema>({
    schema,
    apiDefinition: { method: ApiMethods.PUT, url: `${DONNONS_API_URL}${ApiVersions.V1}/user/control/username` },
    onSuccess: async () => {
      await invalidate();
    },
  });

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full grow flex-col gap-6">
        <div className="mx-auto flex size-30 items-center justify-center">
          <img loading="lazy" decoding="async" src="/assets/control/username.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-contain" />
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-body-primary text-center font-semibold text-content-secondary">{t('control.username.title', { ns: 'common' })}</p>
          <p className="text-body-secondary font-normal text-content-primary">{t('control.username.subtitle-1', { ns: 'common' })}</p>
          <p className="text-body-secondary font-normal text-content-primary">{t('control.username.subtitle-2', { ns: 'common' })}</p>
        </div>
        <Controller
          control={control}
          render={({ field: { ref, value, onChange, onBlur } }) => {
            const { isError, message, isSuccess } = errors.username;
            return (
              <Label htmlFor="username" content={t('control.username.label', { ns: 'common' })}>
                <TextInput
                  id="username"
                  value={value ?? ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  validation={errors.username}
                  ref={ref}
                  enterKeyHint="next"
                  placeholder={t('control.username.placeholder', { ns: 'common' })}
                />
                {!isError && <HintMessage message={t('control.username.hint', { ns: 'common' })} />}
                {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
              </Label>
            );
          }}
          name="username"
          defaultValue=""
        />
      </div>
      <div className="flex gap-4">
        <Button variant="secondary" onClick={logout}>
          {t('control.username.cancel', { ns: 'common' })}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t('control.username.submit', { ns: 'common' })}
        </Button>
      </div>
    </div>
  );
};

export default UsernameControl;
