import { useQueryClient } from '@tanstack/react-query';

import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import type { GetSavedSearchError } from '@/core/lib/new-architecture/api/savedSearch/savedSearch.get';
import getToken from '@/core/lib/new-architecture/query/token';
import type { Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/saved-search'] as const,
};

type AwaitedSavedSearches = Awaited<ReturnType<typeof Api.query.savedSearch.get.call>>;

const useSavedSearches: Query<AwaitedSavedSearches, void, GetSavedSearchError> = ({ token, enabled }) => {
  return useQuery<AwaitedSavedSearches, GetSavedSearchError>({
    queryKey: keys.base,
    queryFn: async () => Api.query.savedSearch.get.call(await getToken(token)),
    enabled,
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  return { invalidate };
};

export default { useSavedSearches, useInvalidate };
