import React, { forwardRef } from 'react';

import Header from '@/core/components/Header';
import Icons from '@/core/components/Icons';
import { NewTextInput } from '@/core/components/inputs/TextInput';
import useSelectInput from '@/core/hooks/inputs/useSelectInput';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { SelectInputProps } from '@/core/types/components';

const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>((props, forwardedRef) => {
  const { t } = useTranslationContext(['common']);

  const {
    id,
    isOpen,
    onOpen,
    onClose,
    items,
    onSelect,
    onSearch,
    onClean,
    onBack,
    selectedItem,
    refs: { inputRef, divRef },
    ...rest
  } = useSelectInput({ forwardedRef, ...props });

  const isLg = useIsLg();

  const mobileModalClasses = isOpen ? 'fixed top-0 left-0 h-screen w-screen z-50 bg-bg-primary px-4' : '';
  const dropdownClasses = `lg:absolute ${
    isOpen ? 'flex' : 'hidden'
  } lg:top-12 z-20 h-full lg:h-auto lg:max-h-select w-full flex-col overflow-auto rounded-2 bg-bg-primary pb-30 lg:pb-0 lg:p-3 lg:shadow-md lg:flex`;

  return (
    <div ref={isLg ? null : divRef} className={isLg ? 'relative' : mobileModalClasses}>
      {isOpen && !isLg && (
        <Header
          content={props.placeholder ?? ''}
          iconBefore={onBack ? 'arrow-left' : undefined}
          onBefore={onBack}
          labelBefore={t('inputs.select.back', { ns: 'common' })}
          iconAfter={!onBack ? 'close' : undefined}
          onAfter={onClose}
          labelAfter={t('inputs.select.close', { ns: 'common' })}
        />
      )}
      <label htmlFor={`select-${id}`}>
        <span className="sr-only">{props.placeholder}</span>
        <NewTextInput
          {...rest}
          ref={inputRef}
          id={`select-${id}`}
          placeholder={selectedItem ? selectedItem.text : props.placeholder}
          value={isOpen || !selectedItem ? '' : selectedItem.text}
          onClick={!isLg && isOpen ? undefined : onOpen}
          onChange={onSearch}
          onClean={onClean}
        />
      </label>

      {(isOpen || !isLg) && (
        <div ref={isLg ? divRef : null} className={dropdownClasses}>
          {onBack && isLg && (
            <button type="button" className="text-body-secondary bg-bg-primary p-3 text-start font-medium text-content-primary hover:bg-bg-pale" onClick={onBack}>
              {t('inputs.select.back', { ns: 'common' })}
            </button>
          )}
          {items.length === 0 && <p className="text-body-secondary p-3 text-content-primary">{t('inputs.select.no-items', { ns: 'common' })}</p>}
          {items.map(item => (
            <button
              key={item.value}
              type="button"
              className="text-body-secondary inline-flex items-center justify-between border-b border-stroke-tertiary bg-bg-primary p-3 text-start font-medium text-content-primary hover:bg-bg-pale"
              onClick={onSelect(item)}
            >
              {item.text} {item.children && <Icons icon="chevron-right" size="16" weight="bold" />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
});

export default SelectInput;
