import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import SavedSearchDomain from '@/core/lib/new-architecture/domain/savedSearch.domain';
import { DonationsPublicDonationListResult } from '@/core/types/donation';
import type { SavedSearchesResult } from '@/core/types/favorite';

export interface FavoritesDomainConstructor {
  savedSearches: SavedSearchesResult;
  categories: CategoriesDomain;
  followedDonations: DonationsPublicDonationListResult;
  current: CurrentDomain;
}

export interface FavoritesDomainInterface {
  getSavedSearches: () => SavedSearchDomain[];
  getSavedSearch: (hash: string) => SavedSearchDomain | null;
  hasSavedSearch: (hash: string) => boolean;
  getUnread: () => number;
  getFollowedDonationsIds: () => Array<number>;

  donations: Array<DonationDomain>;
}

type SavedSearchesMap = Map<string, SavedSearchDomain>;

class FavoritesDomain implements FavoritesDomainInterface {
  private savedSearchesMap: SavedSearchesMap;
  private followedDonations: DonationsPublicDonationListResult;
  public donations: Array<DonationDomain>;

  constructor({ savedSearches, categories, followedDonations, current }: FavoritesDomainConstructor) {
    const savedSearchesMap = new Map<string, SavedSearchDomain>([]);

    savedSearches.searches.forEach(savedSearch => {
      savedSearchesMap.set(savedSearch.hash, new SavedSearchDomain({ data: savedSearch, categories }));
    });

    this.savedSearchesMap = savedSearchesMap;
    this.followedDonations = followedDonations;

    const followedDonationsIds = followedDonations?.donations?.map(donation => donation.id) || [];

    const { admins, cities, countries, subs, users } = followedDonations;
    this.donations = followedDonations.donations.map(donation => {
      const donationDomain = new DonationDomain({
        data: {
          donation,
          admins,
          cities,
          countries,
          subs,
          // TO-DO remove that it should be filtered by the domain
          users: users?.filter(u => u.uuid === donation.giver),
          is_winner: false,
          conversation: null,
          new_messages: 0,
          can_create_conversation: false,
          options: [],
        },
        categories,
        current,
        followedDonationsIds,
      });

      const loc = current.getLoc();
      if (loc?.getLatLon()) {
        const { lat, lon } = loc.getLatLon()!;
        donationDomain.computeDonationDistance({ lat, lon });
      }

      return donationDomain;
    });
  }

  public getFollowedDonationsIds(): Array<number> {
    return this.followedDonations?.donations?.map(donation => donation.id) || [];
  }

  public getSavedSearches(): SavedSearchDomain[] {
    return Array.from(this.savedSearchesMap.values());
  }

  public getSavedSearch(hash: string): SavedSearchDomain | null {
    return this.savedSearchesMap.get(hash) ?? null;
  }

  public hasSavedSearch(hash: string): boolean {
    return this.savedSearchesMap.has(hash);
  }

  public getUnread(): number {
    return Array.from(this.savedSearchesMap.values()).reduce((unread, savedSearch) => unread + savedSearch.getUnread(), 0);
  }
}

export default FavoritesDomain;
