import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Bookmark: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M31.6666 35L19.9999 26.6667L8.33325 35V8.33333C8.33325 7.44928 8.68444 6.60143 9.30956 5.97631C9.93468 5.35119 10.7825 5 11.6666 5H28.3333C29.2173 5 30.0652 5.35119 30.6903 5.97631C31.3154 6.60143 31.6666 7.44928 31.6666 8.33333V35Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bookmark;
