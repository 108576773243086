import React, { createContext, useContext, useMemo } from 'react';

export interface SSRContextType {
  path: string;
}

const SSRContext = createContext<SSRContextType | null>(null);

export const useSSRContext = () => useContext(SSRContext) as SSRContextType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageProps = any | null;

export const SSRProvider: React.FC<React.PropsWithChildren & { pageProps: PageProps }> = ({ pageProps, children }) => {
  const { path } = pageProps ?? {};

  const value = useMemo(
    () => ({
      path,
    }),
    [path],
  );

  return <SSRContext.Provider value={value}>{children}</SSRContext.Provider>;
};

export default SSRProvider;
