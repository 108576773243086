import type { Dayjs } from 'dayjs';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import Actions from '@/core/lib/new-architecture/actions';
import Context from '@/core/lib/new-architecture/context';
import Store from '@/core/lib/new-architecture/store';
import { usePersistContext } from '@/core/lib/persist/persist.context';

export interface AuthTokens {
  accessToken: string | null;
  accessExpiresAt: Dayjs | null;
  refreshToken: string | null;
  refreshExpiresAt: Dayjs | null;
}

export interface AuthContextType extends AuthTokens {
  isReady: boolean;
  isLoggedIn: boolean;
  logout: () => Promise<void>;
  login: (tokens: AuthTokens) => Promise<void>;
  renew: (tokens: AuthTokens) => Promise<void>;
  isAuthOpen: boolean;
  openAuth: () => void;
  closeAuth: (success?: boolean) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => useContext(AuthContext) as AuthContextType;

/**
 * AuthProvider becomes a shell for the new architecture allowing to change the methods
 * but without impacting existing implementation of the components and contexts
 * that consume it
 */
const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // temporary
  const { login } = Actions.auth.useLogin();
  const { logout } = Actions.auth.useLogout();

  const { set } = usePersistContext();

  // temporary
  const { isAuthOpen } = Store.auth.useModal();
  const { openAuth, closeAuth } = Actions.auth.useModal();

  const { setTokens, isReady, ...tokens } = Context.auth.useAuthContext();
  const { isLoggedIn } = Store.auth.useRequest();

  // should disappear soon
  const renew = useCallback(async (data: AuthTokens) => {
    await Promise.all([data.accessToken ? set('accessToken', data.accessToken) : undefined, data.accessExpiresAt ? set('accessExpiresAt', data.accessExpiresAt?.toISOString()) : undefined]);

    setTokens(prev => ({ ...prev, accessToken: data.accessToken, accessExpiresAt: data.accessExpiresAt }));
  }, []);

  const value = useMemo(
    () => ({
      ...tokens,
      isLoggedIn,
      isReady,
      isAuthOpen,
      logout,
      login,
      renew,
      openAuth,
      closeAuth,
    }),
    [tokens, isLoggedIn, isReady, isAuthOpen],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
