import { ToastStatus } from '@/core/components/Toast/Toast';
import useMutation from '@/core/hooks/api/useMutation';
import type { APIError } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import type { UpsertSavedSearchError } from '@/core/lib/new-architecture/api/savedSearch/savedSearch.upsert';
import type SavedSearchDomain from '@/core/lib/new-architecture/domain/savedSearch.domain';
import Query from '@/core/lib/new-architecture/query';
import getToken from '@/core/lib/new-architecture/query/token';
import Store from '@/core/lib/new-architecture/store';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { SavedSearchForm } from '@/core/types/favorite';

const useCreateSavedSearch = () => {
  const { t } = useTranslationContext(['common']);
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.savedSearch.useInvalidate();
  const toast = useToastContext();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.savedSearch.upsert.call>>, UpsertSavedSearchError, SavedSearchForm>({
    mutationFn: async (data: SavedSearchForm) => Api.mutation.savedSearch.upsert.call(data, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
    },
    onError: error => {
      const apiError = error.getApiError();

      if (apiError && apiError.type === 'OVER_QUOTA') {
        toast?.open(t('errors.saved-search.over_quota', { ns: 'common' }), 2000, ToastStatus.DANGER);
      }
    },
  });

  const onCreate = async (data: SavedSearchForm) => mutation.mutate(data);

  return { onCreate, isLoading: mutation.isPending };
};

const useSavedSearch = (savedSearch: SavedSearchDomain | null) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.savedSearch.useInvalidate();

  const mutationDelete = useMutation<Awaited<ReturnType<typeof Api.mutation.savedSearch.delete.call>>, APIError, string>({
    mutationFn: async hash => Api.mutation.savedSearch.delete.call(hash, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
    },
  });

  const onDelete = async (onSuccess: () => void) => {
    if (savedSearch) {
      mutationDelete.mutate(savedSearch.hash, { onSuccess });
    }
  };

  const mutationTogglePush = useMutation<Awaited<ReturnType<typeof Api.mutation.savedSearch.upsert.call>>, APIError, boolean>({
    mutationFn: async push => Api.mutation.savedSearch.upsert.call({ ...savedSearch!.getForm(), push }, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
    },
  });

  const onTogglePush = async (push: boolean) => {
    if (savedSearch) {
      mutationTogglePush.mutate(push);
    }
  };

  const mutationToggleEmail = useMutation<Awaited<ReturnType<typeof Api.mutation.savedSearch.upsert.call>>, APIError, boolean>({
    mutationFn: async email => Api.mutation.savedSearch.upsert.call({ ...savedSearch!.getForm(), email }, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
    },
  });

  const onToggleEmail = async (email: boolean) => {
    if (savedSearch) {
      mutationToggleEmail.mutate(email);
    }
  };

  const mutationRead = useMutation<Awaited<ReturnType<typeof Api.mutation.savedSearch.read.call>>, APIError, string>({
    mutationFn: async hash => Api.mutation.savedSearch.read.call(hash, await getToken(requestToken)),
    onSuccess: async () => {
      await invalidate();
    },
  });

  const onRead = async () => {
    if (savedSearch) {
      mutationRead.mutate(savedSearch.hash);
    }
  };

  return {
    onDelete,
    isDeleteLoading: mutationDelete.isPending,
    onTogglePush,
    isTogglePushLoading: mutationTogglePush.isPending,
    onToggleEmail,
    isToggleEmailLoading: mutationToggleEmail.isPending,
    onRead,
  };
};

export { useCreateSavedSearch, useSavedSearch };
