import { useEffect } from 'react';

import type { DonnonsError } from '@/core/lib/fetch/error';
import Actions from '@/core/lib/new-architecture/actions';

const useGlobalError = (error: DonnonsError | null): void => {
  const { logout } = Actions.auth.useLogout();

  const globalError = error && 'getGlobalError' in error ? error.getGlobalError() : null;

  useEffect(() => {
    const handle = async () => {
      switch (globalError?.type) {
        case 'USER_STATE':
          await logout();
          break;

        default:
          break;
      }
    };

    handle();
  }, [globalError]);
};

export default useGlobalError;
