import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const User: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M33.3334 35V31.6667C33.3334 29.8986 32.631 28.2029 31.3808 26.9526C30.1305 25.7024 28.4349 25 26.6667 25H13.3334C11.5653 25 9.86961 25.7024 8.61937 26.9526C7.36913 28.2029 6.66675 29.8986 6.66675 31.6667V35M26.6666 11.6667C26.6666 15.3486 23.6818 18.3333 19.9999 18.3333C16.318 18.3333 13.3333 15.3486 13.3333 11.6667C13.3333 7.98477 16.318 5 19.9999 5C23.6818 5 26.6666 7.98477 26.6666 11.6667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default User;
