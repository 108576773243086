import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Gift: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M33.3334 20V36.6667H6.66669V20" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.6666 11.6667H3.33331V20.0001H36.6666V11.6667Z" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 36.6668V11.6667" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20 11.6666H12.5C11.3949 11.6666 10.3351 11.2276 9.5537 10.4462C8.7723 9.66479 8.33331 8.60499 8.33331 7.49992C8.33331 6.39485 8.7723 5.33504 9.5537 4.55364C10.3351 3.77224 11.3949 3.33325 12.5 3.33325C18.3333 3.33325 20 11.6666 20 11.6666Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 11.6666H27.5C28.6051 11.6666 29.6649 11.2276 30.4463 10.4462C31.2277 9.66479 31.6667 8.60499 31.6667 7.49992C31.6667 6.39485 31.2277 5.33504 30.4463 4.55364C29.6649 3.77224 28.6051 3.33325 27.5 3.33325C21.6667 3.33325 20 11.6666 20 11.6666Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Gift;
