import React, { useCallback, useMemo } from 'react';

import type { ShareUrlParams } from '@/core/lib/share/share.context';
import { ShareContext } from '@/core/lib/share/share.context';

const ShareProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const canShare = useCallback(async () => {
    if ('share' in navigator) {
      return Promise.resolve(navigator.canShare());
    }

    return Promise.resolve(false);
  }, []);

  const shareUrl = useCallback(async (params: ShareUrlParams) => {
    if ('share' in navigator) {
      await navigator.share(params);
      return undefined;
    }

    throw new Error('navigator.share not implemented in this browser. Did you forgot to call canShare ?');
  }, []);

  const value = useMemo(
    () => ({
      canShare,
      shareUrl,
    }),
    [],
  );

  return <ShareContext.Provider value={value}>{children}</ShareContext.Provider>;
};

export default ShareProvider;
