import type { DocumentsFileResult } from '@/core/types/document';

export interface DocumentsInterface {
  getContent: () => string;
}

interface DocumentConstructor {
  data: DocumentsFileResult;
}

class CguDocument implements DocumentsInterface {
  private data: DocumentsFileResult;

  constructor({ data }: DocumentConstructor) {
    this.data = data;
  }

  public getContent() {
    return this.data.content;
  }
}

export default CguDocument;
