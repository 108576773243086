import { useMemo } from 'react';

import SuggestionDomain from '@/core/lib/new-architecture/domain/suggestion.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';

interface UseSuggestion {
  donationTitle?: string;
}

const useSuggestion = ({ donationTitle }: UseSuggestion) => {
  const { data: categoriesSuggestionsResult } = Query.categories.useCategoriesSuggestions({ data: { donationTitle } });
  const { data: categoriesDomain } = Store.categories.useCategories();
  const suggestions = useMemo(() => {
    if (!categoriesSuggestionsResult || !categoriesDomain) return null;
    return new SuggestionDomain(categoriesSuggestionsResult, categoriesDomain);
  }, [categoriesSuggestionsResult, categoriesDomain]);

  return { suggestions };
};

export default { useSuggestion };
