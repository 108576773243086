import React from 'react';
import * as yup from 'yup';

import Button from '@/core/components/Button';
import type { EmailErrorControlStepProps } from '@/core/components/UserControl/controls/EmailErrorControl/helper';
import useForm from '@/core/hooks/useForm';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { UsersHasControlResult } from '@/core/types/control';

const schema = yup.object();

type Schema = yup.InferType<typeof schema>;

const EmailErrorControlCheck: React.FC<EmailErrorControlStepProps> = ({ onNext }) => {
  const { t } = useTranslationContext(['common']);
  const ns = { ns: 'common' };
  const { DONNONS_API_URL } = useEnvContext();

  const { data: current } = Store.current.useCurrent();
  const { invalidateControl } = Query.user.useInvalidate();
  const { email } = current!.getInfo();

  const { onSubmit } = useForm<UsersHasControlResult, Schema>({
    schema,
    apiDefinition: { method: ApiMethods.PUT, url: `${DONNONS_API_URL}${ApiVersions.V1}/user/control/email_error` },
    onSuccess: async () => {
      await invalidateControl();
    },
  });
  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full grow flex-col gap-6">
        <div className="mx-auto flex size-30 items-center justify-center">
          <img loading="lazy" decoding="async" src="/assets/control/email_error.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-contain" />
        </div>
        <p className="text-title text-center font-semibold text-content-secondary">{t('control.email-error.control-check.title', ns)}</p>
        <div className="flex flex-col gap-2 text-center">
          <p className="text-body-secondary font-normal text-content-primary">{t('control.email-error.control-check.subtitle-1', ns)}</p>
          <p className="text-body-secondary font-medium text-content-primary">{t('control.email-error.control-check.subtitle-2', ns)}</p>
        </div>
        <div className="space-y-1 rounded-1 bg-bg-pale p-3">
          <p className="text-caption-primary text-content-placeholder">{t('control.email-error.control-check.email-label', ns)}</p>
          <p className="font-medium text-content-placeholder">{email}</p>
          <hr className="mt-1 text-stroke-secondary" />
        </div>
      </div>
      <div className="w-full space-y-4">
        <Button variant="primary" onClick={onSubmit}>
          {t('control.email-error.control-check.cta-submit', ns)}
        </Button>
        <Button variant="secondary" onClick={onNext}>
          {t('control.email-error.control-check.cta-edit', ns)}
        </Button>
      </div>
    </div>
  );
};

export default EmailErrorControlCheck;
