import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Share: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M14.3165 22.5169L25.6999 29.1502M25.6832 10.8502L14.3165 17.4835M35 8.3335C35 11.0949 32.7614 13.3335 30 13.3335C27.2386 13.3335 25 11.0949 25 8.3335C25 5.57207 27.2386 3.3335 30 3.3335C32.7614 3.3335 35 5.57207 35 8.3335ZM15 20.0001C15 22.7615 12.7614 25.0001 10 25.0001C7.23858 25.0001 5 22.7615 5 20.0001C5 17.2387 7.23858 15.0001 10 15.0001C12.7614 15.0001 15 17.2387 15 20.0001ZM35 31.6668C35 34.4282 32.7614 36.6668 30 36.6668C27.2386 36.6668 25 34.4282 25 31.6668C25 28.9053 27.2386 26.6668 30 26.6668C32.7614 26.6668 35 28.9053 35 31.6668Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Share;
