import { useQueryClient } from '@tanstack/react-query';

import useQuery from '@/core/hooks/api/useQuery';
import { ErrorKind } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import getToken, { getTokenNoFail } from '@/core/lib/new-architecture/query/token';
import type { Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/user'] as const,
  control: () => [...keys.base, '/control'] as const,
  contacts: () => [...keys.base, '/contacts'] as const,
  user: (uuid: string) => [...keys.base, uuid] as const,
};

const useCurrent: Query<Awaited<ReturnType<typeof Api.query.user.get>>> = ({ enabled, token }) => {
  return useQuery({
    queryKey: keys.base,
    queryFn: async () => {
      return Api.query.user.get(await getToken(token));
    },
    enabled,
    retry: (failureCount, error) => {
      if (error.kind === ErrorKind.Unauthorized) {
        return false;
      }

      return failureCount < 3;
    },
  });
};

const useControl: Query<Awaited<ReturnType<typeof Api.query.user.control>>> = ({ enabled, token }) => {
  return useQuery({
    queryKey: keys.control(),
    queryFn: async () => {
      return Api.query.user.control(await getToken(token));
    },
    enabled,
    retry: (failureCount, error) => {
      if (error.kind === ErrorKind.Unauthorized) {
        return false;
      }

      return failureCount < 3;
    },
  });
};

const useContacts: Query<Awaited<ReturnType<typeof Api.query.user.contacts>>> = ({ enabled, token }) => {
  return useQuery({
    queryKey: keys.contacts(),
    queryFn: async () => {
      return Api.query.user.contacts(await getToken(token));
    },
    enabled,
    retry: (failureCount, error) => {
      if (error.kind === ErrorKind.Unauthorized) {
        return false;
      }

      return failureCount < 3;
    },
  });
};

interface PublicProfileParams {
  uuid?: string;
}

const usePublicProfile: Query<Awaited<ReturnType<typeof Api.query.user.user>>, PublicProfileParams> = ({ data: { uuid }, token }) => {
  return useQuery({
    queryKey: keys.user(uuid as string),
    queryFn: async () => Api.query.user.user(uuid as string, await getTokenNoFail(token)),
    enabled: !!uuid,
    retry: 0,
  });
};

usePublicProfile.prefetch = async ({ queryClient, uuid }) => {
  await queryClient.prefetchQuery({
    queryKey: keys.user(uuid as string),
    queryFn: async () => Api.query.user.user(uuid as string),
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });

  const invalidateControl = () => queryClient.invalidateQueries({ queryKey: keys.control() });

  return { invalidate, invalidateControl };
};

export default { useCurrent, useControl, useContacts, usePublicProfile, useInvalidate };
