import { DonnonsError } from '@/core/lib/fetch/error';
import type { Token } from '@/core/lib/new-architecture/query/types.query';

class TokenError extends DonnonsError {
  constructor() {
    super({
      response: {
        status: 401,
      } as Response,
    });
  }
}

const getToken = async (token?: Token): Promise<string> => {
  if (!token) {
    throw new TokenError();
  }

  if (typeof token === 'string') return token;

  const res = await token();

  if (!res) {
    throw new TokenError();
  }

  return res;
};

export const getTokenNoFail = async (token?: Token): Promise<string | undefined> => {
  try {
    return await getToken(token);
  } catch {
    return undefined;
  }
};

export default getToken;
