import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

import type { TranslationContextType } from '@/core/lib/translation/translation.context';
import { TranslationContext } from '@/core/lib/translation/translation.context';

const TranslationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useMemo(() => ({ useTranslation }) satisfies TranslationContextType, []);

  return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
};

export default TranslationProvider;
