import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Tag: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M34.3166 22.3502L22.3666 34.3002C22.057 34.6101 21.6894 34.856 21.2847 35.0237C20.8801 35.1914 20.4463 35.2778 20.0083 35.2778C19.5702 35.2778 19.1364 35.1914 18.7318 35.0237C18.3271 34.856 17.9595 34.6101 17.6499 34.3002L3.33325 20.0002V3.3335H19.9999L34.3166 17.6502C34.9374 18.2747 35.2859 19.1195 35.2859 20.0002C35.2859 20.8808 34.9374 21.7256 34.3166 22.3502V22.3502Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.6667 11.6665H11.6834" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Tag;
