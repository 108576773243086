import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Close: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M30 10L10 30M10 10L30 30" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Close;
