import type { KeyboardEventHandler, PropsWithChildren } from 'react';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import Icons from '@/core/components/Icons';
import useOnClickOutside from '@/core/hooks/useOnClickOutside';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface BottomSheetProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isOpen, children, onClose }) => {
  const { t } = useTranslationContext(['common']);
  const mobileRef = useRef<HTMLDivElement>(null);
  const desktopRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([mobileRef], event => {
    const isMobile = mobileRef.current?.checkVisibility && mobileRef.current.checkVisibility();
    if (isMobile) {
      onClose();
      event.preventDefault();
    }
  });
  useOnClickOutside([desktopRef], () => {
    const isDesktop = desktopRef.current?.checkVisibility && desktopRef.current.checkVisibility();
    if (isDesktop) {
      onClose();
    }
  });

  const handleOnKeyDown: KeyboardEventHandler = event => {
    if (event.code === 'Escape') {
      onClose();
    }
  };

  const handleOnCloseClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div role="button" onClick={handleOnCloseClick} tabIndex={0} onKeyDown={handleOnKeyDown}>
      <div ref={desktopRef} className="static right-0 top-0 z-50 hidden lg:absolute lg:block">
        <div role="dialog" className="absolute right-0 top-9 w-max rounded-2 bg-bg-primary shadow">
          {children}
        </div>
      </div>
      {createPortal(
        <div className="fixed bottom-0 z-50 flex h-screen w-screen items-end overflow-hidden bg-bg-dark/20 lg:hidden">
          <div ref={mobileRef} role="dialog" className="w-full rounded-t-2 bg-bg-primary">
            <div className="px-3 py-4">
              <div className="flex items-start justify-between">
                <div className="flex size-full flex-col">{children}</div>
                <Icons icon="close" size="24" onClick={onClose} label={t('components.bottom-sheet.close', { ns: 'common' })} />
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )}
    </div>
  );
};

export default BottomSheet;
