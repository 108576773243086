import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@/core/components/Button';
import HintMessage from '@/core/components/HintMessage';
import CheckboxInput from '@/core/components/inputs/CheckboxInput';
import useForm from '@/core/hooks/useForm';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import Query from '@/core/lib/new-architecture/query';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import CGU from '@/core/pages/CGU';
import type { UsersHasControlResult } from '@/core/types/control';

const schema = yup.object({
  cgu: yup.boolean().required('control.cgu.required').isTrue('control.cgu.required'),
});

type Schema = yup.InferType<typeof schema>;

const CguControl: React.FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslationContext(['common']);
  const { DONNONS_API_URL } = useEnvContext();
  const { invalidateControl } = Query.user.useInvalidate();

  const [route, setRoute] = useState<'control' | 'cgu'>('control');
  const goToCgu = () => setRoute('cgu');
  const goToControl = () => setRoute('control');

  const { control, onSubmit, errors } = useForm<UsersHasControlResult, Schema>({
    schema,
    apiDefinition: { method: ApiMethods.PUT, url: `${DONNONS_API_URL}${ApiVersions.V1}/user/control/cgu` },
    onSuccess: async () => {
      await invalidateControl();
    },
  });

  if (route === 'cgu') {
    return <CGU back={goToControl} />;
  }

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full grow flex-col gap-6 text-center">
        <div className="mx-auto flex size-30 items-center justify-center">
          <img loading="lazy" decoding="async" src="/assets/control/cgu.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-contain" />
        </div>
        <p className="text-body-primary text-center font-semibold text-content-secondary">{t('control.cgu.title', { ns: 'common' })}</p>
        <p className="text-body-secondary font-normal text-content-primary">{t('control.cgu.subtitle', { ns: 'common' })}</p>
        <Controller
          control={control}
          defaultValue={undefined}
          render={({ field: { onChange, onBlur, value } }) => {
            const { isError, message, isSuccess } = errors.cgu;
            return (
              <>
                <CheckboxInput onChange={onChange} onBlur={onBlur} id="cgu" value={value} validation={{ isError, isSuccess }}>
                  {t('control.cgu.label', { ns: 'common' })}&nbsp;
                  <button type="button" className="text-body-secondary !p-0 text-center text-content-secondary underline" onClick={goToCgu}>
                    {t('control.cgu.cgu', { ns: 'common' })}
                  </button>
                </CheckboxInput>
                {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
              </>
            );
          }}
          name="cgu"
        />
      </div>
      <div className="flex gap-4">
        <Button variant="secondary" onClick={logout}>
          {t('control.cgu.cancel', { ns: 'common' })}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t('control.cgu.submit', { ns: 'common' })}
        </Button>
      </div>
    </div>
  );
};

export default CguControl;
