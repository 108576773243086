import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Eye: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M1.66675 20.0001C1.66675 20.0001 8.33341 6.66675 20.0001 6.66675C31.6667 6.66675 38.3334 20.0001 38.3334 20.0001C38.3334 20.0001 31.6667 33.3334 20.0001 33.3334C8.33341 33.3334 1.66675 20.0001 1.66675 20.0001Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 25C22.7614 25 25 22.7614 25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25Z" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Eye;
