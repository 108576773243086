import { ErrorKind } from '@/core/lib/fetch';
import { ErrorCode, type ErrorResult } from '@/core/types/donnons';

export interface DonnonsErrorConstructor {
  response: Response;
  json?: ErrorResult;
}

export type NotLoggedIn = { type: 'NOT_LOGGED_IN' };
export type UserState = { type: 'USER_STATE' };

export type GlobalError = NotLoggedIn | UserState;

export abstract class DonnonsError extends Error {
  CAUGHT = true;

  public response: Response;

  public json?: ErrorResult;

  public kind: ErrorKind;

  constructor({ response, json }: DonnonsErrorConstructor) {
    super();
    this.response = response;
    this.json = json;

    if (!this.response) {
      this.kind = ErrorKind.Unknown;
    } else {
      switch (this.response.status) {
        case 400:
          this.kind = ErrorKind.BadRequest;
          break;

        case 401:
          this.kind = ErrorKind.Unauthorized;
          break;

        case 403:
          this.kind = ErrorKind.Forbidden;
          break;

        case 404:
          this.kind = ErrorKind.NotFound;
          break;

        case 500:
          this.kind = ErrorKind.Internal;
          break;

        case 503:
          this.kind = ErrorKind.Unavailable;
          break;

        default:
          this.kind = ErrorKind.Unknown;
          break;
      }
    }
  }

  public getResAndJson(): DonnonsErrorConstructor {
    return {
      response: this.response,
      json: this.json,
    };
  }

  public getGlobalError(): GlobalError | null {
    switch (this.response.status) {
      case 401:
        return { type: 'NOT_LOGGED_IN' };

      case 403:
        if (this.json?.error.code === ErrorCode.USER_STATE) {
          return { type: 'USER_STATE' };
        }

        return null;

      default:
        return null;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyError = { type: string; [key: string]: any };

export interface QueryError {
  getApiError: () => AnyError | null;
}

export interface MutationError extends QueryError {
  getFormErrors: () => AnyError[] | null;
}
