import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const XCircle: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="x-circle" clipPath="url(#clip0_2805_2213)">
      <path
        id="Vector"
        d="M7.50001 4.5L4.50001 7.5M4.50001 4.5L7.50001 7.5M11 6.00001C11 8.76143 8.76145 11 6.00003 11C3.23861 11 1.00003 8.76143 1.00003 6.00001C1.00003 3.23858 3.23861 1.00001 6.00003 1.00001C8.76145 1.00001 11 3.23858 11 6.00001Z"
        stroke="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2805_2213">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default XCircle;
