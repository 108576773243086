import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Menu: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M5 20H35" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 10H35" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 30H35" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Menu;
