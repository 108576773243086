import useMutation from '@/core/hooks/api/useMutation';
import { type APIError, ErrorKind } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';

interface ReverseParams {
  lat: number;
  lon: number;
}

type ReverseSuccess = Awaited<ReturnType<typeof Api.mutation.locs.reverse>>;

enum ReverseErrorEnum {
  NOT_FOUND,
  FORMAT,
}

type BaseError = {
  error: true;
  t: { key: string; ns: string };
};

type ReverseError = BaseError &
  (
    | {
        type: ReverseErrorEnum.NOT_FOUND;
      }
    | {
        type: ReverseErrorEnum.FORMAT;
      }
  );

const useReverse = () => {
  const mutation = useMutation<ReverseSuccess, APIError, ReverseParams>({
    mutationFn: async (data: ReverseParams) => Api.mutation.locs.reverse(data),
  });

  const onReverse = async (data: ReverseParams): Promise<ReverseSuccess | ReverseError> => {
    try {
      return await mutation.mutateAsync(data);
    } catch (err) {
      const error = err as APIError;
      switch (error.kind) {
        case ErrorKind.NotFound:
          return { error: true, type: ReverseErrorEnum.NOT_FOUND, t: { key: 'inputs.city.modal.not-found', ns: 'common' } };

        default:
          return { error: true, type: ReverseErrorEnum.NOT_FOUND, t: { key: 'inputs.city.modal.format', ns: 'common' } };
      }
    }
  };

  return { onReverse, isLoading: mutation.isPending };
};

export { useReverse };
