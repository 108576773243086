import type { ForwardedRef, RefObject } from 'react';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import isNullish from '@/core/lib/utils/isNullish';
import type { NewInputProps } from '@/core/types/components';

type UseTextInputParams<TProps extends NewInputProps<string>, RefElement extends HTMLInputElement | HTMLTextAreaElement> = TProps & {
  forwardedRef: ForwardedRef<RefElement>;
};

type UseTextInputReturn<TProps extends NewInputProps<string>, RefElement extends HTMLInputElement | HTMLTextAreaElement> = {
  ref: RefObject<RefElement>;
  isFocused: boolean;
  isCleanable: boolean;
  isDisabled: boolean;
  isError: boolean;
  isSuccess: boolean;
  value: TProps['value'];
  onChange: (event: React.ChangeEvent<RefElement>) => void;
  onBlur: (event: React.ChangeEvent<RefElement>) => void;
  onFocus: (event: React.FocusEvent<RefElement>) => void;
  onClick?: (event: React.MouseEvent<RefElement>) => void;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  cleanAttrs: {
    onMouseDown: (event: React.MouseEvent) => void;
    onClick: () => void;
    onBlur: () => void;
    onFocus: () => void;
  };
  props: TProps;
};

const useTextInput = <TProps extends NewInputProps<string>, RefElement extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(
  params: UseTextInputParams<TProps, RefElement>,
): UseTextInputReturn<TProps, RefElement> => {
  const { forwardedRef, value, onChange, onBlur, onClick, onClean: onPropsClean, validation, isDisabled, readOnly, id, placeholder } = params;
  const props = params satisfies TProps;

  const ref = useRef<RefElement>(null);
  useImperativeHandle(forwardedRef, () => ref.current as RefElement);

  const [localValue, setLocalValue] = useState<string>(value ?? '');
  useEffect(() => {
    setLocalValue(() => value);
  }, [value]);

  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => {
    if (!readOnly) setIsFocused(true);
  };

  const [isCleanable, setIsCleanable] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (readOnly) {
      setIsCleanable(false);
      setIsSuccess(false);
      setIsError(false);
      return;
    }

    const newIsCleanable = (() => {
      if (readOnly) return false;

      if (isDisabled) return false;

      if (isNullish(localValue)) return false;

      if (isFocused) return true;

      if (validation?.isSuccess) return false;

      return true;
    })();
    setIsCleanable(newIsCleanable);

    setIsSuccess(!newIsCleanable && !isDisabled && !isNullish(localValue) && (validation?.isSuccess ?? false));
    setIsError(validation?.isError ?? false);
  }, [readOnly, isDisabled, localValue, isFocused, validation]);

  const onClean = () => {
    if (isCleanable) {
      setLocalValue('');
      onChange?.('');
      onBlur?.('');
      onPropsClean?.();
    }
  };

  const onLocalChange = (event: React.ChangeEvent<RefElement>) => {
    if (!readOnly && !isDisabled) {
      setLocalValue(event.target.value);
      onChange?.(event.target.value);
    }
  };

  const onLocalBlur = (event: React.ChangeEvent<RefElement>) => {
    if (!readOnly && !isDisabled) {
      setLocalValue(event.target.value);
      setIsFocused(false);
      onBlur?.(event.target.value);
    }
  };

  return {
    ref,
    isFocused,
    onFocus,
    isCleanable,
    isDisabled: isDisabled ?? false,
    isSuccess,
    isError,
    value: localValue,
    onChange: onLocalChange,
    onBlur: onLocalBlur,
    onClick,
    id,
    placeholder,
    disabled: isDisabled,
    readOnly,
    props,
    cleanAttrs: {
      onMouseDown: e => e.preventDefault(),
      onFocus: () => {
        setIsFocused(true);
      },
      onBlur: () => {
        setIsFocused(false);
      },
      onClick: onClean,
    },
  };
};

export default useTextInput;
