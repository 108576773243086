import React from 'react';

interface SeparatorProps {
  content?: string;
}

const Separator: React.FC<SeparatorProps> = ({ content }) => {
  return (
    <div className="flex w-full items-center">
      <div className="h-[1px] grow bg-bg-secondary" />
      {content && <p className="px-8 font-normal text-content-placeholder">{content}</p>}
      <div className="h-[1px] grow bg-bg-secondary" />
    </div>
  );
};

export default Separator;
