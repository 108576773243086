import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { CategoriesSuggestionsResult } from '@/core/types/category';
import { categoriesSuggestionsSchema } from '@/core/types/category';
import { type DonationsCategoriesResult, donationsCategoriesResultSchema } from '@/core/types/donation';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async () =>
    api.get<DonationsCategoriesResult>({
      url: `${getUrl()}${ApiVersions.V1}/categories?nothing=nothing`,
      schema: donationsCategoriesResultSchema,
    }),

  suggestions: async (donationTitle?: string) =>
    api.get<CategoriesSuggestionsResult>({
      url: `${getUrl()}${ApiVersions.V1}/categories/suggest?title=${donationTitle}`,
      schema: categoriesSuggestionsSchema,
    }),
};

export default { query };
