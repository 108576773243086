import React, { useMemo } from 'react';

import Button from '@/core/components/Button';
import type { CatalogueSearchSchema } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import SavedSearchModal from '@/core/components/favorites/SavedSearchModal';
import { ToastStatus } from '@/core/components/Toast/Toast';
import useDisclosure from '@/core/hooks/useDisclosure';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useNativeContext } from '@/core/lib/native/native.context';
import Actions from '@/core/lib/new-architecture/actions';
import Store from '@/core/lib/new-architecture/store';
import Routes from '@/core/lib/router/routes';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const getLabelTrans = (search: CatalogueSearchSchema): string => {
  if (!search.title && !search.cat && !search.loc) {
    return 'search.recap.label-default';
  }

  if (search.title) {
    return search.title;
  }

  return 'search.recap.default';
};

interface CatalogueSearchRecapSeoProps {
  search?: CatalogueSearchSchema;
}

const CatalogueSearchRecapSeo: React.FC<CatalogueSearchRecapSeoProps> = () => {
  const { t } = useTranslationContext(['common']);
  const { isNative } = useNativeContext();
  const { openAuth } = useAuthContext();
  const toast = useToastContext();

  const { total, data: catalogue } = Store.catalogue.useCatalogue();
  const { data: current } = Store.current.useCurrent();

  const {
    search: { page = 1, size },
  } = Routes.CatalogueRoute.useParams();

  const { data: favorites } = Store.favorites.useFavorites();

  const { onCreate, isLoading } = Actions.savedSearch.useCreateSavedSearch();

  const savedSearch = favorites?.getSavedSearch(catalogue?.hash ?? '') ?? null;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const results = useMemo(() => {
    if (total === 0) {
      return t('search.recap.seo-zero', { ns: 'common' });
    }

    if (isNative) {
      return t('search.recap.seo-native', { total: total.toString(), ns: 'common' });
    }

    return t('search.recap.seo', { total: total.toString(), page: page.toString(), nPage: Math.ceil(total / size).toString(), ns: 'common' });
  }, [total, page, size, isNative]);

  if (!catalogue) {
    return null;
  }

  const { search, query } = catalogue;

  const onSaveSearch = async () => {
    if (!savedSearch) {
      // we need to send the id to the api and not the alias
      const cat = search.cat ? [search.cat.id] : undefined;
      onOpen();

      let label = t(getLabelTrans(search), { ns: 'common' });

      if (search.cat) {
        label += ` dans ${search.cat.label}`;
      }

      if (search.loc) {
        label += ` - ${search.loc.label} - ${search.loc.distance} km`;
      }

      onCreate({
        ...query,
        cat,
        label,
        push: false,
        email: false,
      });
      return;
    }
    toast?.open(t('search.recap.already-save', { ns: 'common' }), 2000, ToastStatus.INFO);
  };

  return (
    <>
      <div className={`mb-5 flex w-full ${isNative ? 'flex-row-reverse' : 'flex-col'} border-b border-stroke-tertiary pb-5`}>
        <div className={`flex w-full ${isNative ? 'justify-end' : 'justify-between gap-4'}`}>
          {!isNative && (
            <h1 className="text-body-secondary font-semibold text-content-primary">
              {search.title ? (
                <span className="text-content-secondary">&rdquo;{search.title}&rdquo;</span>
              ) : (
                <span className="text-content-secondary">{t('search.recap.default', { ns: 'common' })}</span>
              )}{' '}
              {search.cat && (
                <span>
                  {search.title && 'dans '}
                  {search.cat?.label}
                </span>
              )}{' '}
              {search.loc && (
                <span>
                  -&nbsp;{search.loc.label}
                  {search.loc.distance ? ` - ${search.loc.distance} km` : ''}
                </span>
              )}
            </h1>
          )}

          <div className="hidden max-w-max shrink-0 lg:inline-block">
            <Button variant="secondary" size="medium" onClick={current ? onSaveSearch : openAuth} isLoading={isLoading} iconRight="bell">
              {t('search.recap.save', { ns: 'common' })}
            </Button>
          </div>
          <div className="inline-block lg:hidden">
            <Button variant="secondary" size="medium" onClick={current ? onSaveSearch : openAuth} isLoading={isLoading} iconOnly="bell" />
          </div>
        </div>
        <p className="text-caption-primary mt-2 shrink-0 text-content-placeholder">{results}</p>
      </div>

      <SavedSearchModal savedSearch={savedSearch} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CatalogueSearchRecapSeo;
