import auth from '@/core/lib/router/auth.route';
import common from '@/core/lib/router/common.route';
import conversations from '@/core/lib/router/conversations.route';
import donations from '@/core/lib/router/donations.route';
import favorites from '@/core/lib/router/favorites.route';
import profile from '@/core/lib/router/profile.route';

const Routes = {
  ...auth,
  ...common,
  ...conversations,
  ...donations,
  ...favorites,
  ...profile,
};

export default Routes;
