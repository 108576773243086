import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import type { Query } from '@/core/lib/new-architecture/query/types.query';
import type { HomeResult } from '@/core/types/home';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/'] as const,
  version: () => [...keys.base, '/version'] as const,
};

const useHome: Query<HomeResult> = () => {
  return useQuery({
    queryKey: keys.version(),
    queryFn: () => Api.query.home.version(),
  });
};

export default { useHome };
