import React from 'react';

import Button from '@/core/components/Button';
import Icons from '@/core/components/Icons';
import useMutation from '@/core/hooks/useMutation';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useBrowserContext } from '@/core/lib/browser/browser.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { QUERY_KEYS } from '@/core/lib/query/query.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const AppleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 22" fill="none" className="mr-2 size-5">
    <path
      d="M6.15685 21.8C2.89075 21.7813 0.189087 15.1493 0.189087 11.7718C0.189087 6.25466 4.34989 5.04681 5.95343 5.04681C6.67608 5.04681 7.44772 5.3291 8.12832 5.5789C8.60426 5.75315 9.09649 5.93303 9.37024 5.93303C9.5341 5.93303 9.92024 5.78001 10.2612 5.64575C10.9882 5.35784 11.893 5 12.9465 5C12.9484 5 12.9509 5 12.9528 5C13.7394 5 16.1247 5.17173 17.5587 7.31389L17.8946 7.816L17.4112 8.17887C16.7206 8.69723 15.4604 9.64279 15.4604 11.5158C15.4604 13.7341 16.8875 14.5873 17.5732 14.9976C17.8758 15.1787 18.1891 15.3654 18.1891 15.7739C18.1891 16.0406 16.0493 21.7669 12.9421 21.7669C12.1818 21.7669 11.6443 21.5396 11.1703 21.3391C10.6906 21.1361 10.2768 20.9613 9.59308 20.9613C9.2465 20.9613 8.80824 21.1243 8.34426 21.2973C7.71021 21.5327 6.99253 21.8 6.1782 21.8H6.15685Z"
      fill="#172B2F"
    />
    <path d="M13.3871 0.200012C13.4577 3.10526 11.6366 5.12079 9.81763 4.99438C9.51789 2.67589 11.6364 0.200012 13.3871 0.200012Z" fill="#172B2F" />
  </svg>
);

const AppleButton: React.FC = () => {
  const { t } = useTranslationContext(['common', 'auth']);
  const { DONNONS_API_URL } = useEnvContext();
  const { open } = useBrowserContext();
  const { closeAuth } = useAuthContext();
  const { send } = useAnalyticsContext();

  const mutation = useMutation<string, void>({
    apiDefinition: { method: ApiMethods.GET, url: `${DONNONS_API_URL}${ApiVersions.V1}${QUERY_KEYS.authApple().join('/')}` },
  });

  const onClick = async () => {
    await send({ event: AnalyticsEventType.CLICK_LOGIN, origin: 'apple' });
    const res = await mutation.mutateAsync();
    await open(res);
    closeAuth();
  };

  return (
    <Button variant="gaf" onClick={onClick}>
      <span className="mr-2">{mutation.isPending ? <Icons icon="spinner" /> : <AppleIcon />}</span>
      {t('apple-button', { ns: 'auth' })}
    </Button>
  );
};

export default AppleButton;
