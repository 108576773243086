import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import { DonationsPublicDonationListResult, donationsPublicDonationListResultSchema } from '@/core/types/donation';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async (token?: string) =>
    api.get<DonationsPublicDonationListResult>({
      url: `${getUrl()}${ApiVersions.V1}/donations/followed`,
      schema: donationsPublicDonationListResultSchema,
      token,
    }),
};

const mutation = {
  follow: async (id: number, token?: string) => api.post({ url: `${getUrl()}${ApiVersions.V1}/donation/${id}/follow`, data: undefined, token }),
  unfollow: async (id: number, token?: string) => api.destroy({ url: `${getUrl()}${ApiVersions.V1}/donation/${id}/unfollow`, data: undefined, token }),
};

export default { query, mutation };
