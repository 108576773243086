import type { RefObject } from 'react';
import { useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(refs: RefObject<T>[], handler: (event: AnyEvent) => void): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const isOutside = refs
        .filter(ref => !!ref.current)
        .map(ref => {
          const el = ref?.current;
          // Do nothing if clicking ref's element or descendent elements
          if (!el || el.contains(event.target as Node)) {
            return;
          }

          return ref.current;
        });
      if (isOutside.every(e => e)) {
        handler(event);
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchend', listener);
    };
    // Reload only if ref or handler changes
  }, [refs, handler]);
}

export default useOnClickOutside;
