import type { PropsWithChildren } from 'react';
import React, { useMemo, useState } from 'react';

import type { IconName, IconSize } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const variants = {
  primary:
    'bg-bg-button-primary-default text-content-button-primary border-stroke-button-primary-default hover:bg-bg-button-primary-hover hover:border-stroke-button-primary-hover focus-visible:bg-bg-button-primary-pressed focus-visible:border-stroke-button-primary-pressed',
  secondary:
    'bg-bg-button-secondary text-content-button-secondary-default border-stroke-button-secondary-default hover:bg-bg-button-secondary-hover hover:border-stroke-button-secondary-hover hover:text-content-button-secondary-hover focus-visible:border-stroke-button-secondary-pressed focus-visible:text-content-button-secondary-pressed',
  gaf: 'bg-bg-primary text-content-primary border-bg-dark hover:bg-bg-pale font-medium',
  give: 'bg-bg-button-give-default text-content-button-primary border-stroke-button-give-default hover:bg-bg-button-give-hover hover:border-stroke-button-give-hover focus-visible:bg-bg-button-give-pressed focus-visible:border-stroke-button-give-pressed',
  ghost: 'bg-bg-button-ghost text-content-button-ghost-default border-transparent hover:bg-bg-button-ghost-hover hover:border-stroke-button-ghost-hover',
} satisfies { [key in ButtonVariant]: string };

const sizes = {
  small: 'text-button-small font-medium',
  medium: 'text-button-medium font-medium',
  large: 'text-button-large font-semibold',
} satisfies { [key in ButtonSize]: string };

const iconSizes = {
  small: '12',
  medium: '16',
  large: '20',
} satisfies { [key in ButtonSize]: IconSize };

const spinnerSizes = {
  small: '20',
  medium: '24',
  large: '32',
} satisfies { [key in ButtonSize]: IconSize };

const pressed = {
  primary: '!bg-bg-button-primary-pressed !border-stroke-button-primary-pressed',
  secondary: '!bg-bg-button-secondary !text-content-button-secondary-pressed !border-stroke-button-secondary-pressed',
  gaf: '',
  give: '!bg-bg-button-give-pressed !border-stroke-button-give-pressed',
  ghost: '',
} satisfies { [key in ButtonVariant]: string };

const loading = {
  primary: '!bg-bg-button-primary-pressed !border-stroke-button-secondary-pressed',
  secondary: '!bg-bg-button-secondary !text-content-button-secondary-pressed !border-stroke-button-secondary-pressed',
  gaf: '!text-content-button-secondary-pressed',
  give: '',
  ghost: '',
} satisfies { [key in ButtonVariant]: string };

const disabled = {
  primary: '!bg-bg-button-primary-disable !border-stroke-button-secondary-disable',
  secondary: '!bg-bg-button-secondary-disable !text-content-button-secondary-disable !border-stroke-button-secondary-disable',
  gaf: '',
  give: '',
  ghost: '!bg-bg-button-ghost-disable !border-stroke-button-ghost-disable !text-content-button-ghost-disable',
} satisfies { [key in ButtonVariant]: string };

export type ButtonVariant = 'primary' | 'secondary' | 'gaf' | 'give' | 'ghost';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonType = 'button' | 'submit';

export interface ButtonProps extends PropsWithChildren {
  id?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  type?: ButtonType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  iconLeft?: IconName;
  iconRight?: IconName;
  iconOnly?: IconName;
}

const BASE_BUTTON_CLASSES = 'border-solid border-1.5 focus-visible:outline-none rounded-2';

const Button: React.FC<ButtonProps> = ({ id, variant = 'primary', size = 'large', type = 'button', onClick, children, iconLeft, iconRight, iconOnly, isLoading, isDisabled }) => {
  const { t } = useTranslationContext(['common']);
  const [isPressed, setIsPressed] = useState(false);

  const paddingClasses = `${iconOnly ? `${size === 'medium' && 'p-3'} ${size === 'large' && 'p-4'}` : `${size === 'small' && 'p-1'} ${size === 'medium' && 'px-3 py-2'} ${size === 'large' && 'px-8 py-3'}`}`;

  const computedClasses = useMemo(() => {
    const variantClass = variants[variant];
    const sizeClass = sizes[size];

    return [variantClass, sizeClass].join(' ');
  }, [variant, size]);

  const stateClasses = useMemo(() => {
    const loadingClass = isLoading ? `cursor-not-allowed pointer-events-none ${loading[variant]}` : '';
    const pressedClass = isPressed ? pressed[variant] : '';
    const disabledClass = isDisabled ? `cursor-not-allowed ${disabled[variant]}` : '';
    return [loadingClass, pressedClass, disabledClass].join(' ');
  }, [isLoading, isPressed, isDisabled]);

  const iconSize = useMemo(() => {
    return iconSizes[size];
  }, [size]);
  const spinnerSize = useMemo(() => {
    return spinnerSizes[size];
  }, [size]);

  const onLocalClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    if (!isLoading && onClick) {
      onClick(event);
    }
  };

  const onPressed = () => {
    setIsPressed(true);
  };

  const onUnPressed = () => {
    setIsPressed(false);
  };

  return (
    <button
      id={id}
      type={type}
      className={`${BASE_BUTTON_CLASSES} ${computedClasses} ${stateClasses} ${paddingClasses} ${iconOnly ? '' : 'w-full'}`}
      onClick={onLocalClick}
      onMouseDown={onPressed}
      onMouseUp={onUnPressed}
      onTouchStart={onPressed}
      onTouchEnd={onUnPressed}
      aria-disabled={isLoading}
      disabled={isDisabled || isLoading}
    >
      {iconOnly ? (
        <span data-testid="icon-only">
          <Icons icon={iconOnly} weight="bold" color="current" size={`${size === 'large' ? '20' : '16'}`} />
        </span>
      ) : (
        <div className="relative flex items-center justify-center text-center">
          {isLoading && (
            <div aria-label={t('buttons.spinner.aria-valuetext', { ns: 'common' })} className="absolute">
              <Icons icon="spinner" color="current" size={spinnerSize} />
            </div>
          )}
          {!isLoading && iconLeft && (
            <span data-testid="left-icon">
              <Icons icon={iconLeft} weight="bold" color="current" size={iconSize} />
            </span>
          )}
          <span className={`${iconLeft || iconRight ? `${size === 'large' ? 'mx-2' : 'mx-1'}` : ''} inline-flex items-center justify-center text-nowrap ${isLoading ? 'opacity-0' : ''}`}>
            {children}
          </span>
          {!isLoading && iconRight && (
            <span data-testid="right-icon">
              <Icons icon={iconRight} weight="bold" color="current" size={iconSize} />
            </span>
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
