// en attendant la librairie

import React from 'react';

export interface RangeInputProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
}

const RangeInput: React.FC<RangeInputProps> = ({ value, onChange, min, max, step }) => {
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.valueAsNumber);
  };

  const defaultClasses = 'h-10 appearance-none focus:outline-none bg-transparent';

  // progression bar => -webkit-slider-runnable-track, -moz-range-track
  // progression bar completed (firefox only) => -moz-range-progress
  // rounded input => -webkit-slider-thumb, -moz-range-thumb

  const webkitClasses = `
    [&::-webkit-slider-runnable-track]:h-[3px]
    [&::-webkit-slider-runnable-track]:w-full
    [&::-webkit-slider-runnable-track]:cursor-pointer
    [&::-webkit-slider-runnable-track]:bg-[#7C7E88]
    [&::-webkit-slider-runnable-track]:rounded-[50px]

    [&::-webkit-slider-thumb]:size-5
    [&::-webkit-slider-thumb]:rounded-full
    [&::-webkit-slider-thumb]:bg-bg-button-primary-default
    [&::-webkit-slider-thumb]:cursor-pointer
    [&::-webkit-slider-thumb]:translate-y-[calc(-50%+1.5px)]
    [&::-webkit-slider-thumb]:appearance-none

    [&::-webkit-progress-value]:bg-bg-button-primary-default
  `;

  const mozClasses = `
    [&::-moz-range-track]:h-[3px]
    [&::-moz-range-track]:w-full
    [&::-moz-range-track]:cursor-pointer
    [&::-moz-range-track]:bg-[#7C7E88]
    [&::-moz-range-track]:rounded-[50px]

    [&::-moz-range-thumb]:size-5
    [&::-moz-range-thumb]:rounded-full
    [&::-moz-range-thumb]:bg-bg-button-primary-default
    [&::-moz-range-thumb]:cursor-pointer
    [&::-moz-range-thumb]:appearance-none
    [&::-moz-range-thumb]:border-none
    
    [&::-moz-range-progress]:bg-bg-button-primary-default
  `;

  return <input type="range" className={`${defaultClasses} ${webkitClasses} ${mozClasses}`} min={min} max={max} onChange={onChangeInput} value={value} step={step} />;
};

export default RangeInput;
