import { useMemo } from 'react';

import CguDocument from '@/core/lib/new-architecture/domain/documents.domain';
import Query from '@/core/lib/new-architecture/query';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const useCgu = (): StoreData<CguDocument> => {
  const { data, isLoading } = Query.documents.useCgu({ data: {} });

  const cgu = useMemo(() => {
    if (!data) {
      return null;
    }

    return new CguDocument({ data });
  }, [data]);

  return {
    data: cgu,
    isLoading,
    notFound: false,
  };
};

export default { useCgu };
