import 'intersection-observer';

interface ObserveParams {
  root: Element | Document;
  el: Element;
  callback: (inView: boolean, entry: IntersectionObserverEntry) => void;
  rootMargin?: string;
  threshold?: number;
}

const observe = ({ root, el, callback, rootMargin = '0px', threshold = 0.5 }: ObserveParams) => {
  const options = {
    root,
    rootMargin,
    threshold,
  };

  const observer = new IntersectionObserver(entries => {
    const entry = entries[0];

    const inView = entry.isIntersecting && [options.threshold].some(thr => entry.intersectionRatio >= thr);

    callback(inView, entry);
  }, options);

  observer.observe(el);

  return observer.disconnect;
};

export default observe;
