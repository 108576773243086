import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { ConversationItemResult, ConversationsConversationForm } from '@/core/types/conversation';
import { conversationItemResult } from '@/core/types/conversation';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

interface ConversationQuery {
  id: number;
  from: number;
  size: number;
}

const query = {
  get: async (data: ConversationQuery, token: string) => {
    const params = new URLSearchParams();
    params.set('from', data.from.toString());
    params.set('size', data.size.toString());

    return api.get<ConversationItemResult>({
      url: `${getUrl()}${ApiVersions.V1}/conversation/${data.id}${Array.from(params.entries()).length > 0 ? `?${params.toString()}` : ''}&nothing=nothing`,
      schema: conversationItemResult,
      token,
    });
  },
};

const mutation = {
  create: async (donationId: number, data: ConversationsConversationForm, token?: string) =>
    api.post<ConversationItemResult, ConversationsConversationForm>({ url: `${getUrl()}${ApiVersions.V1}/donation/${donationId}/conversation`, data, token, schema: conversationItemResult }),
  send: async (conversationId: number, data: ConversationsConversationForm, token?: string) =>
    api.post<ConversationItemResult, ConversationsConversationForm>({ url: `${getUrl()}${ApiVersions.V1}/conversation/${conversationId}`, data, token, schema: conversationItemResult }),
};

export default { query, mutation };
