import { useQueryClient } from '@tanstack/react-query';

import useInfiniteQuery from '@/core/hooks/api/useInfiniteQuery';
import useQuery from '@/core/hooks/api/useQuery';
import { DONATIONS_SIZE } from '@/core/lib/constants';
import Api from '@/core/lib/new-architecture/api';
import type { InfiniteQuery, Query } from '@/core/lib/new-architecture/query/types.query';
import type { DonationsPublicDonationQuery } from '@/core/types/donation';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/donations'] as const,
  donations: (data: DonationsPublicDonationQuery) => [...keys.base, JSON.stringify(data)] as const,
  donationsCount: (data: DonationsPublicDonationQuery) => [...keys.base, '/count', JSON.stringify(data)] as const,
};

type AwaitedDonations = Awaited<ReturnType<typeof Api.query.donations.get>>;

const useDonations: InfiniteQuery<AwaitedDonations, DonationsPublicDonationQuery> = ({ data }) => {
  return useInfiniteQuery({
    queryKey: keys.donations(data),
    queryFn: ({ pageParam }) => Api.query.donations.get({ ...data, from: pageParam as number }),
    retry: 0,
    initialPageParam: data.from ?? 0,
    getNextPageParam: (lastPage, pages) => {
      const { length } = pages.flatMap(page => page.donations);

      if (length >= lastPage.total) return undefined;

      const size = data.size ?? DONATIONS_SIZE;
      return (length / size) * size;
    },
  });
};

useDonations.prefetch = async ({ queryClient, ...data }) => {
  await queryClient.prefetchInfiniteQuery({
    queryKey: keys.donations(data),
    queryFn: () => Api.query.donations.get({ ...data }),
    initialPageParam: data.from ?? 0,
  });
};

type AwaitedDonationsCount = Awaited<ReturnType<typeof Api.query.donations.count>>;

const useDonationsCount: Query<AwaitedDonationsCount, DonationsPublicDonationQuery> = ({ data }) => {
  return useQuery({
    queryKey: keys.donationsCount(data),
    queryFn: () => Api.query.donations.count(data),
    retry: 0,
  });
};

const useInvalidate = (data?: DonationsPublicDonationQuery) => {
  const queryClient = useQueryClient();
  const queryKey = data ? keys.donations(data) : keys.base;
  const invalidate = () => queryClient.invalidateQueries({ queryKey });
  return { invalidate };
};

export default { useDonations, useDonationsCount, useInvalidate };
