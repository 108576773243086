import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const ChevronsLeft: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M18.3333 28.3333L10 20L18.3333 11.6666" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.0001 28.3333L21.6667 20L30.0001 11.6666" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronsLeft;
