import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Bell: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M22.8833 35C22.5903 35.5051 22.1698 35.9244 21.6637 36.2159C21.1577 36.5073 20.584 36.6608 20 36.6608C19.416 36.6608 18.8423 36.5073 18.3363 36.2159C17.8303 35.9244 17.4097 35.5051 17.1167 35M30 13.3334C30 10.6812 28.9464 8.13767 27.0711 6.26231C25.1957 4.38694 22.6522 3.33337 20 3.33337C17.3478 3.33337 14.8043 4.38694 12.9289 6.26231C11.0536 8.13767 10 10.6812 10 13.3334C10 25 5 28.3334 5 28.3334H35C35 28.3334 30 25 30 13.3334Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bell;
