import { DEPARTMENT_ID_REGEX } from '@/core/lib/regex';
import type { PrivateDonationItemResult } from '@/core/types/donation';
import type { AdminResult, CityResult, CountryResult, LocResult, SubResult } from '@/core/types/geo';
import type { UsersPrivateProfilResult, UsersPublicProfilResult } from '@/core/types/profile';

const extractDepartmentFromId = (id: string | undefined): string | undefined => {
  const idResults = id?.match(DEPARTMENT_ID_REGEX);
  return idResults && idResults.length > 0 ? idResults[0].split('-')[1] : undefined;
};

export const locResultToString = (locResult: LocResult | null | undefined): string => {
  if (!locResult) return '';

  const department = extractDepartmentFromId(locResult.admin?.id);
  const { sub, city, admin } = locResult;

  if (department) {
    if (sub?.name) {
      return `${sub.name} (${department})`;
    }
    if (city?.name) {
      return `${city.name} (${department})`;
    }
    if (admin?.name) {
      return `${admin.name} (${department})`;
    }
  }

  if (sub?.name) return sub.name;

  if (city?.name) {
    return admin?.name ? `${city.name} (${admin.name})` : city.name;
  }

  return admin?.name ?? '';
};

export const locResultToAlias = (res: LocResult | null): string => {
  if (!res) return '';

  if (res.sub?.alias) return res.sub.alias;

  if (res.city?.alias) return res.city.alias;

  if (res.admin?.alias) return res.admin.alias;

  return '';
};

export const aliasToString = (alias: string | null, data: LocResult[] | undefined): string => {
  if (!alias) return '';

  const find = (data ?? []).find(locResult => {
    if (locResult.sub?.alias === alias) return true;

    if (locResult.city?.alias === alias) return true;

    if (locResult.admin?.alias === alias) return true;

    return false;
  });

  if (find) return locResultToString(find);

  return alias;
};

export const userToAlias = (user: UsersPrivateProfilResult | UsersPublicProfilResult): string | undefined => {
  if (user.sub) {
    const userSub = user.subs?.find(sub => sub.id === user.sub);

    if (userSub) return userSub.alias;
  }

  const userCity = user.cities?.find(city => city.id === user.city);

  if (userCity) return userCity.alias;

  return undefined;
};

export const userToName = (user: UsersPrivateProfilResult | UsersPublicProfilResult): string | undefined => {
  if (user.sub) {
    const userSub = user.subs?.find(sub => sub.id === user.sub);

    if (userSub) return userSub.name;
  }

  const userCity = user.cities?.find(city => city.id === user.city);

  if (userCity) return userCity.name;

  return undefined;
};

export interface LocArrays {
  countries: CountryResult[];
  admins: AdminResult[];
  cities: CityResult[];
  subs: SubResult[];
}

export const fromArraysToString = (cityId: number | undefined, data: LocArrays) => {
  if (!cityId) {
    return undefined;
  }

  const city = data.cities.find(c => c.id === cityId);

  if (!city) {
    return undefined;
  }

  const dep = data.admins.find(admin => admin.type === 'Département' && admin.id === city.admin);
  const dp = dep?.id.split('-')[1];

  if (!dp || Number.isNaN(parseInt(dp, 10))) {
    if (!dep) {
      return city.name;
    }
    return `${city.name} (${dep.name})`;
  }

  return `${city.name} (${dp})`;
};

export const donationToAlias = (donation: PrivateDonationItemResult): string | undefined => {
  if (donation.donation.sub) {
    const donationSub = donation.subs?.find(sub => sub.id === donation.donation.sub);

    if (donationSub) return donationSub.alias;
  }

  const donationCity = donation.cities?.find(city => city.id === donation.donation.city);

  if (donationCity) return donationCity.alias;

  return undefined;
};
