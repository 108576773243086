import { useQueryClient } from '@tanstack/react-query';

import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import DonationQuery from '@/core/lib/new-architecture/query/donation.query';
import DonationsQuery from '@/core/lib/new-architecture/query/donations.query';
import getToken from '@/core/lib/new-architecture/query/token';
import type { Query } from '@/core/lib/new-architecture/query/types.query';
import UserDonationsQuery from '@/core/lib/new-architecture/query/user.donations.query';
import UserQuery from '@/core/lib/new-architecture/query/user.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/user', '/donation'] as const,
  donation: (id: number) => [...keys.base, id] as const,
};

interface DonationParams {
  id?: number;
}

const useDonation: Query<Awaited<ReturnType<typeof Api.query.user.donation.get>>, DonationParams> = ({ enabled = true, data: { id }, token }) => {
  return useQuery({
    queryKey: keys.donation(id as number),
    queryFn: async () => {
      return Api.query.user.donation.get(id as number, await getToken(token));
    },
    enabled: enabled && !!id,
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  return { invalidate };
};

const useInvalidateAfterCreateOrUpdateDonation = () => {
  const { invalidate: invalidateUser } = UserQuery.useInvalidate();
  const { invalidate: invalidateUserDonations } = UserDonationsQuery.useInvalidate();
  const { invalidateDonation } = DonationQuery.useInvalidate();
  const { invalidate: invalidateDonations } = DonationsQuery.useInvalidate();
  const invalidateAfterCreateOrUpdateDonation = async (donationId: number) => {
    await invalidateUser();
    await invalidateUserDonations();
    await invalidateDonation(donationId);
    await invalidateDonations();
  };
  return { invalidateAfterCreateOrUpdateDonation };
};

const useInvalidateAfterDeleteDonation = () => {
  const { invalidate: invalidateUser } = UserQuery.useInvalidate();
  const { invalidate: invalidateUserDonations } = UserDonationsQuery.useInvalidate();
  const { invalidate: invalidateDonations } = DonationsQuery.useInvalidate();
  const invalidateAfterDeleteDonation = () => {
    invalidateUser();
    invalidateUserDonations();
    invalidateDonations();
  };
  return { invalidateAfterDeleteDonation };
};

export default { useDonation, useInvalidate, useInvalidateAfterCreateOrUpdateDonation, useInvalidateAfterDeleteDonation };
