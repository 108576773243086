import type { InfiniteData } from '@tanstack/react-query';

import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import type CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import type { DonationsPrivateDonationListResult, DonationsPrivateDonationQuery } from '@/core/types/donation';
import type { AdminResult, CityResult, CountryResult, SubResult } from '@/core/types/geo';
import type { UserResult } from '@/core/types/users';

interface UserDonationsDomainInterface {
  donations: DonationDomain[];
  query: DonationsPrivateDonationQuery;
}

interface UserDonationsDomainConstructor {
  data: InfiniteData<DonationsPrivateDonationListResult>;
  categories: CategoriesDomain;
  current: CurrentDomain | null;
  query: DonationsPrivateDonationQuery;
}

class UserDonationsDomain implements UserDonationsDomainInterface {
  public donations: DonationDomain[];

  public query: DonationsPrivateDonationQuery;

  constructor({ data, categories, current, query }: UserDonationsDomainConstructor) {
    this.query = query;
    const { admins, cities, countries, subs, users } = data.pages
      .flatMap(page => page)
      .reduce(
        (acc, page) => ({
          admins: [...acc.admins, ...(page.admins ?? [])],
          cities: [...acc.cities, ...(page.cities ?? [])],
          countries: [...acc.countries, ...(page.countries ?? [])],
          subs: [...acc.subs, ...(page.subs ?? [])],
          users: [...acc.users, ...(page.users ?? [])],
        }),
        {
          admins: [] as AdminResult[],
          cities: [] as CityResult[],
          countries: [] as CountryResult[],
          subs: [] as SubResult[],
          users: [] as UserResult[],
        },
      );

    this.donations = data.pages.flatMap(page =>
      page.donations?.map(
        donation =>
          new DonationDomain({
            data: {
              donation,
              admins,
              cities,
              countries,
              subs,
              // TO-DO remove that it should be filtered by the domain
              users: users.filter(u => u.uuid === donation.giver),
              conversation: null,
              new_messages: 0,
              can_create_conversation: false,
              options: [],
            },
            categories,
            current,
            followedDonationsIds: [],
          }),
      ),
    );
  }
}

export default UserDonationsDomain;
