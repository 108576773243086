import type { MouseEvent } from 'react';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Icons from '@/core/components/Icons';
import useOnClickOutside from '@/core/hooks/useOnClickOutside';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { InputProps } from '@/core/types/components';

interface PasswordProps extends Omit<InputProps<string | null>, 'value' | 'max'> {
  autocomplete?: 'current-password' | 'new-password';
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordProps>(({ id, placeholder, validation, onBlur, onChange, autocomplete, enterKeyHint = 'next' }, ref) => {
  const { t } = useTranslationContext(['common']);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  useOnClickOutside([divRef], () => {
    if (onBlur && inputRef?.current?.contains(document.activeElement)) {
      onBlur(inputRef?.current?.value || '');
    }
  });

  return (
    // I don't use click-events on this elements.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className="flex w-full flex-col"
      ref={divRef}
      onClick={(event: MouseEvent) => {
        if (divRef.current?.contains(event.target as Node)) {
          inputRef.current?.focus();
        }
      }}
    >
      <div
        className={`flex w-full rounded-2 border py-2 ${inputRef.current?.value ? 'border-content-placeholder bg-bg-primary' : 'border-bg-secondary'} focus-within:bg-primary p-2 focus-within:ring-2 ${
          validation?.isError ? '!border-stroke-danger-focus ring-2 !ring-stroke-danger-focus/20' : ''
        } p-1 focus-within:border-stroke-default-focus focus-within:bg-bg-primary focus-within:ring-stroke-default-focus/20`}
      >
        <input
          id={id}
          ref={inputRef}
          type={showPassword ? 'text' : 'password'}
          onChange={event => {
            if (onChange) {
              onChange(event.target.value);
            }
          }}
          placeholder={placeholder}
          className="disabled:placeholder:text-accent ml-2 w-full bg-transparent text-content-primary placeholder:font-normal placeholder:text-content-placeholder focus:outline-none"
          autoComplete={autocomplete}
          inputMode="text"
          enterKeyHint={enterKeyHint}
        />
        <button
          aria-label={t('inputs.password.aria-label-eye', { ns: 'common' })}
          type="button"
          className="text-black mr-3"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? <Icons icon="eye" size="20" color="placeholder" /> : <Icons icon="eye-off" size="20" color="placeholder" />}
        </button>
      </div>
    </div>
  );
});

export default PasswordInput;
