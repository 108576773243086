import React from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import Alert from '@/core/components/Alert';
import Button from '@/core/components/Button';
import FormContainer from '@/core/components/FormContainer';
import HintMessage from '@/core/components/HintMessage';
import { NewTextInput } from '@/core/components/inputs/TextInput';
import Label from '@/core/components/Label';
import type { EmailErrorControlStepProps } from '@/core/components/UserControl/controls/EmailErrorControl/helper';
import useForm from '@/core/hooks/useForm';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { UsersHasControlResult } from '@/core/types/control';

const EmailErrorControlMailEdit: React.FC<EmailErrorControlStepProps & { setNewMail: React.Dispatch<React.SetStateAction<string | undefined>> }> = ({ onNext, onPrevious, setNewMail }) => {
  const { DONNONS_API_URL } = useEnvContext();
  const { t } = useTranslationContext(['common']);
  const ns = { ns: 'common' };

  const schema = yup.object({
    email: yup
      .string()
      .email(t('errors.inputs.email', ns))
      .required(t('errors.inputs.required', { ns: 'common' })),
  });

  type ChangeEmailForm = yup.InferType<typeof schema>;

  const { control, errors, onSubmit, isLoading, onCleanGlobalError, getValues } = useForm<UsersHasControlResult, ChangeEmailForm>({
    schema,
    apiDefinition: { method: ApiMethods.POST, url: `${DONNONS_API_URL}${ApiVersions.V1}/user/email` },
    onSuccess: () => {
      if (getValues().email) {
        setNewMail(getValues().email);
        onNext?.();
      }
    },
  });

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full grow flex-col gap-6">
        <div className="mx-auto flex size-30 items-center justify-center">
          <img loading="lazy" decoding="async" src="/assets/control/email_edit.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-contain" />
        </div>
        <p className="text-body-primary text-center font-semibold text-content-secondary">{t('control.email-error.edit-mail.title', ns)}</p>

        {errors.global.isError && errors.global.message && (
          <Alert status="error" onClose={onCleanGlobalError}>
            {t(errors.global.message, ns)} {errors.global.code}
          </Alert>
        )}
        <FormContainer>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => {
              const { isError, message, isSuccess } = errors.email;
              return (
                <Label htmlFor="email" content={t('control.email-error.edit-mail.input-label', ns)}>
                  <NewTextInput id="email" value={value} type="email" onChange={onChange} placeholder={t('control.email-error.edit-mail.input-placeholder', ns)} enterKeyHint="done" />
                  <HintMessage isError={isError} isSuccess={isSuccess} message={message} />
                </Label>
              );
            }}
            name="email"
            defaultValue=""
          />
        </FormContainer>
      </div>
      <div className="flex gap-4">
        <Button variant="secondary" onClick={onPrevious}>
          {t('control.email-error.edit-mail.cta-cancel', ns)}
        </Button>
        <Button variant="primary" type="submit" isLoading={isLoading} onClick={onSubmit}>
          {t('control.email-error.edit-mail.cta-submit', ns)}
        </Button>
      </div>
    </div>
  );
};

export default EmailErrorControlMailEdit;
