import * as yup from 'yup';

export enum ErrorCode {
  REQUIRED = 'required',
  IS_EMAIL = 'is_email',
  USER_LOGIN = 'user_signin',
  INVALID_PASSWORD = 'invalid_password',
  CITY_NOT_EXIST = 'city_not_exist',
  NOT_AVAILABLE = 'not_available',
  INVALID_OTP = 'invalid_otp',
  PASSWORD_FORMAT = 'password_format',
  OVER_QUOTA = 'over_quota',
  HAS_NOT_URL = 'has_not_url',
  DEFAULT = 'default',
  DONATION_PUBLIC_SEARCH = 'donation_public_search',
  VALIDATION = 'validation',
  USER_STATE = 'user_state',
}

type ErrorStatusCode = 400 | 401 | 403 | 404 | 500 | 503;

const transKeys = {
  required: 'errors.inputs.required',
  is_email: 'errors.inputs.email',
  user_signin: 'errors.bad-login',
  invalid_password: 'errors.bad-login',
  password_format: 'errors.inputs.password',
  city_not_exist: 'errors.city-not-exist',
  not_available: 'errors.email-already-exists',
  invalid_otp: 'errors.otp-invalid',
  over_quota: 'errors.over_quota',
  has_not_url: 'errors.inputs.has_not_url',
} satisfies { [key in ErrorCode]?: string };

export const codeToTransKey = (code: ErrorCode | undefined): string => {
  if (!code) return 'errors.unknown';

  // @ts-expect-error do not want to map all errors
  return transKeys[code] ?? 'errors.unknown';
};

export const errorResultToTransKey = (err?: ErrorResult): { message: string; code: string } => {
  const errors = Object.entries(err?.errors ?? {});

  if (errors.length > 0) {
    const [key, messageResult] = errors[0];

    return {
      message: codeToTransKey(messageResult.code),
      code: `${key} : ${messageResult.code}`,
    };
  }

  return {
    message: codeToTransKey(err?.error.code),
    code: `global : ${err?.error.code}`,
  };
};

export interface ErrorMessageResult {
  code: ErrorCode;
  message?: string;
}

export interface ErrorResult {
  extra?: string[];
  status?: ErrorStatusCode;
  errors?: {
    [key: string]: ErrorMessageResult;
  };
  error: ErrorMessageResult;
}

export const uploadResultSchema = yup.object({
  uuid: yup.string().required(),
  url: yup.string().required(),
});

export type UploadResult = yup.InferType<typeof uploadResultSchema>;
