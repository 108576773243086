import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const CarouselChevronRight: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="chevron-right">
      <path id="Vector" d="M7.5 15L12.5 10L7.5 5" stroke="#262641" strokeWidth="2.08" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

export default CarouselChevronRight;
