import { useMemo } from 'react';

import UserDonationsDomain from '@/core/lib/new-architecture/domain/userDonations.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { InfiniteStoreData } from '@/core/lib/new-architecture/store/store.types';
import Routes from '@/core/lib/router/routes';
import type { DonationsPrivateDonationQuery } from '@/core/types/donation';
import { donationsPrivateDonationQuerySchema } from '@/core/types/donation';

const useUserDonations = (override?: DonationsPrivateDonationQuery): InfiniteStoreData<UserDonationsDomain> => {
  const { data: current, isLoading: userIsLoading } = Store.current.useCurrent();
  const { requestToken, isLoggedIn } = Store.auth.useRequest();
  const { data: categories, isLoading: categoriesIsLoading } = Store.categories.useCategories();
  const params = Routes.ProfilePostsRoute.useParams();
  const query = donationsPrivateDonationQuerySchema.cast({ ...params.page, ...params.search }, { stripUnknown: true, assert: false });

  const {
    data: pages,
    isFetching: donationsIsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = Query.user.donations.useUserDonations({ data: override ?? query, token: requestToken, enabled: !!isLoggedIn });

  const isLoading = userIsLoading || categoriesIsLoading || donationsIsLoading;

  const data = useMemo(() => {
    if (!pages || !categories) {
      return null;
    }

    return new UserDonationsDomain({ data: pages, categories, current, query });
  }, [pages, categories, current, query]);

  return {
    data,
    isLoading,
    isFetchingNextPage,
    notFound: false,
    hasNext: hasNextPage,
    total: pages?.pages.reduce((acc, page) => acc + page.donations.length, 0) ?? 0,
    next: () => fetchNextPage(),
  };
};

export default { useUserDonations };
