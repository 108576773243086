import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const EyeOff: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M16.5 7.06655C17.6472 6.79801 18.8218 6.66378 20 6.66655C31.6667 6.66655 38.3334 19.9999 38.3334 19.9999C37.3217 21.8926 36.1151 23.6744 34.7334 25.3165M23.5334 23.5332C23.0756 24.0245 22.5236 24.4185 21.9103 24.6918C21.2969 24.965 20.6349 25.112 19.9635 25.1238C19.2922 25.1357 18.6253 25.0122 18.0027 24.7607C17.3801 24.5092 16.8146 24.1349 16.3398 23.6601C15.865 23.1853 15.4907 22.6198 15.2392 21.9972C14.9877 21.3746 14.8642 20.7077 14.8761 20.0364C14.8879 19.365 15.0349 18.703 15.3081 18.0896C15.5814 17.4763 15.9754 16.9243 16.4667 16.4665M29.9 29.8999C27.051 32.0715 23.5819 33.2747 20 33.3332C8.33335 33.3332 1.66669 19.9999 1.66669 19.9999C3.73984 16.1364 6.61525 12.7609 10.1 10.0999L29.9 29.8999Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.66669 1.66675L38.3334 38.3334" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EyeOff;
