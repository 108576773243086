import * as yup from 'yup';

export const usersControlResultSchema = yup.object({
  type: yup.number().required(),
  data: yup.object().optional(),
  created_at: yup.string().required(),
});

export type UsersControlResult = yup.InferType<typeof usersControlResultSchema>;

export const usersHasControlResultSchema = yup.object({
  has_control: yup.boolean().required(),
});

export type UsersHasControlResult = yup.InferType<typeof usersHasControlResultSchema>;
