import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';

import { useSSRContext } from '@/core/lib/new-architecture/context/ssr.context';
import NotFound from '@/core/lib/router/notfound.route';
import type { AbstractRoute } from '@/core/lib/router/route';
import type { RouterContextType, RouterQuery } from '@/core/lib/router/router.context';
import { RouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import Link from '@/next/lib/router/Link.next';

const RouterProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { back: routerBack, push, replace, asPath, query: urlParams, beforePopState } = useRouter();
  const { path } = useSSRContext();

  const windowUrl = typeof window !== 'undefined' ? new URL(window.location.href) : new URL(`http://doesnotmatter.com${path}`);

  const currentPathname = typeof window !== 'undefined' ? windowUrl.pathname : path;

  const currentPage = useMemo(() => {
    const Route = Object.values(Routes).find(route => route.test(windowUrl.pathname))!;

    if (!Route) {
      return NotFound.init(windowUrl);
    }

    return Route.init(windowUrl);
  }, [windowUrl]);

  const pushWithParams = useCallback((route: AbstractRoute) => {
    const url = route.resolve();
    push(url, url, { shallow: true });
  }, []);

  const replaceWithParams = useCallback((route: AbstractRoute) => {
    const url = route.resolve();
    replace(url, url, { shallow: true });
  }, []);

  const back = useCallback((fallback?: AbstractRoute) => {
    if (window.history.length > 1) {
      routerBack();
    } else if (fallback) {
      pushWithParams(fallback);
    }
  }, []);

  const value = useMemo(() => {
    const match = asPath.match(/.*\?(.*)/);
    const searchParams = new URLSearchParams(match ? match[1] : '');
    const queryParams: RouterQuery = Array.from(searchParams.entries()).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
    return {
      back,
      beforePopState: typeof window !== 'undefined' ? beforePopState : undefined,
      push: pushWithParams,
      replace: replaceWithParams,
      Link,
      currentPage,
      currentPathname,
      query: { ...queryParams, ...(urlParams as RouterQuery) },
    } satisfies RouterContextType;
  }, [currentPage, asPath, urlParams, currentPathname]);

  return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>;
};

export default RouterProvider;
