import React from 'react';

import { AbstractRoute, YieldLoveExperiment } from '@/core/lib/router/route';
import AdSense from '@/next/lib/ads/AdSense';
import YieldLoveNoLazyLoad from '@/next/lib/ads/YieldLoveNoLazyLoad';
import YieldLoveUniqueId from '@/next/lib/ads/YieldLoveUniqueId';

const AdsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    common: { adsense, yieldlove },
  } = AbstractRoute.useCommonParams();

  if (adsense === 1) {
    return <AdSense>{children}</AdSense>;
  }

  if (yieldlove === YieldLoveExperiment.ONLY_USE_DIV_ID) {
    return <YieldLoveUniqueId>{children}</YieldLoveUniqueId>;
  }
  return <YieldLoveNoLazyLoad>{children}</YieldLoveNoLazyLoad>;
};

export default AdsProvider;
