import React from 'react';

import Button from '@/core/components/Button';
import Header from '@/core/components/Header';
import Separator from '@/core/components/Separator';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useComponentsContext } from '@/core/lib/components/components.context';
import { useNativeContext } from '@/core/lib/native/native.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import { AuthRoutes, useAuthRoutesContext } from '@/core/pages/auth/Authentification';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';

const Auth: PageWithLayoutsAndProviders = () => {
  const { t } = useTranslationContext(['common', 'auth']);
  const { send } = useAnalyticsContext();
  const { isNative } = useNativeContext();
  const { AppleButton, FacebookButton, GoogleButton } = useComponentsContext();

  const { go, onClose } = useAuthRoutesContext();

  // Need go back on history only if isNative
  const onAfter = () => onClose({ navigateBack: isNative });

  const onLogin = async () => {
    await send({ event: AnalyticsEventType.CLICK_LOGIN, origin: 'email' });
    go(AuthRoutes.LOGIN);
  };

  const onRegister = async () => {
    await send({ event: AnalyticsEventType.CLICK_SIGN_UP, where: 'Modal' });
    go(AuthRoutes.REGISTER);
  };

  return (
    <div className="flex h-full flex-col gap-4 md:gap-7">
      <Header content={t('nav-content', { ns: 'auth' })} iconAfter="close" onAfter={onAfter} />
      <div className="inline-flex h-full flex-col gap-5 pb-7">
        <div className="inline-flex flex-col gap-4 md:gap-5">
          <div className="flex flex-col text-start md:text-center">
            <h1 className="text-body-primary mt-2 font-semibold text-content-primary">{t('title', { ns: 'auth' })}</h1>
            <p className="text-body-secondary font-medium text-content-primary">{t('catchphrase', { ns: 'auth' })}</p>
          </div>
          <div className="flex flex-col gap-4 md:px-4 xl:px-14">
            <FacebookButton />
            <GoogleButton />
            <AppleButton />
          </div>
        </div>

        <Separator content={t('separator', { ns: 'auth' })} />

        <div className="flex w-full flex-col items-center gap-5 px-3 md:px-7 xl:px-17">
          <Button variant="primary" onClick={onLogin}>
            {t('email-connexion-button', { ns: 'auth' })}
          </Button>
          <div className="text-center">
            <p className="text-content-primary">{t('no-account', { ns: 'auth' })}</p>
            <button type="button" className="text-body-secondary !p-0 text-center text-content-secondary underline" onClick={onRegister}>
              {t('create-account', { ns: 'auth' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
