import type { Dayjs } from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import React, { createContext, useContext, useMemo, useState } from 'react';

export interface AuthTokens {
  accessToken: string | null;
  accessExpiresAt: Dayjs | null;
  refreshToken: string | null;
  refreshExpiresAt: Dayjs | null;
}

export interface AuthContextType extends AuthTokens {
  isReady: boolean;
  setTokens: Dispatch<SetStateAction<AuthTokens>>;
  setIsReady: Dispatch<SetStateAction<boolean>>;
}

const defaultContext = {
  accessToken: null,
  accessExpiresAt: null,
  refreshToken: null,
  refreshExpiresAt: null,
} satisfies AuthTokens;

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => useContext(AuthContext) as AuthContextType;

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [tokens, setTokens] = useState<AuthTokens>(defaultContext);
  const [isReady, setIsReady] = useState(false);

  const value = useMemo(
    () => ({
      ...tokens,
      isReady,
      setTokens,
      setIsReady,
    }),
    [tokens, isReady],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
