import React, { createContext, useContext, useMemo } from 'react';
import * as yup from 'yup';

import useDisclosure from '@/core/hooks/useDisclosure';
import { useNativeContext } from '@/core/lib/native/native.context';
import Store from '@/core/lib/new-architecture/store';
import { donationsCategoryResultSchema } from '@/core/types/donation';
import { locationFormSchema } from '@/core/types/geo';

export const catalogueSearchSchema = yup.object({
  loc: locationFormSchema.nullable().default(null),
  cat: donationsCategoryResultSchema.nullable().default(null),
  title: yup.string().max(100, 'errors.inputs.max-100').nullable(),
});

export type CatalogueSearchSchema = yup.InferType<typeof catalogueSearchSchema>;

export interface CatalogueSearchContextType {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  data: CatalogueSearchSchema;
}

export const CatalogueSearchContext = createContext<CatalogueSearchContextType | null>(null);

export const useCatalogueSearchContext = () => useContext(CatalogueSearchContext) as CatalogueSearchContextType;

const CatalogueSearchProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isOpen, onOpen: onOpenState, onClose: onCloseState } = useDisclosure();
  const { setShouldHideKeyboard } = useNativeContext();

  const { data: catalogue } = Store.catalogue.useCatalogue();

  const data = catalogue?.search ?? {
    loc: null,
    cat: null,
    title: null,
  };

  const onOpen = () => {
    onOpenState();
    document.body.style.overflow = 'hidden';
    setShouldHideKeyboard(true);
  };

  const onClose = () => {
    onCloseState();
    document.body.style.overflow = '';
    setShouldHideKeyboard(false);
  };

  const value = useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
      data,
    }),
    [isOpen, data],
  );

  return <CatalogueSearchContext.Provider value={value}>{children}</CatalogueSearchContext.Provider>;
};

export default CatalogueSearchProvider;
