const isNullish = (param: string | number | Array<unknown> | object | null | undefined) => {
  if (!param) {
    return true;
  }

  if (Array.isArray(param)) {
    return param.length === 0;
  }

  if (typeof param === 'object') {
    return Object.keys(param).length === 0;
  }

  return false;
};

export default isNullish;
