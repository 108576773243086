import dayjs from 'dayjs';
import React from 'react';
import { Controller } from 'react-hook-form';

import Alert from '@/core/components/Alert';
import Button from '@/core/components/Button';
import FormContainer from '@/core/components/FormContainer';
import Header from '@/core/components/Header';
import HintMessage from '@/core/components/HintMessage';
import PasswordInput from '@/core/components/inputs/PasswordInput';
import { NewTextInput } from '@/core/components/inputs/TextInput';
import Label from '@/core/components/Label';
import useForm from '@/core/hooks/useForm';
import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { focusInputs, transformValuesToFocusMapping } from '@/core/lib/form';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import { AuthRoutes, useAuthRoutesContext } from '@/core/pages/auth/Authentification';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';
import type { UsersAuthLoginForm, UsersAuthResult } from '@/core/types/auth';
import { usersAuthLoginFormSchema } from '@/core/types/auth';

const Login: PageWithLayoutsAndProviders = () => {
  const { DONNONS_API_URL } = useEnvContext();
  const { login } = useAuthContext();
  const { back, onClose, go } = useAuthRoutesContext();
  const { t } = useTranslationContext(['common', 'auth.login']);
  const { send } = useAnalyticsContext();

  const apiDefinition = { method: ApiMethods.POST, url: `${DONNONS_API_URL}${ApiVersions.V1}/signin` };
  const { control, onSubmit, errors, onCleanGlobalError, isLoading, isValid, setFocus, getValues } = useForm<UsersAuthResult, UsersAuthLoginForm>({
    schema: usersAuthLoginFormSchema,
    apiDefinition,
    onSuccess: async data => {
      await login({
        accessToken: data.access_token,
        accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
        refreshToken: data.refresh_token ?? null,
        refreshExpiresAt: data.refresh_expires_in ? dayjs().add(data.refresh_expires_in, 'second') : null,
      });
      await send({ event: AnalyticsEventType.LOGIN, origin: 'email' });
      onClose({ success: true });
    },
  });

  useKeyDownHandler([], 'Enter', () => {
    const { activeElement } = document;
    if (activeElement) {
      const values = getValues() satisfies UsersAuthLoginForm;
      const inputsFocusMapping = transformValuesToFocusMapping<UsersAuthLoginForm>(values);
      const inputToFocus = focusInputs<UsersAuthLoginForm>(activeElement as HTMLInputElement, inputsFocusMapping);
      if (isValid && activeElement.id === 'password') {
        onSubmit();
        return;
      }
      if (inputToFocus) {
        setFocus(inputToFocus);
      }
    }
  });

  return (
    <div className="flex h-full flex-col gap-4 md:gap-7">
      <Header content={t('nav-content', { ns: 'auth.login' })} iconBefore="arrow-left" onBefore={back} />
      <div className="inline-flex h-full flex-col pt-3">
        {errors.global.isError && errors.global.message && (
          <Alert status="error" onClose={onCleanGlobalError}>
            {t(errors.global.message, { ns: 'common' })}
          </Alert>
        )}
        {errors.password.isError && errors.password.code?.includes('invalid_password') && errors.password.message && (
          <Alert status="error" onClose={onCleanGlobalError}>
            {t(errors.password.message, { ns: 'common' })}
          </Alert>
        )}
        <FormContainer>
          <Controller
            name="email"
            control={control}
            render={({ field: { ref, onChange, onBlur, value } }) => {
              const { isError, message, isSuccess } = errors.email;
              return (
                <Label htmlFor="email" content={t('form.inputs.email.label', { ns: 'auth.login' })}>
                  <NewTextInput
                    id="email"
                    type="email"
                    value={value ?? ''}
                    placeholder={t('form.inputs.email.placeholder', { ns: 'auth.login' })}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoComplete="email"
                    inputMode="email"
                    enterKeyHint={isValid ? 'done' : 'next'}
                    validation={{ isError, isSuccess }}
                    ref={ref}
                  />
                  <HintMessage isError={isError} isSuccess={isSuccess} message={message} />
                </Label>
              );
            }}
          />

          <Controller
            name="password"
            control={control}
            render={({ field: { ref, onChange, onBlur } }) => {
              return (
                <Label htmlFor="password" content={t('form.inputs.password.label', { ns: 'auth.login' })}>
                  <PasswordInput
                    id="password"
                    placeholder={t('form.inputs.password.placeholder', { ns: 'auth.login' })}
                    onChange={onChange}
                    onBlur={onBlur}
                    autocomplete="current-password"
                    enterKeyHint={isValid ? 'done' : 'next'}
                    ref={ref}
                  />
                  <button type="button" className="text-body-secondary !p-0 text-start text-content-secondary" onClick={() => go(AuthRoutes.FORGOT)}>
                    {t('form.inputs.password.forgot-password', { ns: 'auth.login' })}
                  </button>
                </Label>
              );
            }}
          />
        </FormContainer>

        <div className="mt-auto flex w-full justify-end">
          <div className="max-w-max">
            <Button type="submit" onClick={onSubmit} isLoading={isLoading}>
              {t('form.submit-button', { ns: 'auth.login' })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
