import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const HelpCircle: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M15.1499 14.9999C15.5417 13.886 16.3151 12.9467 17.3332 12.3485C18.3512 11.7502 19.5481 11.5315 20.7119 11.7311C21.8757 11.9307 22.9313 12.5358 23.6917 13.4391C24.4521 14.3425 24.8683 15.4858 24.8666 16.6666C24.8666 19.9999 19.8666 21.6666 19.8666 21.6666M19.9999 28.3333H20.0166M36.6666 19.9999C36.6666 29.2047 29.2047 36.6666 19.9999 36.6666C10.7952 36.6666 3.33325 29.2047 3.33325 19.9999C3.33325 10.7952 10.7952 3.33325 19.9999 3.33325C29.2047 3.33325 36.6666 10.7952 36.6666 19.9999Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpCircle;
