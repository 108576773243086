import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const ChevronsRight: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M21.6667 28.3333L30.0001 20L21.6667 11.6666" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 28.3333L18.3333 20L10 11.6666" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronsRight;
