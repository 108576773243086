import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import type { CatalogueSearchSchema } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import type { ModalValues } from '@/core/lib/new-architecture/domain/circular.domain';
import CircularDomain from '@/core/lib/new-architecture/domain/circular.domain';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';
import { usePersistContext } from '@/core/lib/persist/persist.context';

const useCircular = (search: CatalogueSearchSchema): StoreData<ModalValues> => {
  const { get, set } = usePersistContext();

  const [circular] = useState(new CircularDomain());
  const [modalValues, setModalValues] = useState<ModalValues | null>(null);

  const getModalValues = useCallback(async (data: CatalogueSearchSchema): Promise<ModalValues | null> => {
    const modal = circular.modal(data);

    if (modal) {
      const persistKey = `circular-last-shown-${modal.id}`;
      const persisted = await get(persistKey);

      if (persisted) {
        const lastShownDate = dayjs(persisted);

        // we check if today is more than 15 days after the last shown date
        // if it is we can show it again
        if (dayjs().isAfter(lastShownDate.add(15, 'days'))) {
          const values = modal.getValues();
          setModalValues(values);
          await set(persistKey, dayjs().toString());
          return values;
        }
        // we don't show the modal if it is less than 15 days that the modal
        // has been shown
        return null;
      }
      // the modal has never been shown
      const values = modal.getValues();
      setModalValues(values);
      await set(persistKey, dayjs().toString());
      return values;
    }

    return null;
  }, []);

  useEffect(() => {
    getModalValues(search);
  }, [search]);

  return {
    data: modalValues,
    isLoading: false,
    notFound: false,
  };
};

export default { useCircular };
