import { LOC_SIZE } from '@/core/lib/constants';
import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import api from '@/core/lib/fetch/api';
import type { GeoDetailResult, GeoResult, ReverseGeoResult } from '@/core/types/geo';
import { geoDetailResultSchema, geoResultSchema, reverseGeoResultSchema } from '@/core/types/geo';

const url = valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_GEO_API_URL;

interface LargeParams {
  search: string;
  size?: number;
}

const query = {
  large: async ({ search, size }: LargeParams) =>
    api.get<GeoResult>({
      url: `${url}/loc/large?search=${search}&size=${size ?? LOC_SIZE}&nothing=nothing`,
      schema: geoResultSchema,
    }),
  limited: async ({ search, size }: LargeParams) =>
    api.get<GeoResult>({
      url: `${url}/loc/limited?search=${search}&size=${size ?? LOC_SIZE}&nothing=nothing`,
      schema: geoResultSchema,
    }),
  detail: async (search: string) =>
    api.get<GeoDetailResult>({
      url: `${url}/loc/detail?search=${search}`,
      schema: geoDetailResultSchema,
    }),
};

interface ReverseParams {
  lat: number;
  lon: number;
}

const mutation = {
  reverse: async ({ lat, lon }: ReverseParams) =>
    api.get<ReverseGeoResult>({
      url: `${url}/loc/reverse?lat=${lat}&lon=${lon}`,
      schema: reverseGeoResultSchema,
    }),
};

export default { query, mutation };
