import React from 'react';

import { useTranslationContext } from '@/core/lib/translation/translation.context';

export type HintMessageProps = {
  message?: string | null;
  inputLength?: number;
  max?: number;
  hasCharCounter?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
};

const HintMessage: React.FC<HintMessageProps> = ({ message, inputLength, max, isError, isSuccess, hasCharCounter }) => {
  const { t } = useTranslationContext(['common']);

  if (!isError) {
    return (
      <div className="flex w-full text-content-placeholder">
        {message && <span className="text-caption-primary font-medium">{t(message, { ns: 'common' })}</span>}
        {hasCharCounter && <span className="text-caption-primary ml-auto font-medium">{`${inputLength} / ${max}`}</span>}
      </div>
    );
  }
  if (isSuccess) {
    return (
      <div className="flex text-content-secondary">
        {message && <span className="text-caption-primary inline-block font-medium">{t(message, { ns: 'common' })}</span>}
        {hasCharCounter && <span className="text-caption-primary ml-auto mt-2 inline-block font-medium">{`${inputLength} / ${max}`}</span>}
      </div>
    );
  }
  return (
    <div className="flex text-content-danger">
      {message && <span className="text-caption-primary inline-block font-medium">{t(message, { ns: 'common' })}</span>}
      {hasCharCounter && <span className="text-caption-primary ml-auto mt-2 inline-block font-medium">{`${inputLength} / ${max}`}</span>}
    </div>
  );
};

export default HintMessage;
