import * as yup from 'yup';

import { hasNotUrl } from '@/core/lib/form/validators';
import { NOT_URL_REGEX, PASSWORD_REGEX, USERNAME_AT_LEAST_2_REGEX, USERNAME_REGEX } from '@/core/lib/regex';
import { adminResultSchema, cityResultSchema, countryResultSchema, locationFormSchema, subResultSchema } from '@/core/types/geo';

export const reportsReportProfileFormSchema = yup.object({
  at_least_one_checked: yup.string().test('at_least_one_checked', 'errors.inputs.at_least_one_checked', function oneChecked() {
    return this.parent.user_avatar || this.parent.user_name || this.parent.user_message || this.parent.user_other;
  }),
  user_avatar: yup.boolean(),
  user_name: yup.boolean(),
  user_message: yup.boolean(),
  user_other: yup.boolean(),
  comment: yup.string().when('user_other', {
    is: true,
    then: schema => schema.required('errors.inputs.details').max(400, 'errors.inputs.max-400'),
  }),
});

export type ReportsReportProfileForm = yup.InferType<typeof reportsReportProfileFormSchema>;

export type ReportsReportProfileFormResult = null;

export const usersPrivateProfilResultSchema = yup.object({
  uuid: yup.string().required(),
  state: yup.string().required(),
  username: yup.string().required(),
  avatar_url: yup.string().required(),
  created_at: yup.string().required(),
  description: yup.string().nullable(),
  donation_donated: yup.number().required(),
  donation_received: yup.number().required(),
  co2_given: yup.number().required(),
  co2_received: yup.number().required(),
  email: yup.string().required(),
  notifiable: yup.boolean().required(),
  last_visit: yup.string().required(),
  cgu: yup.string().required(),
  updated_at: yup.string().required(),
  newsletter: yup.boolean().required(),
  push: yup.boolean().required(),
  msg_email: yup.boolean().required(),
  msg_push: yup.boolean().required(),
  signature: yup.string().nullable(),
  has_password: yup.boolean().required(),
  google: yup.string().nullable(),
  facebook: yup.string().nullable(),
  apple: yup.string().nullable(),
  new_messages: yup.number().required(),
  is_nice: yup.boolean().required(),
  is_punctual: yup.boolean().required(),
  // loc
  city: yup.number().required(),
  sub: yup.number().nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
});

export type UsersPrivateProfilResult = yup.InferType<typeof usersPrivateProfilResultSchema>;

export const usersPublicProfilResultSchema = yup.object({
  uuid: yup.string().required(),
  state: yup.string().required(),
  username: yup.string().required(),
  avatar_url: yup.string().required(),
  created_at: yup.string().required(),
  description: yup.string().nullable(),
  donation_donated: yup.number().required(),
  donation_received: yup.number().required(),
  co2_given: yup.number().required(),
  co2_received: yup.number().required(),
  is_nice: yup.boolean().required(),
  is_punctual: yup.boolean().required(),
  // loc
  city: yup.number().required(),
  sub: yup.number().nullable(),
  countries: yup.array(countryResultSchema.required()).nullable(),
  admins: yup.array(adminResultSchema.required()).nullable(),
  cities: yup.array(cityResultSchema.required()).nullable(),
  subs: yup.array(subResultSchema.required()).nullable(),
});

export type UsersPublicProfilResult = yup.InferType<typeof usersPublicProfilResultSchema>;

export const usersChangeAvatarFormSchema = yup.object({
  avatar: yup.string().required('errors.inputs.required'),
});

export type UsersChangeAvatarForm = yup.InferType<typeof usersChangeAvatarFormSchema>;

export const usersChangeCityFormSchema = yup.object({
  city: yup.number().required('errors.inputs.required'),
});

export type UsersChangeCityForm = yup.InferType<typeof usersChangeCityFormSchema>;

export const usersChangeDescriptionFormSchema = yup.object({
  description: yup.string().required('errors.inputs.required'),
});

export type UsersChangeDescriptionForm = yup.InferType<typeof usersChangeDescriptionFormSchema>;

export const usersChangeEmailFormSchema = yup.object({
  email: yup.string().email('errors.inputs.email').required('errors.inputs.required'),
});

export type UsersChangeEmailForm = yup.InferType<typeof usersChangeEmailFormSchema>;

export const usersChangePasswordFormSchema = yup.object({
  password: yup.string().matches(PASSWORD_REGEX, 'errors.inputs.password').required('errors.inputs.required'),
});

export type UsersChangePasswordForm = yup.InferType<typeof usersChangePasswordFormSchema>;

export type UsersChangePasswordResult = null;

export const usersChangeSignatureFormSchema = yup.object({
  signature: yup.string().test('has_not_url', 'errors.inputs.has_not_url_sentence', hasNotUrl).min(10).max(400).required('errors.inputs.required'),
});

export type UsersChangeSignatureForm = yup.InferType<typeof usersChangeSignatureFormSchema>;

export const usersChangeUsernameFormSchema = yup.object({
  username: yup
    .string()
    .matches(USERNAME_AT_LEAST_2_REGEX, 'errors.inputs.username')
    .matches(USERNAME_REGEX, 'errors.inputs.username')
    .matches(NOT_URL_REGEX, 'errors.inputs.has_not_url')
    .required('errors.inputs.required'),
});

export type UsersChangeUsernameForm = yup.InferType<typeof usersChangeUsernameFormSchema>;

export const usersUploadAvatarSchema = yup.object({
  avatar: yup.string().optional(),
});

export type UsersUploadAvatar = yup.InferType<typeof usersUploadAvatarSchema>;

export const usersEditFormSchema = yup.object({
  avatar: yup.string().required('errors.inputs.required'),
  username: yup
    .string()
    .matches(USERNAME_AT_LEAST_2_REGEX, 'errors.inputs.username')
    .matches(USERNAME_REGEX, 'errors.inputs.username')
    .matches(NOT_URL_REGEX, 'errors.inputs.has_not_url')
    .required('errors.inputs.required'),
  location: locationFormSchema.required('errors.inputs.required'),
});

export type UsersEditForm = yup.InferType<typeof usersEditFormSchema>;

export type UsersEditResult = null;
export const usersDeleteAccountSchema = yup.object({
  at_least_one_checked: yup.string().test('at_least_one_checked', 'errors.inputs.at_least_one_checked', function oneChecked() {
    return this.parent.needs_changed || this.parent.similar_app || this.parent.not_easy || this.parent.other;
  }),
  needs_changed: yup.boolean(),
  similar_app: yup.boolean(),
  not_easy: yup.boolean(),
  other: yup.boolean(),
  details: yup.string().when('other', {
    is: true,
    then: schema => schema.required('errors.inputs.details').max(400, 'errors.inputs.max-400'),
  }),
});

export type UsersDeleteAccountForm = yup.InferType<typeof usersDeleteAccountSchema>;

export type UsersDeleteAccountResult = null;
