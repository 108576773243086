import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Add: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M12 6V18M18 12L6 12" strokeLinecap="round" />
  </svg>
);

export default Add;
