import * as yup from 'yup';

import { QuerySortEnum } from '@/core/types/donation';

export const savedSearchResult = yup.object({
  hash: yup.string().required(),
  label: yup.string().required(),
  email: yup.boolean().required(),
  push: yup.boolean().required(),
  params: yup.object({
    cat: yup.array(yup.number().required()).optional(),
    giver: yup.string().optional(),
    loc: yup.string().optional(),
    published_at: yup.number().optional(),
    sort: yup.mixed<QuerySortEnum>().oneOf(Object.values(QuerySortEnum)),
    title: yup.string().optional(),
    distance: yup.number().optional(),
  }),
  created_at: yup.string().required(),
  unread: yup.number().required(),
});

export type SavedSearchResult = yup.InferType<typeof savedSearchResult>;

export const savedSearchesResult = yup.object({
  searches: yup.array(savedSearchResult.required()).required(),
});

export type SavedSearchesResult = yup.InferType<typeof savedSearchesResult>;

export const savedSearchForm = yup.object({
  title: yup.string().optional(),
  cat: yup.array(yup.number().required()).optional(),
  loc: yup.string().optional(),
  distance: yup.number().optional(),
  lat: yup.number().optional(),
  lon: yup.number().optional(),
  published_at: yup.string().optional(),
  giver: yup.string().optional(),
  from: yup.number().optional(),
  size: yup.number().optional(),
  sort: yup.mixed<QuerySortEnum>().oneOf(Object.values(QuerySortEnum)),
  label: yup.string().required(),
  email: yup.boolean().required(),
  push: yup.boolean().required(),
});

export type SavedSearchForm = yup.InferType<typeof savedSearchForm>;
