import React, { useCallback, useMemo } from 'react';

import type { PersistContextType } from '@/core/lib/persist/persist.context';
import { PersistContext } from '@/core/lib/persist/persist.context';

const PersistProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const get = useCallback(async (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  }, []);

  const set = useCallback(async (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch {
      // do nothing
    }
  }, []);

  const remove = useCallback(async (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch {
      // do nothing
    }
  }, []);

  const value = useMemo(() => ({ get, set, remove }) satisfies PersistContextType, []);

  return <PersistContext.Provider value={value}>{children}</PersistContext.Provider>;
};

export default PersistProvider;
