import * as yup from 'yup';

import { userResultSchema } from '@/core/types/users';

export const usersContactFormSchema = yup.object({
  type: yup.number().oneOf([1, 2]).required(), // follow=1, block=2
  note: yup.string().optional(),
});

export type UsersContactForm = yup.InferType<typeof usersContactFormSchema>;

export const usersContactResultSchema = yup.object({
  user: yup.string().required(),
  for_user: yup.string().required(),
  type: yup.number().required(),
  note: yup.string().nullable(),
  created_at: yup.string().required(),
  updated_at: yup.string().required(),
});

export type UsersContactResult = yup.InferType<typeof usersContactResultSchema>;

export const usersContactsResultSchema = yup.object({
  total: yup.number().required(),
  contacts: yup.array(usersContactResultSchema).required(),
  users: yup.array(userResultSchema).required(),
});

export type UsersContactsResult = yup.InferType<typeof usersContactsResultSchema>;
