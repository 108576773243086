import React from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import Button from '@/core/components/Button';
import HintMessage from '@/core/components/HintMessage';
import CodeInput from '@/core/components/inputs/CodeInput';
import Label from '@/core/components/Label';
import useForm from '@/core/hooks/useForm';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import { OTP_REGEX } from '@/core/lib/regex';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { UsersHasControlResult } from '@/core/types/control';

const schema = yup.object({
  otp: yup.string().matches(OTP_REGEX, 'control.email-check.6-digits').required('control.email-check.required'),
});

type Schema = yup.InferType<typeof schema>;

const EmailCheckControl: React.FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslationContext(['common']);
  const { DONNONS_API_URL } = useEnvContext();

  const { data: current } = Store.current.useCurrent();
  const { invalidateControl } = Query.user.useInvalidate();

  const { control, onSubmit, errors } = useForm<UsersHasControlResult, Schema>({
    schema,
    apiDefinition: { method: ApiMethods.PUT, url: `${DONNONS_API_URL}${ApiVersions.V1}/user/control/email_check` },
    onSuccess: async () => {
      await invalidateControl();
    },
  });

  if (!current) {
    return null;
  }

  const { email } = current!.getInfo();

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full grow flex-col gap-6 text-center">
        <div className="mx-auto flex size-30 items-center justify-center">
          <img loading="lazy" decoding="async" src="/assets/control/email_check.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-contain" />
        </div>
        <p className="text-body-primary text-center font-semibold text-content-secondary">{t('control.email-check.title', { ns: 'common' })}</p>
        <p className="text-body-secondary font-normal text-content-primary">
          {t('control.email-check.subtitle', { ns: 'common' })} <strong>{email}</strong>
        </p>
        <Controller
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => {
            const { isError, message, isSuccess } = errors.otp;
            return (
              <Label htmlFor="otp" content={t('control.email-check.label', { ns: 'common' })}>
                <CodeInput id="otp" onChange={onChange} value={value} />
                <HintMessage isError={isError} isSuccess={isSuccess} message={message} />

                <p className="text-caption-primary mt-3 text-center font-normal text-content-primary">{t('control.email-check.received', { ns: 'common' })}</p>
              </Label>
            );
          }}
          name="otp"
        />
      </div>
      <div className="flex gap-4">
        <Button variant="secondary" onClick={logout}>
          {t('control.email-check.cancel', { ns: 'common' })}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t('control.email-check.submit', { ns: 'common' })}
        </Button>
      </div>
    </div>
  );
};

export default EmailCheckControl;
