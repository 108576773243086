import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import { type UsersAuthResult, usersAuthResultSchema, type UsersAuthTokenForm } from '@/core/types/auth';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const mutation = {
  renew: async (token: string) =>
    api.get<UsersAuthResult>({
      url: `${getUrl()}${ApiVersions.V1}/token/renew`,
      schema: usersAuthResultSchema,
      token,
    }),
  refresh: async (data: UsersAuthTokenForm) =>
    api.post<UsersAuthResult, UsersAuthTokenForm>({
      url: `${getUrl()}${ApiVersions.V1}/token/refresh`,
      schema: usersAuthResultSchema,
      data,
    }),
};

export default { mutation };
