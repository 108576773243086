import React, { useCallback, useMemo } from 'react';

import { useEnvContext } from '@/core/lib/env/env.context';
import { FFContext } from '@/core/lib/ff/ff.context';

const FFProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { env } = useEnvContext();

  const ff = useCallback(() => env !== 'production', []);

  const value = useMemo(
    () => ({
      ff,
    }),
    [],
  );

  return <FFContext.Provider value={value}>{children}</FFContext.Provider>;
};

export default FFProvider;
