import React from 'react';

import Button from '@/core/components/Button';
import useMutation from '@/core/hooks/useMutation';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import Query from '@/core/lib/new-architecture/query';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { UsersHasControlResult } from '@/core/types/control';

const DeleteControl: React.FC = () => {
  const { logout } = useAuthContext();
  const { t } = useTranslationContext(['common']);
  const { DONNONS_API_URL } = useEnvContext();
  const { invalidateControl } = Query.user.useInvalidate();

  const mutation = useMutation<UsersHasControlResult, void>({
    apiDefinition: { method: ApiMethods.PUT, url: `${DONNONS_API_URL}${ApiVersions.V1}/user/control/delete` },
    onSuccess: async () => {
      await invalidateControl();
    },
  });

  const onSubmit = () => mutation.mutate();

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex w-full grow flex-col gap-6 text-center">
        <div className="mx-auto flex size-30 items-center justify-center">
          <img loading="lazy" decoding="async" src="/assets/control/delete_account.svg" alt={t('control.rs.title', { ns: 'common' })} className="object-contain" />
        </div>
        <p className="text-body-primary text-center font-semibold text-content-secondary">{t('control.delete.title', { ns: 'common' })}</p>
        <p className="text-body-secondary font-normal text-content-primary">{t('control.delete.subtitle', { ns: 'common' })}</p>
      </div>
      <div className="w-full space-y-4">
        <Button variant="primary" onClick={onSubmit}>
          {t('control.delete.submit', { ns: 'common' })}
        </Button>
        <Button variant="secondary" onClick={logout}>
          {t('control.delete.cancel', { ns: 'common' })}
        </Button>
      </div>
    </div>
  );
};

export default DeleteControl;
