import { createContext, useContext } from 'react';

export interface ShareUrlParams {
  title: string;
  text: string;
  url: string;
  dialogTitle: string;
}

export interface ShareContextType {
  canShare: () => Promise<boolean>;
  shareUrl: (params: ShareUrlParams) => Promise<string | undefined>;
}

export const ShareContext = createContext<ShareContextType | null>(null);

export const useShareContext = () => useContext(ShareContext) as ShareContextType;
