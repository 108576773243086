export const OTP_REGEX = /^\d{6}$/;

export const USERNAME_AT_LEAST_2_REGEX = /[0-9a-zàâäéèêëîïôöùûüÿç]{2}/i;

export const USERNAME_REGEX = /^[0-9a-zàâäéèêëîïôöùûüÿç _-]{2,30}$/i;

export const PASSWORD_REGEX = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,64}/;

export const AVATAR_REGEX = /avatar\/\{\{size\}\}\/(.+)\.\{\{ext\}\}/;

export const PHOTO_REGEX = /photo\/\{\{size\}\}\/(.+)\.\{\{ext\}\}/;

export const SEMVER_REGEX = /^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+)$/;

export const NOT_URL_REGEX = /^(?!https?:\/\/[^\s/$.?#].[^\s]*).*$/;

export const URL_REGEX = /https?:\/\/|ftp:\/\/|www\./;

export const KEY_WORD_REGEX = /[*\][()?+|{}/$\\]/g;

export const DEPARTMENT_ID_REGEX = /FR-[0-9][0-9]/;
