import React from 'react';

import type { IconName } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';

interface NotificationButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  isLoading?: boolean;
  iconName: IconName;
  ariaLabel: string;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({ onClick, isLoading, isSelected, isDisabled, ariaLabel, iconName }) => {
  const onLocalClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    if (!isLoading && onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type="button"
      className={`size-10 rounded-2 border border-solid p-2 focus-visible:outline-none ${isDisabled ? 'cursor-not-allowed !border-bg-button-primary-disable !bg-bg-button-primary-disable' : ''} ${isSelected ? 'border-stroke-button-primary-default bg-bg-button-primary-default hover:bg-bg-button-primary-pressed' : 'border-content-button-secondary-disable bg-bg-button-secondary hover:bg-bg-button-secondary-hover'}`}
      onClick={onLocalClick}
      aria-label={ariaLabel}
      disabled={isDisabled}
    >
      <Icons icon={isLoading ? 'spinner' : iconName} size="20" color={isDisabled || isSelected ? 'button-primary' : 'disabled'} />
    </button>
  );
};

export default NotificationButton;
