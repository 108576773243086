import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Flag: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M6.66669 24.9999C6.66669 24.9999 8.33335 23.3333 13.3334 23.3333C18.3334 23.3333 21.6667 26.6666 26.6667 26.6666C31.6667 26.6666 33.3334 24.9999 33.3334 24.9999V4.99992C33.3334 4.99992 31.6667 6.66659 26.6667 6.66659C21.6667 6.66659 18.3334 3.33325 13.3334 3.33325C8.33335 3.33325 6.66669 4.99992 6.66669 4.99992V24.9999ZM6.66669 24.9999V36.6665"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Flag;
