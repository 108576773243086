import type React from 'react';
import { useEffect } from 'react';

import useEventListener from '@/core/hooks/useEventListener';
import type { ContentProps } from '@/core/lib/components/components.context';
import { useScrollContext } from '@/core/lib/scroll/scroll.context';

const Content: React.FC<ContentProps> = ({ scroll, children }) => {
  const { set, get } = useScrollContext();

  const toRestore = scroll ? get(scroll) : 0;

  const onScrollEnd = () => {
    if (scroll) {
      // @ts-expect-error ionic do not type right
      set(scroll, window.document.scrollingElement.scrollTop);
    }
  };

  // @ts-expect-error ionic do not type right
  useEventListener('scrollend', onScrollEnd, typeof window !== 'undefined' ? window : null);

  useEffect(() => {
    if (scroll && toRestore) {
      // @ts-expect-error ionic do not type right
      window.document.scrollingElement.scrollTo({ top: toRestore });
    }
  }, [toRestore]);

  return children;
};

export default Content;
