import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Camera: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="camera" clipPath="url(#clip0_1307_3193)">
      <path
        id="Vector"
        d="M15.8332 12.6667C15.8332 13.0203 15.6927 13.3594 15.4426 13.6095C15.1926 13.8595 14.8535 14 14.4998 14H2.49984C2.14622 14 1.80708 13.8595 1.55703 13.6095C1.30698 13.3594 1.1665 13.0203 1.1665 12.6667V5.33333C1.1665 4.97971 1.30698 4.64057 1.55703 4.39052C1.80708 4.14048 2.14622 4 2.49984 4H5.1665L6.49984 2H10.4998L11.8332 4H14.4998C14.8535 4 15.1926 4.14048 15.4426 4.39052C15.6927 4.64057 15.8332 4.97971 15.8332 5.33333V12.6667Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.50016 11.3333C9.97292 11.3333 11.1668 10.1394 11.1668 8.66667C11.1668 7.19391 9.97292 6 8.50016 6C7.0274 6 5.8335 7.19391 5.8335 8.66667C5.8335 10.1394 7.0274 11.3333 8.50016 11.3333Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1307_3193">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Camera;
