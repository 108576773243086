import type { GalleryPhoto, Photo } from '@capacitor/camera';

export const resizeAvatar = async (photo: Photo): Promise<Blob> => {
  const TARGET_MIME_TYPE = 'image/jpeg';
  const WIDTH = 256;
  const HEIGHT = 256;

  const src = photo.webPath as string;
  const targetFileType = TARGET_MIME_TYPE;

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const context = canvas.getContext('2d');

      if (!context) {
        reject();
        return;
      }

      const iw = image.width;
      const ih = image.height;
      const scale = Math.max(WIDTH / iw, HEIGHT / ih);
      const sw = iw * scale;
      const sh = ih * scale;
      context.drawImage(image, 0, 0, iw, ih, (WIDTH - sw) / 2, (HEIGHT - sh) / 2, iw * scale, ih * scale);

      canvas.toBlob(resolve as BlobCallback, targetFileType, 0.65);
    };
  });
};

export const resizeDonation = async (photo: Photo | GalleryPhoto): Promise<Blob> => {
  const TARGET_MIME_TYPE = 'image/jpeg';
  const WIDTH = 320;
  const HEIGHT = 240;

  const src = photo.webPath as string;
  const targetFileType = TARGET_MIME_TYPE;

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const context = canvas.getContext('2d');

      if (!context) {
        reject();
        return;
      }

      context.fillStyle = '#FFF';
      context.fillRect(0, 0, canvas.width, canvas.height);

      const iw = image.width;
      const ih = image.height;
      const scale = Math.min(WIDTH / iw, HEIGHT / ih);
      const sw = iw * scale;
      const sh = ih * scale;
      context.drawImage(image, 0, 0, iw, ih, (WIDTH - sw) / 2, (HEIGHT - sh) / 2, iw * scale, ih * scale);

      canvas.toBlob(resolve as BlobCallback, targetFileType, 0.65);
    };
  });
};

export interface ImagePrepareOptions {
  /**
   * File name
   * @default 'file'
   */
  name?: string;

  /**
   * Max File Size
   * @default 20971520
   */
  maxSize?: number;

  /**
   * Image AllowedTypes Format types MIME
   * @default ['image/jpeg', 'image/png']
   */
  allowedTypes?: Array<string>;

  /**
   * Image AllowedTypes Format types MIME
   * @default 'image/jpeg'
   */
  outputType?: string;

  /**
   * Compression quality of the jpeg conversion
   * @default 0.75
   */
  quality?: number;

  /** Max width of the image. If to big, image is resized */
  maxWidth?: number;

  /** Max height of the image. If to big, image is resized */
  maxHeight?: number;

  /** Min width of the image. If to small, throw an error */
  minWidth?: number;

  /** Min height of the image. If to small, throw an error */
  minHeight?: number;

  /**
   * If true, the max/min Width & Height are interchangeables for validation & resize.
   * @default true
   */
  orientationAllowed?: boolean;

  /**
   * If true, the exceeding part will be crop.
   * @default true
   */
  autoCrop?: boolean;
}

/**
 * Check if allowed type
 * @param image
 * @param options
 * @returns boolean
 */
const isFileTypeValid = (file: File, allowedTypes: Array<string>) => {
  return !allowedTypes || allowedTypes.some(allowedType => allowedType === file.type);
};

/**
 * Check if file is too heavy
 * @param image
 * @param options
 * @returns boolean
 */
const isFileSizeValid = (file: File, maxSize: number) => {
  return !maxSize || file.size < maxSize;
};

/**
 * Check if image is big enough
 * @param image
 * @param options
 * @returns boolean
 */
const isImageMinSizeValid = (image: HTMLImageElement, options: ImagePrepareOptions) => {
  return !(options.minWidth && options.minHeight) || (image.width >= options.minWidth && image.height >= options.minHeight);
};

/**
 * Check if image is small enough
 * @param image
 * @param options
 * @returns boolean
 */
const isImageMaxSizeValid = (image: HTMLImageElement, options: ImagePrepareOptions) => {
  return !(options.maxWidth && options.maxHeight) || (image.width <= options.maxWidth && image.height <= options.maxHeight);
};

/**
 * If orientationAllowed, handle big side/small side instead of width/height
 * @param image
 * @param options
 */
const handleOrientationAllowed = (image: HTMLImageElement, options: ImagePrepareOptions): ImagePrepareOptions => {
  if (!options.orientationAllowed) return options;

  const newOptions = { ...options };

  // handle max size. If image is wider than higher we make sur that maxWidth is bigger than maxHeight
  // if not we swap max size
  if (options.maxHeight && options.maxWidth) {
    if (image.width > image.height) {
      if (options.maxHeight > options.maxWidth) {
        [newOptions.maxHeight, newOptions.maxWidth] = [options.maxWidth, options.maxHeight];
      }
    } else if (options.maxHeight < options.maxWidth) {
      [newOptions.maxHeight, newOptions.maxWidth] = [options.maxWidth, options.maxHeight];
    }
  }

  // handle min size. If image is wider than higher we make sur that minWidth is bigger than minHeight
  // if not we swap min size
  if (options.minHeight && options.minWidth) {
    if (image.width > image.height) {
      if (options.minHeight > options.minWidth) {
        [newOptions.minHeight, newOptions.minWidth] = [options.minWidth, options.minHeight];
      }
    } else if (options.minHeight < options.minWidth) {
      [newOptions.minHeight, newOptions.minWidth] = [options.minWidth, options.minHeight];
    }
  }

  return newOptions;
};

/**
 * Determines if webp is supported by the browser
 * @returns a promise resolving to a boolean to know if webp is supported
 */
export const isWebpSupported = (): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    const img = new Image();
    img.onload = () => resolve(img.width === 2 && img.height === 1);
    img.onerror = () => resolve(false);
    img.src = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';
  });
};

/**
 * Determines if webp conversion with canvas is supported by the browser
 * @returns a boolean to know if webp conversion is supported
 */
export const isWebpConversionSupported = (): boolean => {
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  return canvas.toDataURL('image/webp').match('image/webp') !== null;
};

/**
 * Optimize a file
 * Handle validation error
 * @param file
 * @param options
 * @returns a promise resolving to a new file (otherwise a validation error)
 */
const resizeAndConvert = (file: File, options: ImagePrepareOptions): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const image = document.createElement('img');
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      const { width, height } = image;

      const newOptions = handleOrientationAllowed(image, options);

      if (!isImageMinSizeValid(image, newOptions)) {
        reject(new Error('MIN_SIZE'));
      }

      if (!newOptions.autoCrop && !isImageMaxSizeValid(image, newOptions)) {
        reject(new Error('MAX_SIZE'));
      }

      // if no max/min size, set max/min size to width/height
      const { maxWidth = width, maxHeight = height, minWidth = width, minHeight = height } = newOptions;

      // Define ratio to have the biggest image allowed
      const maxWRatio = maxWidth / width;
      const maxHRatio = maxHeight / height;
      let ratios = [maxWRatio, maxHRatio].filter(ratio => ratio < 1);
      let ratio = 1;
      if (ratios.length > 0) {
        ratio = Math.min(...ratios);
      }
      let newWidth = width * ratio;
      let newHeight = height * ratio;

      // Make sure the ratio respect min size if we need to resize down the image
      if (newWidth < minWidth || newHeight < minHeight) {
        const minWRatio = minWidth / width;
        const minHRatio = minHeight / height;
        ratios = [minWRatio, minHRatio].filter(r => r < 1);
        ratio = ratios.length > 0 ? Math.max(...ratios) : 1;
        newWidth = width * ratio;
        newHeight = height * ratio;
      }

      const sourceWidth = newWidth > maxWidth ? maxWidth / ratio : width;
      const sourceHeight = newHeight > maxHeight ? maxHeight / ratio : height;
      let x = 0;
      let y = 0;

      // if needed, offset the exceeding part on the x axis
      if (newWidth > maxWidth) {
        x = (width - sourceWidth) / 2;
        newWidth = maxWidth;
      }

      // if needed, offset the exceeding part on the y axis
      if (newHeight > maxHeight) {
        y = (height - sourceHeight) / 2;
        newHeight = maxHeight;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      context.drawImage(image, x, y, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob: Blob | null): void => {
          if (!blob) {
            reject(new Error('DEFAULT'));
          } else {
            // Rename file with correct extension
            const nameParts = file.name.split('.');
            nameParts.pop();
            nameParts.push(blob.type.replace('image/', ''));
            resolve(blob);
          }
        },
        options.outputType,
        options.quality,
      );
    };

    const urlCreator = window.URL || window.webkitURL;
    image.src = urlCreator.createObjectURL(file);
  });
};

/**
 * Optimize a file
 * Handle validation error
 * @param blob
 * @param options
 * @returns a promise resolving to a new file (otherwise a validation error)
 */
export const resizeDonationV2 = async (blob: Blob, options: ImagePrepareOptions = {}): Promise<Blob> => {
  const isWebp = isWebpConversionSupported() && (await isWebpSupported());
  return new Promise((resolve, reject) => {
    const allowedTypes = isWebp ? ['image/jpeg', 'image/png', 'image/svg', 'image/webp'] : ['image/jpeg', 'image/png', 'image/svg'];

    const imageOptions = {
      name: 'file',
      maxSize: 20971520,
      allowedTypes,
      outputType: 'image/jpeg',
      quality: 0.75,
      orientationAllowed: true,
      autoCrop: true,
      minWidth: 320,
      minHeight: 240,
      maxWidth: 2560,
      maxHeight: 1140,
      ...options,
    };

    const file = new File([blob], imageOptions.name, { type: blob.type });

    if (!isFileTypeValid(file, imageOptions.allowedTypes)) {
      reject(new Error('INVALID_FILETYPE'));
    }

    if (!isFileSizeValid(file, imageOptions.maxSize)) {
      reject(new Error('INVALID_FILE_SIZE'));
    }

    resizeAndConvert(file, imageOptions)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
};
