import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Archive: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" className={className}>
    <g clipPath="url(#clip0_10515_156511)">
      <path d="M17.5002 6.66668V17.5H2.50018V6.66668M0.833496 2.5H19.1668V6H10.0002H0.833496V2.5Z" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.99984 11.3332L10.3332 14.6665M10.3332 14.6665L13.6665 11.3332M10.3332 14.6665L10.3332 8.99984" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10515_156511">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Archive;
