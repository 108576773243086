import auth from '@/core/lib/new-architecture/api/auth.api';
import categories from '@/core/lib/new-architecture/api/categories.api';
import conversation from '@/core/lib/new-architecture/api/conversation.api';
import conversations from '@/core/lib/new-architecture/api/conversations.api';
import documents from '@/core/lib/new-architecture/api/documents.api';
import donation from '@/core/lib/new-architecture/api/donation.api';
import donations from '@/core/lib/new-architecture/api/donations.api';
import followedDonations from '@/core/lib/new-architecture/api/followedDonations.api';
import home from '@/core/lib/new-architecture/api/home.api';
import locs from '@/core/lib/new-architecture/api/locs.api';
import push from '@/core/lib/new-architecture/api/push.api';
import report from '@/core/lib/new-architecture/api/report.api';
import savedSearch from '@/core/lib/new-architecture/api/savedSearch';
import user from '@/core/lib/new-architecture/api/user.api';
import userDonation from '@/core/lib/new-architecture/api/user.donation.api';
import userDonations from '@/core/lib/new-architecture/api/user.donations.api';

const Api = {
  query: {
    donation: donation.query,
    donations: donations.query,
    followedDonations: followedDonations.query,
    categories: categories.query,
    user: {
      ...user.query,
      donation: userDonation.query,
      donations: userDonations.query,
    },
    locs: locs.query,
    documents: documents.query,
    conversations: conversations.query,
    conversation: conversation.query,
    savedSearch: savedSearch.query,
    home: home.query,
  },
  mutation: {
    donation: donation.mutation,
    auth: auth.mutation,
    push: push.mutation,
    user: user.mutation,
    savedSearch: savedSearch.mutation,
    locs: locs.mutation,
    conversation: conversation.mutation,
    conversations: conversations.mutation,
    report: report.mutation,
  },
};

export default Api;
