import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Sliders: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className={className}>
    <g clipPath="url(#clip0_10_10160)">
      <path d="M6.66669 35.0002V23.3335" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66669 16.6667V5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 35V20" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 13.3333V5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.3333 34.9998V26.6665" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.3333 20V5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.66669 23.3335H11.6667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 13.3335H25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.3333 26.6665H38.3333" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10_10160">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Sliders;
