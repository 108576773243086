import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Type: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M6.66669 11.6665V6.6665H33.3334V11.6665" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 33.3335H25" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 6.6665V33.3332" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Type;
