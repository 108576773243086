import { useMemo } from 'react';

import HomeDomain from '@/core/lib/new-architecture/domain/home.domain';
import Query from '@/core/lib/new-architecture/query';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const useHome = (): StoreData<HomeDomain> => {
  const { data, isLoading, error } = Query.home.useHome({});

  const homeData = useMemo(() => {
    if (!data) {
      return null;
    }

    return new HomeDomain({ data });
  }, [data]);

  return {
    data: homeData,
    isLoading,
    notFound: false,
    errors: error,
  };
};

export default { useHome };
