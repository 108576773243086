import { useMemo } from 'react';

import ConversationsDomain from '@/core/lib/new-architecture/domain/conversations.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';
import Routes from '@/core/lib/router/routes';

const useConversations = (): StoreData<ConversationsDomain> => {
  const { requestToken } = Store.auth.useRequest();

  const { data: current, isLoading: currentIsLoading } = Store.current.useCurrent();

  const { search } = Routes.ConversationsRoute.useParams();

  const { data: conversations, isLoading: conversationsIsLoading } = Query.conversations.useConversations({
    data: search,
    token: requestToken,
    enabled: !!current,
  });

  const isLoading = conversationsIsLoading || currentIsLoading;

  const data = useMemo(() => {
    if (!conversations || !current) {
      return null;
    }

    return new ConversationsDomain({ data: conversations, current });
  }, [conversations, current]);

  return {
    data,
    isLoading,
    notFound: false,
  };
};

export default { useConversations };
