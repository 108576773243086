import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Check: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M33.3334 10L15 28.3333L6.66669 20" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Check;
