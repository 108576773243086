import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Unarchive: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" className={className}>
    <g clipPath="url(#clip0_10515_156512)">
      <path d="M17.5002 6.66668V17.5H2.50018V6.66668M0.833496 2.5H19.1668V6H0.833496V2.5Z" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.6667 12.3333L10.3333 9M10.3333 9L7 12.3333M10.3333 9L10.3333 14.6667" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_10515_156512">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Unarchive;
