import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const LogOut: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M25 5H31.6667C32.5507 5 33.3986 5.35119 34.0237 5.97631C34.6488 6.60143 35 7.44928 35 8.33333V31.6667C35 32.5507 34.6488 33.3986 34.0237 34.0237C33.3986 34.6488 32.5507 35 31.6667 35H25M16.6667 28.3333L25 20M25 20L16.6667 11.6666M25 20L5 20"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogOut;
