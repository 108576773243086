import { useMemo } from 'react';

import DonationDomain, { DonationTakersDomain } from '@/core/lib/new-architecture/domain/donation.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const useDonation = (id: number | undefined): StoreData<DonationDomain> => {
  const { requestToken } = Store.auth.useRequest();

  const { data: current, isLoading: userIsLoading } = Store.current.useCurrent();
  const { data: categories, isLoading: categoriesIsLoading } = Store.categories.useCategories();
  const { data: favorites, isLoading: favoritesIsLoading } = Store.favorites.useFavorites();

  const {
    data: publicDonation,
    isLoading: isPublicLoading,
    isSuccess: isPublicSuccess,
    isError: isPublicError,
  } = Query.donation.useDonation({ data: { id, isLoggedIn: !!current }, token: requestToken });

  const privateDonationEnabled = !!(current && current.uuid === publicDonation?.donation.giver);
  const {
    data: privateDonation,
    isLoading: isPrivateLoading,
    isSuccess: isPrivateSuccess,
    isError: isPrivateError,
  } = Query.user.donation.useDonation({
    data: { id },
    enabled: privateDonationEnabled,
    token: requestToken,
  });

  const queriesAreNotErrorAndNotSuccess = privateDonationEnabled ? !isPrivateSuccess && !isPrivateError : !isPublicSuccess && !isPublicError;

  const queriesAreLoading = categoriesIsLoading || userIsLoading || (current ? isPrivateLoading : isPublicLoading);

  const isLoading = queriesAreNotErrorAndNotSuccess || queriesAreLoading || favoritesIsLoading;

  const don = privateDonation || publicDonation;

  const data = useMemo(() => {
    if (!don || !categories) {
      return null;
    }

    return new DonationDomain({ data: don, categories, current, followedDonationsIds: favorites?.getFollowedDonationsIds() || [] });
  }, [don, categories, current, favorites]);

  const notFound = useMemo(() => !don && !isLoading, [don, isLoading]);

  return {
    data,
    isLoading,
    notFound,
  };
};

const useTakers = (id: number): StoreData<DonationTakersDomain> => {
  const { requestToken } = Store.auth.useRequest();
  const { data: current, isLoading: userIsLoading } = Store.current.useCurrent();

  const { data: takers, isLoading: takersIsLoading, isSuccess: takersIsSuccess, isError: takersIsError } = Query.donation.useTakers({ data: { id }, token: requestToken, enabled: !!current });

  const queriesAreNotErrorAndNotSuccess = !takersIsSuccess && !takersIsError;
  const queriesAreLoading = userIsLoading || takersIsLoading;
  const isLoading = queriesAreNotErrorAndNotSuccess || queriesAreLoading;

  const data = useMemo(() => {
    if (!takers) {
      return null;
    }

    return new DonationTakersDomain({ data: takers });
  }, [takers]);

  return {
    data,
    isLoading,
    notFound: false,
  };
};

export default { useDonation, useTakers };
