import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import type { ForwardedRef } from 'react';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import useDisclosure from '@/core/hooks/useDisclosure';
import useOnClickOutside from '@/core/hooks/useOnClickOutside';
import type { SelectInputProps, SelectItem } from '@/core/types/components';

type UseSelectInputParams<TValue = string> = SelectInputProps<TValue> & {
  forwardedRef: ForwardedRef<HTMLInputElement>;
  searchFn?: (search: string, items: SelectItem<TValue>[]) => SelectItem<TValue>[];
  findFn?: (value: TValue | null, data: SelectItem<TValue>[]) => SelectItem<TValue> | null;
  onNotFound?: (search: string) => void;
};

const find = <TValue = string>(value: TValue | null, data: SelectItem<TValue>[]): SelectItem<TValue> | null => {
  if (!value) return null;

  const firstLevel = data.find(item => isEqual(item.value, value));

  if (!firstLevel) {
    const children = data.flatMap(item => item.children ?? []);

    if (children.length === 0) return null;

    return find(value, children);
  }

  return firstLevel;
};

const useSelectInput = <TValue = string>(params: UseSelectInputParams<TValue>) => {
  const { forwardedRef, searchFn, findFn, items, onChange, onBlur, onNotFound, value, ...rest } = params;

  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSearching, setIsSearching] = useState(false);

  useImperativeHandle(forwardedRef, () => inputRef.current as HTMLInputElement);

  const [initialItems, setInitialItems] = useState(items);
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItem, setSelectedItem] = useState<SelectItem<TValue> | null>(findFn ? findFn(value, items) : find(value, items));
  const [previousItems, setPreviousItems] = useState<SelectItem<TValue>[][]>([]);

  useEffect(() => {
    if (!isEqual(initialItems, items)) {
      setFilteredItems(items);
      setInitialItems(items);
    }
  }, [initialItems, items]);

  const onSearch = debounce((search: string) => {
    if (search === '') {
      setIsSearching(false);
      setFilteredItems(items);
    } else {
      setIsSearching(true);
      const filtered = searchFn ? searchFn(search, items) : items.filter(item => item.text.includes(search));
      if (filtered.length === 0) {
        onNotFound?.(search);
      }
      setFilteredItems(filtered);
    }
  }, 500);

  useOnClickOutside([inputRef, divRef], () => {
    setIsSearching(false);
    onClose();
  });

  const onSelect = (item: SelectItem<TValue>) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (item.children && item.children.length > 0) {
      setPreviousItems(prevState => [...prevState, filteredItems]);
      setFilteredItems(item.children);
      setIsSearching(false);
    } else {
      setSelectedItem(item);
      setFilteredItems(items);
      setPreviousItems([]);
      setIsSearching(false);
      onChange?.(item.value);
      onBlur?.(item.value);
      onClose();
    }
  };

  const onBack =
    previousItems.length > 0
      ? () => {
          const copy = [...previousItems];
          const previous = copy.pop();
          setFilteredItems(previous as SelectItem<TValue>[]);
          setPreviousItems(copy);
        }
      : undefined;

  const onClean = () => {
    setFilteredItems(items);
    setSelectedItem(null);
    setIsSearching(false);
    onChange?.(null);
    onBlur?.(null);
    inputRef.current?.focus();
  };

  return {
    isOpen,
    isSearching,
    onOpen,
    onClose,
    items: filteredItems,
    selectedItem,
    onSelect,
    onSearch,
    onClean,
    onBack,
    refs: { inputRef, divRef },
    ...rest,
  };
};

export default useSelectInput;
