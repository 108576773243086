import React from 'react';

import type { RangeInputProps } from '@/core/components/inputs/RangeInput/RangeInput';
import RangeInput from '@/core/components/inputs/RangeInput/RangeInput';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const RadiusLoc: React.FC<RangeInputProps> = ({ max, min, onChange, step, value }) => {
  const { t } = useTranslationContext(['common']);
  const lengthUnit = t('components.radius-loc.length-unit', { ns: 'common' });

  return (
    <div className="flex flex-col gap-1">
      <div className="flex w-full flex-row justify-between">
        <p className="font-normal text-content-primary">{t('components.radius-loc.in-radius-of', { ns: 'common' })}</p>
        <p className="font-normal text-content-secondary">
          {value}
          &nbsp;
          {lengthUnit}
        </p>
      </div>
      <RangeInput value={value} min={min} max={max} step={step} onChange={onChange} />
      <div className="flex w-full flex-row justify-between">
        <p className="text-caption-primary font-normal text-content-placeholder">
          {min}
          &nbsp;
          {lengthUnit}
        </p>
        <p className="text-caption-primary font-normal text-content-placeholder">
          {max}
          &nbsp;
          {lengthUnit}
        </p>
      </div>
    </div>
  );
};

export default RadiusLoc;
