import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Trash: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M5 10H8.33333H35" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.3333 10.0002V6.66683C13.3333 5.78277 13.6844 4.93493 14.3096 4.30981C14.9347 3.68469 15.7825 3.3335 16.6666 3.3335H23.3333C24.2173 3.3335 25.0652 3.68469 25.6903 4.30981C26.3154 4.93493 26.6666 5.78277 26.6666 6.66683V10.0002M31.6666 10.0002V33.3335C31.6666 34.2176 31.3154 35.0654 30.6903 35.6905C30.0652 36.3156 29.2173 36.6668 28.3333 36.6668H11.6666C10.7825 36.6668 9.93468 36.3156 9.30956 35.6905C8.68444 35.0654 8.33325 34.2176 8.33325 33.3335V10.0002H31.6666Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.6667 18.3335V28.3335" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.3333 18.3335V28.3335" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Trash;
