import type CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import type { CategoriesSuggestionsResult } from '@/core/types/category';

class SuggestionDomain {
  private categoriesSuggestionsResult: CategoriesSuggestionsResult;

  private categoriesDomain: CategoriesDomain;

  constructor(categoriesSuggestionsResult: CategoriesSuggestionsResult, categoriesDomain: CategoriesDomain) {
    this.categoriesSuggestionsResult = categoriesSuggestionsResult;
    this.categoriesDomain = categoriesDomain;
  }

  get categoriesDomainSuggested() {
    return this.categoriesDomain.getParentOnlyItems().filter(parent => this.categoriesSuggestionsResult.categories.includes(parent.value.id));
  }
}

export default SuggestionDomain;
