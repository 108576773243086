import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const ArrowLeft: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M31.6668 20H8.3335M8.3335 20L20.0002 31.6667M8.3335 20L20.0002 8.33337" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArrowLeft;
