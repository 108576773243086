import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Feather: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M33.7333 20.3999C35.6097 18.5235 36.6638 15.9785 36.6638 13.3249C36.6638 10.6713 35.6097 8.1263 33.7333 6.2499C31.8568 4.37349 29.3119 3.31934 26.6583 3.31934C24.0046 3.31934 21.4597 4.37349 19.5833 6.2499L8.33325 17.4999V31.6666H22.4999L33.7333 20.3999Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M26.6666 13.3333L3.33325 36.6666" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M29.1667 25H15" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Feather;
