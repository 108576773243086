import React from 'react';

import { useCatalogueSearchContext } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import Icons from '@/core/components/Icons';
import { useNativeContext } from '@/core/lib/native/native.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const CatalogueSearchButton: React.FC = () => {
  const { t } = useTranslationContext(['common']);
  const { isNative } = useNativeContext();

  const { data, isOpen, onOpen } = useCatalogueSearchContext();

  if (isOpen) {
    return null;
  }

  const isDataEmpty = !data.title && !data.cat && !data.loc;

  return (
    <button
      type="button"
      onClick={onOpen}
      aria-label={t('search.button.aria', { ns: 'common' })}
      className={`box-content inline-flex h-10 w-full min-w-0 ${!isNative ? 'max-w-[476px]' : ''} items-center justify-between rounded-[26px] border border-solid border-[#7C7E88] bg-bg-primary p-1 pl-4`}
    >
      {isDataEmpty ? (
        <p className="text-body-secondary flex-nowrap text-start font-medium text-content-placeholder">{t('search.button.placeholder', { ns: 'common' })}</p>
      ) : (
        <div className="flex h-full min-w-0 flex-col overflow-hidden">
          {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
          <p className="text-caption-primary truncate text-ellipsis text-start font-medium text-content-secondary">{data.title ?? t('search.button.all-title', { ns: 'common' })}</p>
          <div className="flex w-full items-center overflow-hidden">
            {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
            <p className="text-caption-primary flex min-w-0 overflow-hidden truncate text-ellipsis text-nowrap font-normal text-content-primary">
              {data.loc ? (
                <>
                  {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
                  <span className="overflow-hidden truncate text-ellipsis">{data.loc.label}</span>
                  {data.loc.distance !== undefined ? <span>&nbsp;-&nbsp;{data.loc.distance}&nbsp;km</span> : null}
                </>
              ) : (
                t('search.button.all-locs', { ns: 'common' })
              )}
            </p>

            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none" className="mx-2">
              <path d="M1 1L1 11" stroke="#AAAAAA" strokeLinecap="round" />
            </svg>

            {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
            <p className="text-caption-primary min-w-0 overflow-hidden truncate text-ellipsis text-nowrap font-normal text-content-primary">
              {data.cat?.label ?? t('search.button.all-categories', { ns: 'common' })}
            </p>
          </div>
        </div>
      )}
      <div className="shrink-0 rounded-full bg-bg-button-primary-default p-3">
        <Icons icon="search" size="16" color="button-primary" />
      </div>
    </button>
  );
};

export default CatalogueSearchButton;
