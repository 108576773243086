import type { PropsWithChildren } from 'react';
import React from 'react';

import Icons from '@/core/components/Icons';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

type AlertStatus = 'error';

export interface AlertProps extends PropsWithChildren {
  status: AlertStatus;
  onClose?: () => void | undefined;
}

const bgs = {
  error: 'bg-bg-pale',
} satisfies { [key in AlertStatus]: string };

const texts = {
  error: 'text-content-danger',
} satisfies { [key in AlertStatus]: string };

const Alert: React.FC<AlertProps> = ({ status, children, onClose }) => {
  const { t } = useTranslationContext(['common']);

  return (
    <div role="alert" className={`flex w-full items-center justify-between rounded-2 ${bgs[status]} p-4`} aria-labelledby="alert-content">
      <p id="alert-content" className={`text-caption-primary ${texts[status]}`}>
        {children}
      </p>
      {onClose && <Icons icon="close" size="20" color="placeholder" onClick={onClose} label={t('alert.close', { ns: 'common' })} />}
    </div>
  );
};

export default Alert;
