import React from 'react';

import Button from '@/core/components/Button';
import Icons from '@/core/components/Icons';
import useMutation from '@/core/hooks/useMutation';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useBrowserContext } from '@/core/lib/browser/browser.context';
import { hackRedirectUri } from '@/core/lib/browser/hackRedirectUri';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { QUERY_KEYS } from '@/core/lib/query/query.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="mr-2 size-5">
    <path
      d="M12.3648 0.5C6.01357 0.5 0.864746 5.67035 0.864746 12.0481C0.864746 17.7683 5.01075 22.5055 10.4468 23.4229V14.4574H7.67268V11.2311H10.4468V8.85215C10.4468 6.09189 12.1257 4.5877 14.5782 4.5877C15.7527 4.5877 16.7621 4.67559 17.0552 4.7143V7.59951L15.3542 7.60034C14.0208 7.60034 13.7637 8.23651 13.7637 9.17037V11.2294H16.9454L16.5304 14.4557H13.7637V23.5C19.4536 22.8046 23.8647 17.9467 23.8647 12.0448C23.8647 5.67035 18.7159 0.5 12.3648 0.5Z"
      fill="#3B5998"
    />
  </svg>
);

const FacebookButton: React.FC = () => {
  const { t } = useTranslationContext(['common', 'auth']);
  const { env, DONNONS_API_URL } = useEnvContext();
  const { open } = useBrowserContext();
  const { closeAuth } = useAuthContext();
  const { send } = useAnalyticsContext();

  const mutation = useMutation<string, void>({
    apiDefinition: { method: ApiMethods.GET, url: `${DONNONS_API_URL}${ApiVersions.V1}${QUERY_KEYS.authFacebook().join('')}` },
  });

  const onClick = async () => {
    await send({ event: AnalyticsEventType.CLICK_LOGIN, origin: 'facebook' });
    let res = await mutation.mutateAsync();

    // To be able to use it in local dev or on validation environments, we update the redirect URI so it doesn't always redirect to preprod.donnons.org
    if (env === 'preprod') {
      res = hackRedirectUri(res);
    }
    await open(res);
    closeAuth();
  };

  return (
    <Button variant="gaf" onClick={onClick}>
      <span className="mr-2">{mutation.isPending ? <Icons icon="spinner" /> : <FacebookIcon />}</span>
      {t('facebook-button', { ns: 'auth' })}
    </Button>
  );
};

export default FacebookButton;
