import { KEY_WORD_REGEX } from '@/core/lib/regex';
import normalizeString from '@/core/lib/stringHelpers';
import type { SelectItem } from '@/core/types/components';
import type { DonationsCategoriesResult, DonationsCategoryOptionResult, DonationsCategoryOptionValuesResult, DonationsCategoryResult } from '@/core/types/donation';

export const sortingCategory = (categories: (DonationsCategoryResult & { children: number[] })[], searchValue: string): (DonationsCategoryResult & { children: number[] })[] => {
  const searchWithoutParenthesis = searchValue.trim().replace(KEY_WORD_REGEX, '');
  return categories
    .map(leaf => {
      const labelWithoutHyphen = leaf.label.replace('-', ' ');
      const exactFirst = new RegExp(`^${normalizeString(searchWithoutParenthesis)}`, 'g').exec(normalizeString(labelWithoutHyphen));
      const exactFullFirst = new RegExp(`^${normalizeString(searchWithoutParenthesis)}$`, 'g').exec(normalizeString(labelWithoutHyphen));
      const exactFirstAndSpace = new RegExp(`^${normalizeString(searchWithoutParenthesis)}[ ,]`, 'g').exec(normalizeString(labelWithoutHyphen));
      const spaceAndExact = new RegExp(` ${normalizeString(searchWithoutParenthesis)}(= |$)`, 'g').exec(normalizeString(labelWithoutHyphen));
      const isFind = normalizeString(leaf.label).includes(normalizeString(searchValue));
      let calc = 0;

      if (isFind) {
        calc = 10;
      }
      if (exactFirst) {
        calc = 100 - (leaf.label.length > 100 ? 11 : leaf.label.length);
      }
      if (spaceAndExact) {
        calc = 1000;
      }
      if (exactFirstAndSpace) {
        calc = 10000;
      }
      if (exactFullFirst) {
        calc = 100000;
      }
      return { distance: 1000000 - calc, leaf };
    })
    .sort((a, b) => {
      return a.distance - b.distance;
    })
    .filter(item => item.distance < 1000000)
    .map(item => {
      return item.leaf;
    });
};

export const getParent = (parentId: number, categoriesMapWithChild: Map<number, DonationsCategoryResult & { children: number[] }>): string => {
  const parent = categoriesMapWithChild.get(parentId);
  if (parent?.parent) {
    return `${getParent(parent.parent, categoriesMapWithChild)} > ${parent.label}`;
  }
  return parent?.label ? `${parent.label}` : '';
};

type CategoryWithOption = DonationsCategoryOptionResult & DonationsCategoryOptionValuesResult;

export type CategoryMap = Map<number, DonationsCategoryResult & { children: DonationsCategoryResult[]; options?: CategoryWithOption[] }>;

export const selectCategoryMap = (data: DonationsCategoriesResult): CategoryMap => {
  const map: CategoryMap = new Map([]);

  const array = data.categories ?? [];
  const dataOptions = data.options;
  const valuesByOption = new Map(data.values.map(value => [value.option, value]));

  const childrenToAppend: { [key: number]: DonationsCategoryResult[] } = {};

  for (let index = 0; index < array.length; index += 1) {
    const element = array[index];

    const options = dataOptions.reduce((acc, val) => {
      if (val.cat === element.id && val.option) {
        const values = valuesByOption.get(val.option);
        if (!values) return acc;
        return [...acc, { ...val, ...values }];
      }
      return acc;
    }, [] as CategoryWithOption[]);

    map.set(element.id, { ...element, children: childrenToAppend[element.id] ?? [], options });

    delete childrenToAppend[element.id];

    if (element.parent) {
      const parent = map.get(element.parent);

      if (parent) {
        map.set(parent.id, { ...parent, children: [...parent.children, element] });
      } else {
        childrenToAppend[element.parent] = [...(childrenToAppend[element.parent] ?? []), element];
      }
    }
  }

  return map;
};

const childrenToSelectItem = (children: DonationsCategoryResult[], map: CategoryMap): SelectItem<DonationsCategoryResult>[] => {
  if (children.length === 0) return [];

  return children.reduce((acc, child) => {
    const category = map.get(child.id);

    if (!category) return acc;

    return [
      ...acc,
      {
        value: child,
        text: map.get(child.id)?.label as string,
        children: childrenToSelectItem(category.children, map),
      },
    ];
  }, [] as SelectItem<DonationsCategoryResult>[]);
};

export interface SelectItemsAndMap {
  items: SelectItem<DonationsCategoryResult>[];
  map: CategoryMap;
}

export const categoriesToSelectItemsAndMap = (data: DonationsCategoriesResult): SelectItemsAndMap => {
  const map = selectCategoryMap(data);

  const parents = Array.from(map.values()).filter(parent => parent.parent === null);

  return {
    items: parents.map(parent => ({
      value: parent,
      text: parent.label,
      children: childrenToSelectItem(parent.children, map),
    })),
    map,
  };
};

export const searchCategories = (search: string, items: SelectItem<DonationsCategoryResult>[]): SelectItem<DonationsCategoryResult>[] => {
  const flats = items.flatMap(item => item.children ?? []);
  const leaves = [...flats.filter(flat => flat.children?.length === 0), ...flats.flatMap(child => child.children ?? [])];
  const searchWithoutParenthesis = search.trim().replace(KEY_WORD_REGEX, '');

  return leaves
    .map(leaf => {
      const labelWithoutHyphen = leaf.text.replace('-', ' ');
      const exactFirst = new RegExp(`^${normalizeString(searchWithoutParenthesis)}`, 'g').exec(normalizeString(labelWithoutHyphen));
      const exactFullFirst = new RegExp(`^${normalizeString(searchWithoutParenthesis)}$`, 'g').exec(normalizeString(labelWithoutHyphen));
      const exactFirstAndSpace = new RegExp(`^${normalizeString(searchWithoutParenthesis)}[ ,]`, 'g').exec(normalizeString(labelWithoutHyphen));
      const spaceAndExact = new RegExp(` ${normalizeString(searchWithoutParenthesis)}(= |$)`, 'g').exec(normalizeString(labelWithoutHyphen));
      const isFind = normalizeString(leaf.text).includes(normalizeString(search));
      let calc = 0;

      if (isFind) {
        calc = 10;
      }
      if (exactFirst) {
        calc = 100 - (leaf.text.length > 100 ? 11 : leaf.text.length);
      }
      if (spaceAndExact) {
        calc = 1000;
      }
      if (exactFirstAndSpace) {
        calc = 10000;
      }
      if (exactFullFirst) {
        calc = 100000;
      }
      return { distance: 1000000 - calc, leaf };
    })
    .sort((a, b) => {
      return a.distance - b.distance;
    })
    .filter(item => item.distance < 1000000)
    .map(item => {
      return item.leaf;
    });
};

export const searchAllCategories = (search: string, items: SelectItem<DonationsCategoryResult>[]): SelectItem<DonationsCategoryResult>[] => {
  const searchWithoutParenthesis = search.trim().replace(KEY_WORD_REGEX, '');
  return items
    .map(item => {
      const labelWithoutHyphen = item.text.replace('-', ' ');
      const exactFirst = new RegExp(`^${normalizeString(searchWithoutParenthesis)}`, 'g').exec(normalizeString(labelWithoutHyphen));
      const exactFullFirst = new RegExp(`^${normalizeString(searchWithoutParenthesis)}$`, 'g').exec(normalizeString(labelWithoutHyphen));
      const exactFirstAndSpace = new RegExp(`^${normalizeString(searchWithoutParenthesis)}[ ,]`, 'g').exec(normalizeString(labelWithoutHyphen));
      const spaceAndExact = new RegExp(` ${normalizeString(searchWithoutParenthesis)}(= |$)`, 'g').exec(normalizeString(labelWithoutHyphen));
      const isFind = normalizeString(item.text).includes(normalizeString(search));
      let calc = 0;

      if (isFind) {
        calc = 10;
      }
      if (exactFirst) {
        calc = 100 - (item.text.length > 100 ? 11 : item.text.length);
      }
      if (spaceAndExact) {
        calc = 1000;
      }
      if (exactFirstAndSpace) {
        calc = 10000;
      }
      if (exactFullFirst) {
        calc = 100000;
      }
      return { distance: 1000000 - calc, item };
    })
    .sort((a, b) => {
      return a.distance - b.distance;
    })
    .filter(item => item.distance < 1000000)
    .map(item => {
      return item.item;
    });
};

export const getAncestryString = (id: number, map?: CategoryMap): string => {
  if (!map) return '';

  const self = map.get(id);
  const parent = self && self.parent ? map.get(self.parent) : undefined;

  if (!parent) return '';

  return `${parent.label} > ${getAncestryString(parent.id, map)}`;
};
