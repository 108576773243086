import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import useMutation from '@/core/hooks/api/useMutation';
import renewOrRefresh from '@/core/lib/auth/renewOrRefresh';
import Context from '@/core/lib/new-architecture/context';
import Query from '@/core/lib/new-architecture/query';
import { usePersistContext } from '@/core/lib/persist/persist.context';
import { useRouterContext } from '@/core/lib/router/router.context';
import type { UsersAuthResult } from '@/core/types/auth';

const useRequest = () => {
  const { set, remove } = usePersistContext();

  const { accessToken, refreshToken, accessExpiresAt, refreshExpiresAt, isReady, setTokens } = Context.auth.useAuthContext();

  const onTokenSuccess = async (data: UsersAuthResult) => {
    const tokens = {
      accessToken: data.access_token,
      accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
      refreshToken: data.refresh_token ?? null,
      refreshExpiresAt: data.refresh_expires_in ? dayjs().add(data.refresh_expires_in, 'second') : null,
    };

    await Promise.all([
      tokens.accessToken ? set('accessToken', tokens.accessToken) : Promise.resolve(),
      tokens.accessExpiresAt ? set('accessExpiresAt', tokens.accessExpiresAt?.toISOString()) : Promise.resolve(),
      tokens.refreshToken ? set('refreshToken', tokens.refreshToken) : Promise.resolve(),
      tokens.refreshExpiresAt ? set('refreshExpiresAt', tokens.refreshExpiresAt.toISOString()) : Promise.resolve(),
    ]);

    setTokens(prev => ({
      accessToken: tokens.accessToken,
      accessExpiresAt: tokens.accessExpiresAt,
      refreshToken: tokens.refreshToken ?? prev.refreshToken,
      refreshExpiresAt: tokens.refreshExpiresAt ?? prev.refreshExpiresAt,
    }));
  };

  const renew = Query.auth.useRenew({
    onSuccess: onTokenSuccess,
  });

  const refresh = Query.auth.useRefresh({
    onSuccess: onTokenSuccess,
  });

  const [shouldCleanAccess, setShouldCleanAccess] = useState(false);
  const [shouldCleanRefresh, setShouldCleanRefresh] = useState(false);

  const cleanAccess = async () => {
    await Promise.all([remove('accessToken'), remove('accessExpiresAt')]);

    setTokens(prev => ({
      ...prev,
      accessToken: null,
      accessExpiresAt: null,
    }));

    setShouldCleanAccess(false);
  };

  const cleanRefresh = async () => {
    await Promise.all([remove('refreshToken'), remove('refreshExpiresAt')]);

    setTokens(prev => ({
      ...prev,
      refreshToken: null,
      refreshExpiresAt: null,
    }));

    setShouldCleanRefresh(false);
  };

  useEffect(() => {
    if (shouldCleanAccess) {
      cleanAccess();
    }
  }, [shouldCleanAccess]);

  useEffect(() => {
    if (shouldCleanRefresh) {
      cleanRefresh();
    }
  }, [shouldCleanRefresh]);

  const mutation = useMutation({
    mutationFn: async () => {
      const { shouldRenew, shouldRefresh, isAccessValid, isRefreshValid } = renewOrRefresh({ accessExpiresAt, refreshExpiresAt });

      if (accessToken && isReady) {
        if (shouldRenew && !renew.isPending && isAccessValid) {
          renew.mutate(accessToken);
          return accessToken;
        }

        if (!shouldRenew && !shouldRefresh && isAccessValid) {
          return accessToken;
        }

        if (shouldRefresh && refreshToken && !refresh.isPending) {
          try {
            if (isRefreshValid) {
              // refresh and send the new one
              const login = await refresh.mutateAsync(refreshToken);
              if ('access_token' in login) {
                return login.access_token;
              }
            }
          } catch {
            return null;
          }
        }
      }

      if (!isAccessValid) {
        setShouldCleanAccess(true);
      }
      if (!isRefreshValid) {
        setShouldCleanRefresh(true);
      }

      return null;
    },
  });

  const isLoggedIn = useMemo(() => !!accessToken, [accessToken]);

  const requestToken = async () => mutation.mutateAsync();

  return { requestToken, isLoggedIn, isReady };
};

const useModal = () => {
  const { query } = useRouterContext();

  const isAuthOpen = query.openAuth === 'true';

  return { isAuthOpen };
};

export default { useRequest, useModal };
