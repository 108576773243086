import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const MoreVertical: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M19.9999 21.6668C20.9204 21.6668 21.6666 20.9206 21.6666 20.0002C21.6666 19.0797 20.9204 18.3335 19.9999 18.3335C19.0794 18.3335 18.3333 19.0797 18.3333 20.0002C18.3333 20.9206 19.0794 21.6668 19.9999 21.6668Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9999 9.99984C20.9204 9.99984 21.6666 9.25364 21.6666 8.33317C21.6666 7.4127 20.9204 6.6665 19.9999 6.6665C19.0794 6.6665 18.3333 7.4127 18.3333 8.33317C18.3333 9.25364 19.0794 9.99984 19.9999 9.99984Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9999 33.3333C20.9204 33.3333 21.6666 32.5871 21.6666 31.6667C21.6666 30.7462 20.9204 30 19.9999 30C19.0794 30 18.3333 30.7462 18.3333 31.6667C18.3333 32.5871 19.0794 33.3333 19.9999 33.3333Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MoreVertical;
