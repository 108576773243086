import React, { useState } from 'react';

import Button from '@/core/components/Button';
import HintMessage from '@/core/components/HintMessage';
import { useGeolocationContext } from '@/core/lib/geolocation/geolocation.context';
import Actions from '@/core/lib/new-architecture/actions';
import { LocDomain } from '@/core/lib/new-architecture/domain/locs.domain';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface LocateMeButtonProps {
  id: string;
  onSelect: (value: LocDomain) => void;
}

const LocateMeButton: React.FC<LocateMeButtonProps> = ({ id, onSelect }) => {
  const { t } = useTranslationContext(['common']);
  const { coordinates } = useGeolocationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { onReverse } = Actions.locs.useReverse();

  const onClick = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    const location = await coordinates();

    if ('error' in location) {
      setErrorMessage(location.error);
    } else {
      const res = await onReverse(location);

      if ('error' in res) {
        setErrorMessage(res.t.key);
      } else {
        const loc = new LocDomain({ data: res.result });
        onSelect?.(loc);
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="flex flex-col gap-1">
      <Button id={id} isLoading={isLoading} iconLeft="crosshair" variant="secondary" onClick={onClick}>
        {t('inputs.city.modal.position', { ns: 'common' })}
      </Button>
      {errorMessage && <HintMessage isError message={errorMessage} />}
    </div>
  );
};

export default LocateMeButton;
