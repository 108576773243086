import React from 'react';

import Image from '@/core/components/Image';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

type AvatarSize = '16' | '24' | '32' | '40' | '48' | '80';

interface AvatarProps {
  avatarUrl?: string;
  size: AvatarSize;
}

const Avatar: React.FC<AvatarProps> = ({ avatarUrl, size }) => {
  const { t } = useTranslationContext(['common']);

  if (!avatarUrl) return null;

  const sizes = {
    16: 'h-4 w-4',
    24: 'h-6 w-6',
    32: 'h-8 w-8',
    40: 'h-10 w-10',
    48: 'h-12 w-12',
    80: 'h-20 w-20',
  } satisfies { [key in AvatarSize]: string };

  return (
    <div className={`flex ${sizes[size]} items-center justify-center rounded-full`}>
      <Image
        loading="lazy"
        decoding="async"
        className="rounded-full"
        src={avatarUrl.replace('{{size}}', '1').replace('{{ext}}', 'webp')}
        fallback={avatarUrl.replace('{{size}}', '1').replace('{{ext}}', 'jpg')}
        type="image/webp"
        alt={t('components.avatar.alt', { ns: 'common' })}
        width={size}
        height={size}
      />
    </div>
  );
};

export default Avatar;
