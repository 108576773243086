import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { HomeResult } from '@/core/types/home';
import { homeResultSchema } from '@/core/types/home';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async () => {
    return api.get<HomeResult>({
      url: `${getUrl()}/`,
      schema: homeResultSchema,
    });
  },
  version: async () => {
    return api.get<HomeResult>({
      url: `${getUrl()}/${ApiVersions.V1}/version`,
      schema: homeResultSchema,
    });
  },
};

export default { query };
