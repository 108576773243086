import type { SelectItem } from '@/core/types/components';
import type { DonationsCategoriesResult, DonationsCategoryResult } from '@/core/types/donation';

interface CategoryOption {
  option: string;
  values: string[];
  isRequired: boolean;
}

export interface CategoryDomainInterface {
  id: number;
  state: number;
  label: string;
  alias: string;
  parent: number | null;
  children: CategoryDomainInterface[];
  options: CategoryOption[];

  getDonationsCategoryResult: () => DonationsCategoryResult;
}

export class CategoryDomain implements CategoryDomainInterface {
  public id: number;

  public state: number;

  public label: string;

  public alias: string;

  public parent: number | null;

  public children: CategoryDomain[];

  public options: CategoryOption[];

  constructor(data: DonationsCategoryResult) {
    this.id = data.id;
    this.state = data.state;
    this.label = data.label;
    this.alias = data.alias;
    this.parent = data.parent ?? null;
    this.children = [];
    this.options = [];
  }

  public setOptions(options: CategoryOption[]) {
    this.options = options;
  }

  public setChildren(children: CategoryDomain[]) {
    this.children = children;
  }

  public getDonationsCategoryResult(): DonationsCategoryResult {
    return {
      id: this.id,
      state: this.state,
      label: this.label,
      alias: this.alias,
      parent: this.parent,
    };
  }
}

export type CategoryMap = Map<number, CategoryDomain>;
export type CategoryAliasMap = Map<string, CategoryDomain>;

export interface CategoriesDomainInterface {
  map: CategoryMap;
  findByAlias: (alias: string) => CategoryDomain | null;
  findById: (id: number) => CategoryDomain | null;

  getAncestryString: (id: number) => string;
  findHighestAncestor: (id: number) => CategoryDomain | null;

  // TO-DO delete
  getItems: () => SelectItem<DonationsCategoryResult>[];
}

class CategoriesDomain implements CategoriesDomainInterface {
  public map: CategoryMap;

  public aliasMap: CategoryAliasMap;

  private suggestions?: number[];

  constructor(data: DonationsCategoriesResult) {
    const map: CategoryMap = new Map([]);
    const aliasMap: CategoryAliasMap = new Map([]);

    const array = data.categories;
    const dataOptions = data.options;
    const valuesByOption = new Map(data.values.map(value => [value.option, value]));

    const childrenToAppend: { [key: number]: CategoryDomain[] } = {};

    for (let index = 0; index < array.length; index += 1) {
      const element = array[index];

      const options = dataOptions.reduce((acc, val) => {
        if (val.cat === element.id && val.option) {
          const values = valuesByOption.get(val.option);
          if (!values) return acc;
          return [...acc, { isRequired: !!val.is_required, ...values }];
        }
        return acc;
      }, [] as CategoryOption[]);

      const category = new CategoryDomain(element);
      category.setOptions(options);
      category.setChildren(childrenToAppend[element.id] ?? []);
      map.set(element.id, category);
      aliasMap.set(element.alias, category);

      delete childrenToAppend[element.id];

      if (element.parent) {
        const parent = map.get(element.parent);

        if (parent) {
          parent.setChildren([...parent.children, category]);
          map.set(parent.id, parent);
          aliasMap.set(parent.alias, parent);
        } else {
          childrenToAppend[element.parent] = [...(childrenToAppend[element.parent] ?? []), category];
        }
      }
    }

    this.map = map;
    this.aliasMap = aliasMap;
  }

  public findByAlias(alias: string): CategoryDomain | null {
    return this.aliasMap.get(alias) ?? null;
  }

  public findManyByAlias(aliases: string[]): (CategoryDomain | undefined)[] {
    return aliases.map(alias => this.aliasMap.get(alias));
  }

  public findById(id: number): CategoryDomain | null {
    return this.map.get(id) ?? null;
  }

  public getAncestryString(id: number): string {
    const cat = this.map.get(id);
    const parent = cat && cat.parent ? this.map.get(cat.parent) : undefined;

    if (!parent) return '';

    return `${parent.label} > ${this.getAncestryString(parent.id)}`;
  }

  private childrenToSelectItem(children: DonationsCategoryResult[]): SelectItem<DonationsCategoryResult>[] {
    if (children.length === 0) return [];

    return children.reduce((acc, child) => {
      const category = this.map.get(child.id);

      if (!category || category.state !== 1) return acc;

      return [
        ...acc,
        {
          value: child,
          text: this.map.get(child.id)?.label as string,
          children: this.childrenToSelectItem(category.children),
        },
      ];
    }, [] as SelectItem<DonationsCategoryResult>[]);
  }

  public getItems(): SelectItem<DonationsCategoryResult>[] {
    const parents = Array.from(this.map.values()).filter(parent => parent.parent === null && parent.state === 1);

    return parents.map(parent => ({
      value: parent,
      text: parent.label,
      children: this.childrenToSelectItem(parent.children),
    }));
  }

  public getParentOnlyItems(): SelectItem<DonationsCategoryResult>[] {
    const parents = Array.from(this.map.values()).filter(parent => parent.parent === null && parent.state === 1);

    return parents.map(parent => ({
      value: parent,
      text: parent.label,
      children: [],
    }));
  }

  public findHighestAncestor(id: number): CategoryDomain | null {
    const cat = this.map.get(id);

    if (!cat) return null;

    const parent = cat.parent ? this.map.get(cat.parent) : null;

    // if there is no parent then it is the highest ancestor
    if (!parent) return cat;

    return this.findHighestAncestor(parent.id);
  }
}

export default CategoriesDomain;
