import DeleteSavedSearch from '@/core/lib/new-architecture/api/savedSearch/savedSearch.delete';
import GetSavedSearch from '@/core/lib/new-architecture/api/savedSearch/savedSearch.get';
import ReadSavedSearch from '@/core/lib/new-architecture/api/savedSearch/savedSearch.read';
import UpsertSavedSearch from '@/core/lib/new-architecture/api/savedSearch/savedSearch.upsert';

const query = {
  get: new GetSavedSearch(),
};

const mutation = {
  upsert: new UpsertSavedSearch(),
  delete: new DeleteSavedSearch(),
  read: new ReadSavedSearch(),
};

export default { query, mutation };
