import React from 'react';

import Icons from '@/core/components/Icons';

export type DonateType = 'primary' | 'secondary' | 'empty' | 'clean';

export interface DonateProps {
  type?: DonateType;
}

const Donate: React.FC<DonateProps> = ({ type = 'primary' }) => {
  const divTypes = {
    primary: 'bg-bg-button-give-default border-transparent',
    secondary: 'bg-bg-primary border-stroke-button-give-default',
    empty: 'bg-bg-secondary border-transparent',
    clean: 'bg-bg-dark border-transparent opacity-70 rotate-45',
  } satisfies { [key in DonateType]: string };

  return (
    <div className={`flex size-6 items-center justify-center rounded-full border-2 ${divTypes[type]}`}>
      <Icons icon="add" size="24" color={type === 'secondary' ? 'give' : 'button-primary'} />
    </div>
  );
};

export default Donate;
