import { createContext, useContext } from 'react';

export interface NativeContextType {
  isNative: boolean;
  platform: 'web' | 'ios' | 'android';
  version: string;
  isKeyboardOpen: boolean;
  shouldHideKeyboard: boolean;
  setShouldHideKeyboard: (value: boolean) => void;
  hideKeyboard: () => Promise<void>;
  openNativeSettings?: () => Promise<{ status: boolean }>;
}

export const NativeContext = createContext<NativeContextType | null>(null);

export const useNativeContext = () => useContext(NativeContext) as NativeContextType;
