import type { PermissionStatus } from '@capacitor/push-notifications';
import { createContext, useContext } from 'react';

export interface DefaultNotificationSchema {
  body?: string;
  title?: string;
  uuid: string;
}

export enum PushActionType {
  MSG_GIVER = 'msg_giver',
  MSG_TAKER = 'msg_taker',
  SAVED_SEARCH = 'saved_search',
  SAVED_SEARCHES = 'saved_searches',
}

type MessageGiverNotification = DefaultNotificationSchema & { type: PushActionType.MSG_GIVER; conversation: number };

type MessageTakerNotification = DefaultNotificationSchema & { type: PushActionType.MSG_TAKER; conversation: number };

type SavedSearchNotification = DefaultNotificationSchema & { type: PushActionType.SAVED_SEARCH; donation: number };

type SavedSearchesNotification = DefaultNotificationSchema & { type: PushActionType.SAVED_SEARCHES };

export type NotificationData = MessageGiverNotification | MessageTakerNotification | SavedSearchNotification | SavedSearchesNotification;

export interface RegisterArgs {
  setToken: (token: string) => void;
  pushNotification: (value: NotificationData) => void;
  pushActionPerformed: (value: NotificationData) => void;
}

export interface UnregisterArgs {
  unsetToken: () => void;
}

export interface NotificationContextType {
  register: (args: RegisterArgs) => Promise<void>;
  unregister: (args: UnregisterArgs) => Promise<void>;
  getIsReceiveGrantedAndIsDenied: () => Promise<{ isGranted: boolean; isDenied: boolean }>;
  requestPermission: () => Promise<NotificationPermission | PermissionStatus>;
}

export const NotificationContext = createContext<NotificationContextType | null>(null);

export const useNotificationContext = () => useContext(NotificationContext) as NotificationContextType;
