import { useMemo } from 'react';

import CategoriesDomain from '@/core/lib/new-architecture/domain/categories.domain';
import Query from '@/core/lib/new-architecture/query';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const useCategories = (): StoreData<CategoriesDomain> => {
  const { data, isLoading } = Query.categories.useCategories({});

  const categories = useMemo(() => {
    if (!data) return null;

    return new CategoriesDomain(data);
  }, [data]);

  return {
    data: categories,
    isLoading,
    notFound: false,
  };
};

export default { useCategories };
