import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Heart: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M34.7333 7.68345C33.8821 6.83179 32.8713 6.15619 31.7589 5.69525C30.6465 5.23432 29.4541 4.99707 28.25 4.99707C27.0458 4.99707 25.8535 5.23432 24.7411 5.69525C23.6286 6.15619 22.6179 6.83179 21.7667 7.68345L20 9.45011L18.2333 7.68345C16.5138 5.96396 14.1817 4.99796 11.75 4.99796C9.31827 4.99796 6.98615 5.96396 5.26666 7.68345C3.54717 9.40293 2.58118 11.7351 2.58118 14.1668C2.58118 16.5985 3.54717 18.9306 5.26666 20.6501L7.03333 22.4168L20 35.3834L32.9667 22.4168L34.7333 20.6501C35.585 19.7989 36.2606 18.7881 36.7215 17.6757C37.1825 16.5633 37.4197 15.3709 37.4197 14.1668C37.4197 12.9626 37.1825 11.7703 36.7215 10.6579C36.2606 9.54542 35.585 8.53471 34.7333 7.68345V7.68345Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Heart;
