import { useQueryClient } from '@tanstack/react-query';

import useInfiniteQuery from '@/core/hooks/api/useInfiniteQuery';
import { DONATIONS_SIZE } from '@/core/lib/constants';
import Api from '@/core/lib/new-architecture/api';
import getToken from '@/core/lib/new-architecture/query/token';
import type { InfiniteQuery } from '@/core/lib/new-architecture/query/types.query';
import type { DonationsPrivateDonationQuery } from '@/core/types/donation';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/user', '/donations'] as const,
  donations: (data: DonationsPrivateDonationQuery) => [...keys.base, JSON.stringify(data)] as const,
};

type AwaitedDonations = Awaited<ReturnType<typeof Api.query.user.donations.get>>;

const useUserDonations: InfiniteQuery<AwaitedDonations, DonationsPrivateDonationQuery> = ({ data, token, enabled }) => {
  return useInfiniteQuery({
    queryKey: keys.donations(data),
    queryFn: async ({ pageParam }) => Api.query.user.donations.get({ ...data, from: pageParam as number, size: data.size ?? DONATIONS_SIZE }, await getToken(token)),
    initialPageParam: data.from ?? 0,
    getNextPageParam: (lastPage, pages) => {
      const lastLength = lastPage.donations.length;

      const { length } = pages.flatMap(page => page.donations);
      if (lastPage.donations.length < DONATIONS_SIZE || length === lastLength) return undefined;

      const size = data.size ?? DONATIONS_SIZE;

      return (length / size) * size;
    },
    enabled,
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  return { invalidate };
};

export default { useUserDonations, useInvalidate };
