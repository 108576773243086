import type { InfiniteData, QueryKey, UseInfiniteQueryOptions, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useInfiniteQuery as useRqInfiniteQuery } from '@tanstack/react-query';

import type { APIError } from '@/core/lib/fetch';

const useInfiniteQuery = <TQueryFnData, TError = APIError, TData = InfiniteData<TQueryFnData>, TQueryKey extends QueryKey = QueryKey, TPageParam = unknown>(
  options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey, TPageParam>,
): UseInfiniteQueryResult<TData, TError> => {
  return useRqInfiniteQuery<TQueryFnData, TError, TData, TQueryKey, TPageParam>(options);
};

export default useInfiniteQuery;
