import React from 'react';

import * as auth from '@/core/lib/new-architecture/actions/auth.actions';
import * as conversation from '@/core/lib/new-architecture/actions/conversation.actions';
import * as conversations from '@/core/lib/new-architecture/actions/conversations.actions';
import * as donation from '@/core/lib/new-architecture/actions/donation.actions';
import * as locs from '@/core/lib/new-architecture/actions/locs.actions';
import * as push from '@/core/lib/new-architecture/actions/push.actions';
import * as report from '@/core/lib/new-architecture/actions/report.actions';
import * as savedSearch from '@/core/lib/new-architecture/actions/savedSearch.actions';
import * as user from '@/core/lib/new-architecture/actions/user.actions';

const AppWideActions: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <auth.AuthActions>{children}</auth.AuthActions>;
};

const Actions = {
  auth,
  push,
  user,
  savedSearch,
  donation,
  locs,
  conversation,
  conversations,
  report,
  AppWideActions,
};

export default Actions;
