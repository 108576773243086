import type { AnySchema } from 'yup';

import { APIError } from '@/core/lib/fetch';
import type { DonnonsErrorConstructor } from '@/core/lib/fetch/error';
import { DonnonsError } from '@/core/lib/fetch/error';

type CallType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'IMAGE';

interface CallParams<TData extends FormData | object | void = void> {
  url: string;
  schema?: AnySchema;
  token?: string;
  data?: TData;
}

const call = async <TRes, TData extends FormData | object | void = void, TError = void>(type: CallType, params: CallParams<TData>) => {
  let method = type;
  let body: BodyInit;
  let headers: HeadersInit = {
    Accept: 'application/json',
    ...(params.token
      ? {
          Authorization: `Bearer ${params.token}`,
        }
      : {}),
  };

  if (type === 'IMAGE') {
    method = 'POST';
    body = params.data as FormData;
  } else {
    body = JSON.stringify(params.data);
    headers = { ...headers, 'Content-Type': 'application/json' };
  }

  try {
    const response = await fetch(params.url, {
      method,
      body,
      headers,
    });

    if (response.status === 204 || response.status === 304) {
      return null as TRes;
    }

    let json;

    try {
      json = await response.json();
    } catch {
      // do nothing
    }

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    try {
      params.schema?.validateSync(json);
    } catch (err) {
      console.warn(`[Schema Validation Error] API : ${method} ${params.url}`, { json, err });
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

interface ApiParams {
  url: string;
  schema?: AnySchema;
  token?: string;
}

interface GetParams extends ApiParams {}

interface PostPutPatchDeleteParams<TData extends object | void> extends ApiParams {
  data: TData extends void ? undefined : TData;
}

interface ImageParams extends ApiParams {
  data: FormData;
}

const api = {
  get: async <TRes>(params: GetParams) => call<TRes>('GET', params),
  post: async <TRes, TData extends object | void = void>(params: PostPutPatchDeleteParams<TData>) => call<TRes, TData>('POST', params),
  put: async <TRes, TData extends object | void = void>(params: PostPutPatchDeleteParams<TData>) => call<TRes, TData>('PUT', params),
  patch: async <TRes, TData extends object | void = void>(params: PostPutPatchDeleteParams<TData>) => call<TRes, TData>('PATCH', params),
  destroy: async <TRes, TData extends object | void = void>(params: PostPutPatchDeleteParams<TData>) => call<TRes, TData>('DELETE', params),
  image: async <TRes>(params: ImageParams) => call<TRes, FormData>('IMAGE', params),
};

interface FetchCaller {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: BodyInit;
  headers?: { [key: string]: string };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponseAndJson = { response: Response; json: any };

class CallerError extends DonnonsError {
  constructor({ response, json }: DonnonsErrorConstructor) {
    super({ response, json });
  }
}

class Caller {
  private headers: HeadersInit;

  private schema?: AnySchema;

  constructor(schema?: AnySchema) {
    this.headers = {
      Accept: 'application/json',
    };
    this.schema = schema;
  }

  public addBearer(token: string) {
    this.headers = { ...this.headers, Authorization: `Bearer ${token}` };
  }

  public async fetch({ url, method, body, headers }: FetchCaller): Promise<ResponseAndJson> {
    const response = await fetch(url, {
      method,
      body,
      headers: { ...this.headers, ...headers },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let json: any = null;

    try {
      if (response.status !== 204 && response.status < 400 && this.schema) {
        json = await response.json();
        this.schema.validateSync(json);
      }
    } catch (err) {
      console.warn(`[Schema Validation Error] API : ${method} ${url}`, { err });
    }

    if (response.status >= 400) {
      json = await response.json();
      throw new CallerError({ response, json });
    }

    return { response, json };
  }
}

export class Get extends Caller {
  public request(url: string, token?: string): Promise<ResponseAndJson> {
    if (token) {
      this.addBearer(token);
    }
    return this.fetch({ url, method: 'GET', headers: { 'Content-Type': 'application/json' } });
  }
}

export class Post extends Caller {
  public request(url: string, data: object | undefined, token?: string): Promise<ResponseAndJson> {
    if (token) {
      this.addBearer(token);
    }
    return this.fetch({ url, method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } });
  }
}

export class Image extends Caller {
  public request(url: string, data: FormData, token?: string): Promise<ResponseAndJson> {
    if (token) {
      this.addBearer(token);
    }
    return this.fetch({ url, method: 'POST', body: JSON.stringify(data) });
  }
}

export class Put extends Caller {
  public request(url: string, data: object | undefined, token?: string): Promise<ResponseAndJson> {
    if (token) {
      this.addBearer(token);
    }
    return this.fetch({ url, method: 'PUT', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } });
  }
}

export class Patch extends Caller {
  public request(url: string, data: object | undefined, token?: string): Promise<ResponseAndJson> {
    if (token) {
      this.addBearer(token);
    }
    return this.fetch({ url, method: 'PATCH', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } });
  }
}

export class Delete extends Caller {
  public request(url: string, data: object | undefined, token?: string): Promise<ResponseAndJson> {
    if (token) {
      this.addBearer(token);
    }
    return this.fetch({ url, method: 'DELETE', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } });
  }
}

export default api;
