import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import type { Query } from '@/core/lib/new-architecture/query/types.query';
import type { DocumentsFileResult } from '@/core/types/document';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/document'] as const,
  cgu: (version: string = 'latest') => [...keys.base, '/cgu', version] as const,
};

interface DocumentsParams {
  version?: string;
}

const useCgu: Query<DocumentsFileResult, DocumentsParams> = ({ data: { version = 'latest' } }) => {
  return useQuery({
    queryKey: keys.cgu(version),
    queryFn: async () => Api.query.documents.cgu(version),
    retry: 0,
  });
};

useCgu.prefetch = async ({ queryClient, version }) => {
  await queryClient.prefetchQuery({
    queryKey: keys.cgu(version),
    queryFn: () => Api.query.documents.cgu(version),
  });
};

export default { useCgu };
