import { useMutation } from '@tanstack/react-query';

import type { APIError } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import Query from '@/core/lib/new-architecture/query';
import getToken from '@/core/lib/new-architecture/query/token';
import Store from '@/core/lib/new-architecture/store';
import type { ReportDonationReasons, ReportUserReasons } from '@/core/types/report';

type AwaitedReportDonation = Awaited<ReturnType<typeof Api.mutation.report.report>>;

export interface ReportDonation {
  donation: number;
  reasons: ReportDonationReasons[];
  comment?: string;
}

interface ReportDonationCallback {
  onSuccess?: (res: AwaitedReportDonation, data: ReportDonation) => void;
  onError?: (error: APIError) => void;
}

const useReportDonation = ({ onSuccess, onError }: ReportDonationCallback) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidateDonation } = Query.donation.useInvalidate();

  const mutation = useMutation<AwaitedReportDonation, APIError, ReportDonation>({
    mutationFn: async (data: ReportDonation) => Api.mutation.report.report(data, await getToken(requestToken)),
    onSuccess: async (res, data) => {
      await invalidateDonation(data.donation);
      onSuccess?.(res, data);
    },
    onError,
  });

  const onReport = async (data: ReportDonation) => mutation.mutateAsync(data);

  return { onReport, isLoading: mutation.isPending };
};

type AwaitedReportUser = Awaited<ReturnType<typeof Api.mutation.report.report>>;

export interface ReportUser {
  user: string;
  reasons: ReportUserReasons[];
  comment?: string;
}

interface ReportUserCallback {
  onSuccess?: (res: AwaitedReportUser, data: ReportUser) => void;
  onError?: (error: APIError) => void;
}

const useReportUser = ({ onSuccess, onError }: ReportUserCallback) => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.user.useInvalidate();

  const mutation = useMutation<AwaitedReportUser, APIError, ReportUser>({
    mutationFn: async (data: ReportUser) => Api.mutation.report.report(data, await getToken(requestToken)),
    onSuccess: async (res, data) => {
      await invalidate();
      onSuccess?.(res, data);
    },
    onError,
  });

  const onReport = async (data: ReportUser) => mutation.mutateAsync(data);

  return { onReport, isLoading: mutation.isPending };
};

export { useReportDonation, useReportUser };
