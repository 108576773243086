import { ApiVersions } from '@/core/lib/fetch';
import { Patch } from '@/core/lib/fetch/api';
import type { DonnonsErrorConstructor, QueryError } from '@/core/lib/fetch/error';
import { DonnonsError } from '@/core/lib/fetch/error';
import getUrl from '@/core/lib/new-architecture/api/url';
import type { SavedSearchesResult } from '@/core/types/favorite';

class ReadSavedSearchError extends DonnonsError implements QueryError {
  constructor({ response, json }: DonnonsErrorConstructor) {
    super({ response, json });
  }

  public getApiError(): null {
    return null;
  }
}

class ReadSavedSearch extends Patch {
  public async call(hash: string, token: string): Promise<SavedSearchesResult> {
    try {
      const { json } = await this.request(`${getUrl()}${ApiVersions.V1}/saved-search/${hash}`, undefined, token);

      return json as SavedSearchesResult;
    } catch (err) {
      if ('CAUGHT' in (err as DonnonsError)) {
        const { response, json } = (err as DonnonsError).getResAndJson();
        throw new ReadSavedSearchError({ response, json });
      }
      // TO-DO handle errors
      throw err;
    }
  }
}

export default ReadSavedSearch;
