import type { RefObject } from 'react';
import { useEffect } from 'react';

function useKeyDownHandler<T extends HTMLElement = HTMLInputElement>(refs: RefObject<T>[], keyName: string, handler?: (event: KeyboardEvent) => void | undefined): void {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === keyName && handler) {
        handler(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // Reload only if ref or handler changes
  }, [refs, handler]);
}

export default useKeyDownHandler;
