import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';
import type { DonationsPrivateDonationListResult, DonationsPrivateDonationQuery } from '@/core/types/donation';
import { donationsPrivateDonationListResultSchema, donationsPrivateDonationQuerySchema } from '@/core/types/donation';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const query = {
  get: async (data: DonationsPrivateDonationQuery, token: string) => {
    const cast = donationsPrivateDonationQuerySchema.cast(data, { stripUnknown: true, assert: false });

    const params = new URLSearchParams();

    Object.entries(cast).map(([key, val]) => params.set(key, val.toString()));

    const cat = params.get('cat');
    if (cat) {
      params.delete('cat');
      params.set('cat[]', cat);
    }

    const states = params.get('states');
    if (states) {
      params.delete('states');
      data.states?.forEach(state => {
        if (state) {
          params.append('states[]', state);
        }
      });
    }

    return api.get<DonationsPrivateDonationListResult>({
      url: `${getUrl()}${ApiVersions.V1}/user/donations${Array.from(params.entries()).length > 0 ? `?${params.toString()}` : ''}&nothing=nothing`,
      schema: donationsPrivateDonationListResultSchema,
      token,
    });
  },
};

export default { query };
