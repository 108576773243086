import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Smile: React.FC<IconProps> = ({ className }) => (
  <svg viewBox="0 0 12 12" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="smile" clipPath="url(#clip0_3583_9391)">
      <path
        id="Vector"
        d="M4 7C4 7 4.75 8 6 8C7.25 8 8 7 8 7M4.49998 4.49998H4.50498M7.49998 4.49998H7.50498M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3583_9391">
        <rect className={className} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Smile;
