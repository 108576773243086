import type { FormFocusNextInputs } from '@/core/types/form';

export const transformValuesToFocusMapping = <TForm>(valuesForm: object) => {
  return Object.entries(valuesForm)
    .filter((_, index) => {
      const nextInputExit = !!Object.entries(valuesForm)[index + 1];
      return nextInputExit;
    })
    .map(([key], index) => ({
      id: key,
      inputToFocus: Object.entries(valuesForm)[index + 1][0],
    })) as FormFocusNextInputs<TForm>[];
};

export const focusInputs = <TForm>(activeElement: HTMLInputElement, inputsFocusMapping: FormFocusNextInputs<TForm>[]) => {
  const { id } = activeElement;
  return inputsFocusMapping.find(inputFocusMapping => inputFocusMapping.id === id)?.inputToFocus;
};
