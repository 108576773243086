import React from 'react';

interface HtmlImgProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'alt'> {}

interface ImageProps extends HtmlImgProps {
  src: string;
  type: string;
  alt: string;
  fallback?: string;
}

const Image: React.FC<ImageProps> = ({ src, type, alt, fallback = src, ...delegated }) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} alt={alt} {...delegated} />
    </picture>
  );
};

export default Image;
