import React from 'react';

import BottomSheet from '@/core/components/BottomSheet';
import type { IconName } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import useDisclosure from '@/core/hooks/useDisclosure';
import { useNativeContext } from '@/core/lib/native/native.context';

interface HeaderProps {
  content: string | React.ReactNode;
  iconBefore?: IconName;
  labelBefore?: string;
  onBefore?: () => void;
  iconAfter?: IconName;
  labelAfter?: string;
  onAfter?: () => void;
  actions?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ content, iconBefore, labelBefore, onBefore, iconAfter, labelAfter, onAfter, actions }) => {
  const { isNative } = useNativeContext();

  const { isOpen: isBottomSheetOpen, onOpen: onOpenBottomSheet, onClose: onCloseBottomSheet } = useDisclosure();

  const onAfterLocalClick = onAfter ?? onOpenBottomSheet;

  return (
    <div aria-labelledby="header-content" className={`${isNative ? 'sticky left-0 top-0 pt-safe-top' : ''} z-10 flex w-full items-center justify-center bg-bg-primary lg:relative`}>
      {iconBefore ? (
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            onBefore?.();
          }}
          aria-label={labelBefore}
          className="flex size-8 shrink-0 items-center justify-center rounded-full hover:bg-bg-button-ghost-hover"
        >
          <Icons icon={iconBefore} weight="bold" size="24" />
        </button>
      ) : (
        <div className="size-8 shrink-0" />
      )}

      <div id="header-content" className="text-body-primary grow text-center font-semibold text-content-secondary">
        {content}
      </div>

      {iconAfter ? (
        <button type="button" onClick={onAfterLocalClick} aria-label={labelAfter} className="flex size-8 shrink-0 items-center justify-center rounded-full hover:bg-bg-button-ghost-hover">
          <Icons icon={iconAfter} size="24" />
        </button>
      ) : (
        <div className="size-8 shrink-0" />
      )}

      {actions && (
        <BottomSheet isOpen={isBottomSheetOpen} onClose={onCloseBottomSheet}>
          {actions}
        </BottomSheet>
      )}
    </div>
  );
};

export default Header;
