import { useCallback, useEffect, useRef } from 'react';

import Context from '@/core/lib/new-architecture/context';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { NotificationData } from '@/core/lib/notification/notification.context';
import { PushActionType, useNotificationContext } from '@/core/lib/notification/notification.context';
import { usePersistContext } from '@/core/lib/persist/persist.context';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';

const useRegister = () => {
  const { set, device } = Context.push.usePushContext();

  const { register: notificationRegister } = useNotificationContext();

  const { requestToken } = Store.auth.useRequest();

  const { push } = useRouterContext();
  const { invalidate: invalidationConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidationConversations } = Query.conversations.useInvalidate();
  const { invalidate: invalidationSavedSearch } = Query.savedSearch.useInvalidate();

  Query.push.useRegister({ data: { device }, token: requestToken });

  const onPushNotification = async (value: NotificationData) => {
    switch (value.type) {
      case PushActionType.MSG_GIVER:
      case PushActionType.MSG_TAKER:
        await Promise.all([invalidationConversation(), invalidationConversations()]);
        break;

      case PushActionType.SAVED_SEARCH:
      case PushActionType.SAVED_SEARCHES:
        await invalidationSavedSearch();
        break;
      default:
        break;
    }
  };

  const onPushActionPerformed = async (value: NotificationData) => {
    switch (value.type) {
      case PushActionType.MSG_GIVER:
      case PushActionType.MSG_TAKER:
        await Promise.all([invalidationConversation(), invalidationConversations()]);
        push(new Routes.ConversationRoute(value.conversation));
        break;

      case PushActionType.SAVED_SEARCH:
        await invalidationSavedSearch();
        push(new Routes.DonationRoute(value.donation));
        break;

      case PushActionType.SAVED_SEARCHES:
        await invalidationSavedSearch();
        push(new Routes.FavoritesRoute());
        break;
      default:
        break;
    }
  };

  const register = useCallback(async () => {
    await notificationRegister({
      setToken: set,
      pushNotification: onPushNotification,
      pushActionPerformed: onPushActionPerformed,
    });
  }, []);

  return { register, onPushActionPerformed };
};

const useUnregister = () => {
  const { unset, device } = Context.push.usePushContext();
  const { unregister: notificationUnregister } = useNotificationContext();

  const localDevice = useRef<string | null>(device);
  useEffect(() => {
    // we only keep the device to allow the delete of the device id
    // without waiting on the store to update or not
    if (device) {
      localDevice.current = device;
    }
  }, [device]);

  const mutation = Query.push.useUnregister({});

  const unregister = useCallback(async (token: string) => {
    await notificationUnregister({ unsetToken: unset });

    // at this point we known the device is set
    mutation.mutate({ token, device: localDevice.current as string });

    localDevice.current = null;
  }, []);

  return { unregister };
};

const usePermissions = () => {
  const { set, get } = usePersistContext();
  const { getIsReceiveGrantedAndIsDenied, requestPermission } = useNotificationContext();

  const getNotificationReminderFromPersist = async () => (await get('notificationReminder')) === 'closed';

  const persistNotificationReminder = async () => set('notificationReminder', 'closed');

  return { getIsReceiveGrantedAndIsDenied, requestPermission, getNotificationReminderFromPersist, persistNotificationReminder };
};

export { usePermissions, useRegister, useUnregister };
