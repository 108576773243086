import { QueryClient } from '@tanstack/react-query';

const makeQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        retry: 3,
        staleTime: 1000 * 60 * 5, // stale time of 5 minutes
      },
    },
  });
};

export default makeQueryClient;
