import isEqual from 'lodash/isEqual';
import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import useForm from '@/core/hooks/useForm';
import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import type { InputProps } from '@/core/types/components';

const BACKSPACE_KEY = 'Backspace';

const schema = yup.object({
  n1: yup.number().typeError('inputs.code.type').required().min(0, 'inputs.code.min').max(9, 'inputs.code.max'),
  n2: yup.number().typeError('inputs.code.type').required().min(0, 'inputs.code.min').max(9, 'inputs.code.max'),
  n3: yup.number().typeError('inputs.code.type').required().min(0, 'inputs.code.min').max(9, 'inputs.code.max'),
  n4: yup.number().typeError('inputs.code.type').required().min(0, 'inputs.code.min').max(9, 'inputs.code.max'),
  n5: yup.number().typeError('inputs.code.type').required().min(0, 'inputs.code.min').max(9, 'inputs.code.max'),
  n6: yup.number().typeError('inputs.code.type').required().min(0, 'inputs.code.min').max(9, 'inputs.code.max'),
});

interface CodeSchema {
  n1: string;
  n2: string;
  n3: string;
  n4: string;
  n5: string;
  n6: string;
}

interface CodeInputItemProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  isError: boolean;
  onPaste: React.ClipboardEventHandler<HTMLInputElement>;
}

const CodeInputItem = forwardRef<HTMLInputElement, CodeInputItemProps>(({ id, value, onChange, onBlur, isError, onPaste }, inputRef) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      ref={divRef}
      onClick={event => {
        if (divRef.current?.contains(event.target as Node)) {
          (inputRef as React.RefObject<HTMLInputElement>)?.current?.focus();
        }
      }}
      className={`relative flex h-14 w-full items-center justify-center rounded-2 border ${
        value ? 'bg-primary border-content-placeholder' : 'border-bg-secondary'
      } focus-within:bg-primary p-2 focus-within:ring-2 ${isError ? '!border-stroke-danger-focus ring-2 !ring-stroke-danger-focus/20' : ''} cursor-text p-1 focus-within:border-stroke-default-focus focus-within:bg-bg-primary focus-within:ring-stroke-default-focus/20`}
    >
      <input
        id={id}
        ref={inputRef}
        className="text-4 placeholder:text-4 placeholder:text-foreground-disable h-8 w-5 border-b border-stroke-secondary bg-transparent text-center font-semibold text-content-primary placeholder:font-semibold focus:bg-bg-primary focus:outline-none"
        value={value ?? ''}
        onChange={e => (e.target.value.length > 1 ? undefined : onChange(e.target.value))}
        onBlur={e => (e.target.value.length > 1 ? undefined : onBlur(e.target.value))}
        placeholder="0"
        onPaste={onPaste}
        autoComplete="one-time-code"
        inputMode="numeric"
      />
    </div>
  );
});

interface CodeInputProps extends InputProps<string | undefined> {}

const CodeInput: React.FC<CodeInputProps> = ({ id, value: componentValue, onChange: onComponentChange, onBlur: onComponentBlur, validation }) => {
  const { control, watch, reset, setValue } = useForm({
    schema,
    values: componentValue ? (componentValue.split('').reduce((acc, val, index) => ({ ...acc, [`n${index + 1}`]: val }), {}) as CodeSchema) : undefined,
  });

  const formData = watch();

  const actual = useMemo(() => {
    const stringFormData = Object.values(formData).join('');

    if (stringFormData === '') return undefined;

    return stringFormData;
  }, [formData]);

  useEffect(() => {
    if (!isEqual(actual, componentValue) && componentValue) {
      reset({
        ...(componentValue.split('').reduce((acc, val, index) => ({ ...acc, [`n${index + 1}`]: val }), {}) as CodeSchema),
      });
    }
  }, [componentValue]);

  const n1Ref = useRef<HTMLInputElement>(null);
  const n2Ref = useRef<HTMLInputElement>(null);
  const n3Ref = useRef<HTMLInputElement>(null);
  const n4Ref = useRef<HTMLInputElement>(null);
  const n5Ref = useRef<HTMLInputElement>(null);
  const n6Ref = useRef<HTMLInputElement>(null);

  const refs = [n1Ref, n2Ref, n3Ref, n4Ref, n5Ref, n6Ref];

  useKeyDownHandler(refs, BACKSPACE_KEY, () => {
    const activeElementIndex = refs.findIndex(ref => ref.current?.contains(document.activeElement));

    // blur the current one
    if (activeElementIndex > 0 && refs[activeElementIndex].current) {
      refs[activeElementIndex].current?.blur();
    }

    // focus the previous one
    if (activeElementIndex > 0) {
      refs[activeElementIndex - 1].current?.focus();
      setValue(`n${activeElementIndex + 1}` as keyof CodeSchema, '');
    }
  });

  useEffect(() => {
    if (actual && /^\d{0,6}$/.test(actual)) {
      const activeElementIndex = refs.findIndex(ref => ref.current?.contains(document.activeElement));

      if (activeElementIndex >= 0 && refs[activeElementIndex].current?.value !== '') {
        const nextActiveElement = refs[activeElementIndex + 1];
        if (nextActiveElement?.current) {
          nextActiveElement.current?.focus();
        }
      }

      if (onComponentChange && !isEqual(actual, componentValue)) onComponentChange(actual);

      if (onComponentBlur && !isEqual(actual, componentValue) && actual.toString().length === 6) {
        onComponentBlur(actual);
      }
    }
  }, [actual, componentValue]);

  const onPaste: React.ClipboardEventHandler<HTMLInputElement> = event => {
    const clipboardData = event.clipboardData.getData('text');

    if (/^\d{0,6}$/.test(clipboardData) && clipboardData.length <= 6) {
      clipboardData.split('').forEach((n, i) => {
        setValue(`n${i + 1}` as keyof CodeSchema, n);
      });

      const nextActiveElement = refs[clipboardData.length - 1];
      nextActiveElement.current?.focus();
    }
  };

  return (
    <div className="grid w-full grid-cols-2 gap-4 space-x-2">
      <div className="col-span-1 grid grid-cols-3 gap-2">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CodeInputItem ref={n1Ref} key={`${id}-1`} id={`${id}-1`} value={value} onChange={onChange} onBlur={onBlur} isError={validation?.isError ?? false} onPaste={onPaste} />
          )}
          name="n1"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CodeInputItem ref={n2Ref} key={`${id}-2`} id={`${id}-2`} value={value} onChange={onChange} onBlur={onBlur} isError={validation?.isError ?? false} onPaste={onPaste} />
          )}
          name="n2"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CodeInputItem ref={n3Ref} key={`${id}-3`} id={`${id}-3`} value={value} onChange={onChange} onBlur={onBlur} isError={validation?.isError ?? false} onPaste={onPaste} />
          )}
          name="n3"
        />
      </div>
      <div className="col-span-1 grid grid-cols-3 gap-2">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CodeInputItem ref={n4Ref} key={`${id}-4`} id={`${id}-4`} value={value} onChange={onChange} onBlur={onBlur} isError={validation?.isError ?? false} onPaste={onPaste} />
          )}
          name="n4"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CodeInputItem ref={n5Ref} key={`${id}-5`} id={`${id}-5`} value={value} onChange={onChange} onBlur={onBlur} isError={validation?.isError ?? false} onPaste={onPaste} />
          )}
          name="n5"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CodeInputItem ref={n6Ref} key={`${id}-6`} id={`${id}-6`} value={value} onChange={onChange} onBlur={onBlur} isError={validation?.isError ?? false} onPaste={onPaste} />
          )}
          name="n6"
        />
      </div>
    </div>
  );
};

export default CodeInput;
