import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';

import Icons from '@/core/components/Icons';
import useTextInput from '@/core/hooks/inputs/useTextInput';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import isNullish from '@/core/lib/utils/isNullish';
import type { NewInputProps } from '@/core/types/components';

interface NewTextInputProps extends NewInputProps<string> {
  type?: 'text' | 'email';
  autoComplete?: 'name' | 'email' | 'username' | 'address-level2' | 'off';
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  noCheck?: boolean;
}

const NewTextInput = forwardRef<HTMLInputElement, NewTextInputProps>((props, forwardedRef) => {
  const { t } = useTranslationContext(['common']);

  const {
    isFocused,
    isError,
    isSuccess,
    isCleanable,
    isDisabled,
    props: { type, autoComplete, inputMode, enterKeyHint },
    cleanAttrs,
    ...attrs
  } = useTextInput<NewTextInputProps>({ forwardedRef, ...props });

  const isFocusedClasses = isFocused ? 'bg-bg-primary ring-2 ring-stroke-default-focus/20 border-stroke-default-focus' : '';

  const isErrorClasses = isError ? '!border-stroke-danger-focus ring-2 !ring-stroke-danger-focus/20' : '';

  const hasRightIconClasses = isCleanable || isSuccess ? 'pr-8' : 'pr-3';

  const isDisabledClasses = isDisabled ? 'border-transparent bg-bg-pale' : '';

  const hasValue = !isNullish(attrs.value) ? 'bg-bg-primary border-content-placeholder' : '';

  return (
    <div className="relative w-full">
      <input
        className={`text-body-secondary placeholder:text-body-secondary z-0 w-full rounded-2 border border-bg-secondary bg-bg-primary py-2 pl-3 font-normal text-content-primary outline-none placeholder:font-normal placeholder:text-content-placeholder ${isFocusedClasses} ${isErrorClasses} ${hasRightIconClasses} ${isDisabledClasses} ${hasValue}`}
        {...attrs}
        type={type}
        autoComplete={autoComplete}
        inputMode={inputMode}
        enterKeyHint={enterKeyHint}
      />
      {isCleanable && (
        <button type="button" className="absolute inset-y-0 right-0 z-10 my-auto mr-3 h-5" aria-label={t('inputs.text.clean', { ns: 'common' })} {...cleanAttrs}>
          <Icons icon="close" size="20" color="placeholder" />
        </button>
      )}

      {isSuccess && !props.noCheck && (
        <div className="absolute inset-y-0 right-0 my-auto mr-3 h-5">
          <Icons icon="check" size="20" color="success" />
        </div>
      )}
    </div>
  );
});

export default NewTextInput;
