import React, { useMemo } from 'react';

import Icons from '@/core/components/Icons';
import type { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';

interface PaginationLinkProps {
  href: AbstractRoute | null;
  self?: boolean;
  content: 'first' | 'previous' | 'next' | 'last' | number;
}

const PaginationLink: React.FC<PaginationLinkProps> = ({ href, self, content }) => {
  const { Link } = useRouterContext();

  const text = useMemo(() => {
    switch (content) {
      case 'first':
        return <Icons icon="chevrons-left" size="16" color={href ? 'button-default' : 'placeholder'} weight="bold" />;
      case 'previous':
        return <Icons icon="chevron-left" size="16" color={href ? 'button-default' : 'placeholder'} weight="bold" />;
      case 'next':
        return <Icons icon="chevron-right" size="16" color={href ? 'button-default' : 'placeholder'} weight="bold" />;
      case 'last':
        return <Icons icon="chevrons-right" size="16" color={href ? 'button-default' : 'placeholder'} weight="bold" />;

      default:
        return content;
    }
  }, [content, href]);

  if (!href) {
    const roundedLeft = content === 'first';
    const roundedRight = content === 'last';

    return (
      <div
        className={`pointer-events-none inline-flex h-8 cursor-not-allowed items-center justify-center bg-bg-button-secondary-disable px-2 ${roundedLeft ? 'rounded-l-1' : ''} ${roundedRight ? 'rounded-r-1' : ''}`}
      >
        {text}
      </div>
    );
  }

  return (
    <Link
      href={href}
      shallow
      className={`text-button-large inline-flex h-8 items-center justify-center px-2 font-bold ${self ? 'default bg-bg-button-primary-default text-content-button-primary' : 'bg-bg-primary text-content-button-secondary-default'}`}
    >
      {text}
    </Link>
  );
};

export interface CataloguePaginationProps {
  total: number;
}

const CataloguePagination: React.FC<CataloguePaginationProps> = ({ total }) => {
  const params = Routes.CatalogueRoute.useParams();

  const { page = 1, size } = params.search;

  const pagination = useMemo(() => {
    const maxPage = Math.ceil(total / size);

    const pageMinus2 = page - 2;
    const pageMinus1 = page - 1;

    const pagePlus1 = page + 1;
    const pagePlus2 = page + 2;

    const links = [];

    if (pageMinus2 >= 1) {
      links.push({
        href: new Routes.CatalogueRoute({ ...params.page, ...params.search, page: pageMinus2 }),
        number: pageMinus2,
      });
    }
    if (pageMinus1 >= 1) {
      links.push({
        href: new Routes.CatalogueRoute({ ...params.page, ...params.search, page: pageMinus1 }),
        number: pageMinus1,
      });
    }

    links.push({
      href: new Routes.CatalogueRoute({ ...params.page, ...params.search }),
      number: page,
      self: true,
    });

    if (pagePlus1 <= maxPage) {
      links.push({
        href: new Routes.CatalogueRoute({ ...params.page, ...params.search, page: pagePlus1 }),
        number: pagePlus1,
      });
    }
    if (pagePlus2 <= maxPage) {
      links.push({
        href: new Routes.CatalogueRoute({ ...params.page, ...params.search, page: pagePlus2 }),
        number: pagePlus2,
      });
    }

    const first = page === 1 ? null : new Routes.CatalogueRoute({ ...params.page, ...params.search, page: undefined });
    const previous = page === 1 ? null : new Routes.CatalogueRoute({ ...params.page, ...params.search, page: page - 1 });

    const next = page === maxPage ? null : new Routes.CatalogueRoute({ ...params.page, ...params.search, page: page + 1 });
    const last = page === maxPage ? null : new Routes.CatalogueRoute({ ...params.page, ...params.search, page: maxPage });

    return {
      first,
      previous,
      next,
      last,
      links,
    };
  }, [page, size, total, params]);

  if (!total) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-end">
      <PaginationLink href={pagination.first} content="first" />
      <PaginationLink href={pagination.previous} content="previous" />
      {pagination.links.map(link => {
        return <PaginationLink key={link.number} href={link.href} self={link.self} content={link.number} />;
      })}
      <PaginationLink href={pagination.next} content="next" />
      <PaginationLink href={pagination.last} content="last" />
    </div>
  );
};

export default CataloguePagination;
