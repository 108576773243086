// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAB0h4hBqKxP_NMFS4vgMmhNDQwyRPWBMo',
  authDomain: 'donnons-push.firebaseapp.com',
  projectId: 'donnons-push',
  storageBucket: 'donnons-push.appspot.com',
  messagingSenderId: '9496264515',
  appId: '1:9496264515:web:5769f2041fd16e845079f6',
  measurementId: 'G-2ZMYVMXHDY',
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
