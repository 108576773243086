import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Clock: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path
      d="M20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33337 20 3.33337C10.7952 3.33337 3.33331 10.7953 3.33331 20C3.33331 29.2048 10.7952 36.6667 20 36.6667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 10V20L26.6667 23.3333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Clock;
