import { useEffect, useMemo } from 'react';

import ConversationDomain from '@/core/lib/new-architecture/domain/conversation.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { InfiniteStoreData } from '@/core/lib/new-architecture/store/store.types';
import type { UserResult } from '@/core/types/users';

const useConversation = (id: number | undefined): InfiniteStoreData<ConversationDomain> => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate } = Query.conversations.useInvalidate();

  const { data: current, isLoading: currentIsLoading } = Store.current.useCurrent();

  const {
    data: pages,
    isLoading: conversationIsLoading,
    isSuccess: conversationIsSuccess,
    isError: conversationIsError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isSuccess,
  } = Query.conversation.useConversation({ data: { id }, token: requestToken, enabled: !!current });

  useEffect(() => {
    if (isSuccess) {
      invalidate();
    }
  }, [isSuccess]);

  const isLoading = useMemo(() => {
    if (Number.isNaN(id)) return false;

    if (!current) return true;

    if (conversationIsLoading || currentIsLoading) return true;

    if (current && !conversationIsError && !conversationIsSuccess) return false;

    return false;
  }, [id, current, conversationIsLoading, currentIsLoading, conversationIsError, conversationIsSuccess]);

  const data = useMemo(() => {
    if (!pages || !current) {
      return null;
    }

    const isWinner = pages.pages[0].is_winner;
    const conv = pages.pages[0];
    const messages = pages.pages.flatMap(page => page.messages);
    const you = pages.pages[0].users?.find(user => user.uuid === (current.uuid === conv.conversation.giver ? conv.conversation.taker : conv.conversation.giver)) as UserResult;

    return new ConversationDomain({ data: conv, messages, you, current, isWinner });
  }, [pages, current]);

  return {
    data,
    isLoading,
    notFound: false,
    isFetchingNextPage,
    hasNext: hasNextPage,
    total: pages?.pages.reduce((acc, page) => acc + page.messages.length, 0) ?? 0,
    next: () => fetchNextPage(),
  };
};

export default { useConversation };
