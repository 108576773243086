import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import type { Control, FieldError, UseFormGetValues, UseFormReset, UseFormSetFocus, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useForm as useRHFForm } from 'react-hook-form';
import type { AnyObjectSchema } from 'yup';

import useMutation from '@/core/hooks/useMutation';
import type { APIDefinition, APIError } from '@/core/lib/fetch';
import type { DeepPartial } from '@/core/lib/types';
import isNullish from '@/core/lib/utils/isNullish';
import type { InputValidation } from '@/core/types/components';
import type { ErrorResult } from '@/core/types/donnons';
import { codeToTransKey, errorResultToTransKey } from '@/core/types/donnons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyValues = DeepPartial<{ [x: string]: any }>;

interface Transformers<TData extends AnyValues, TForm extends AnyValues> {
  apiToForm?: (data?: DeepPartial<TData>) => DeepPartial<TForm>;
  formToApi?: (data: TForm) => TData;
}

interface UseFormParams<TRes, TData extends AnyValues, TForm extends AnyValues = TData> {
  schema: AnyObjectSchema;
  resSchema?: AnyObjectSchema;
  apiDefinition?: APIDefinition<TData>;
  mutationFn?: (data: TData, token?: string) => Promise<TRes>;
  values?: DeepPartial<TData>;
  transformers?: Transformers<TData, TForm>;
  onSuccess?: (data: TRes, variables: TData) => void;
  onError?: (error: APIError, variables: TData) => void;
  orderKeysForm?: string[];
  mode?: 'onSubmit' | 'onBlur' | 'onChange';
}

type FormErrors<TData extends AnyValues> = {
  [key in keyof TData]-?: InputValidation;
} & { global: InputValidation };

interface UseFormReturn<TForm extends AnyValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<TForm, any>;
  onSubmit: () => void;
  errors: FormErrors<TForm>;
  onCleanGlobalError: () => void;
  isDirty: boolean;
  isValid: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  setValue: UseFormSetValue<TForm>;
  watch: UseFormWatch<TForm>;
  reset: UseFormReset<TForm>;
  setFocus: UseFormSetFocus<TForm>;
  getValues: UseFormGetValues<TForm>;
}

const useForm = <TRes, TData extends AnyValues, TForm extends AnyValues = TData>({
  schema,
  resSchema,
  values,
  transformers,
  apiDefinition,
  mutationFn,
  onSuccess,
  onError,
  orderKeysForm,
  mode = 'onBlur',
}: UseFormParams<TRes, TData, TForm>): UseFormReturn<TForm> => {
  const [apiError, setApiError] = useState<APIError | null>(null);
  const onCleanGlobalError = () => setApiError(null);

  const onLocalError = (error: APIError, variables: TData) => {
    setApiError(error);
    if (onError) onError(error, variables);
  };

  const mutation = useMutation<TRes, TData>({
    apiDefinition,
    mutationFn,
    onSuccess,
    onError: onLocalError,
    schema: resSchema,
  });

  useEffect(() => {
    if (orderKeysForm && schema) {
      const schemaKeys = Object.keys(schema.fields);
      orderKeysForm.forEach(orderKey => {
        if (!schemaKeys.includes(orderKey)) {
          throw new Error(`[Order key not valid]: ${orderKey} is not in schema validation`);
        }
      });
    }
  }, [orderKeysForm, schema]);

  const transformedValues = useMemo(() => {
    return transformers && transformers.apiToForm ? transformers.apiToForm(values) : (values as unknown as TForm);
  }, [values, transformers?.apiToForm]);

  const transformedValuesOrdered = useMemo(() => {
    if (orderKeysForm && transformedValues) {
      const orderedValues = {} as TForm;
      orderKeysForm.forEach(orderKey => {
        Object.assign(orderedValues, {
          [orderKey]: transformedValues[orderKey],
        });
      });
      return { ...orderedValues, ...transformedValues };
    }
    return transformedValues;
  }, [transformedValues, orderKeysForm]);

  const {
    control,
    handleSubmit,
    formState: { errors: formStateErrors, isDirty, isValid },
    trigger: reactHookFormTrigger,
    setValue: reactHookFormSetValue,
    watch,
    reset,
    setFocus,
    getValues,
  } = useRHFForm<TForm>({
    mode,
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    values: transformedValuesOrdered as TForm,
  });

  const watchData = watch();

  // at form init run a validation on all the form to be sure that any previously data that has been
  // recorded is valid otherwise the form would appear invalid but the error would not show
  // we also trigger the validation only if the object is not empty because for an empty form
  // it would not make sense
  useEffect(() => {
    if (!isEmpty(values)) {
      const trigger = async () => reactHookFormTrigger();
      trigger();
    }
  }, [reactHookFormTrigger, schema]);

  const onSubmit = (data: TForm) => {
    // we know that if there is no transform formToApi then TForm is TData
    const transformedData = transformers && transformers.formToApi ? transformers.formToApi(data) : (data as unknown as TData);
    mutation.mutate(transformedData);
  };

  const errors = Object.keys(schema.fields).reduce((acc, key) => {
    if (apiError?.json?.errors) {
      const apiErrorForKey = apiError.json.errors[key];

      if (apiErrorForKey) {
        return {
          ...acc,
          [key]: {
            isError: true,
            message: codeToTransKey(apiErrorForKey.code),
            code: apiErrorForKey.code,
          },
        };
      }
    }

    const formError = formStateErrors[key];

    if (!formError) {
      return { ...acc, [key]: { isError: false, isSuccess: !isNullish(watchData[key]) } };
    }

    return { ...acc, [key]: { isError: true, code: 'yup', message: formError.message ?? (formError as { [key: string]: FieldError })[Object.keys(formError)[0]].message ?? null } };
  }, {} as FormErrors<TForm>);

  // we only want a global error when there is no field error
  const global =
    apiError && !Object.values(errors).some(e => e.isError === true)
      ? {
          isError: true,
          ...errorResultToTransKey(apiError.json as ErrorResult),
        }
      : { isError: false };

  const setValue: UseFormSetValue<TForm> = (name, value) => {
    reactHookFormSetValue(name, value);
    reactHookFormTrigger(name);
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    errors: { ...errors, global },
    onCleanGlobalError,
    isDirty,
    isValid,
    isLoading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    setValue,
    watch,
    reset,
    setFocus,
    getValues,
  };
};

export default useForm;
