import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';

interface LabelProps extends PropsWithChildren {
  htmlFor: string;
  content?: string;
  disabled?: boolean;
}

const BASE_LABEL_CLASSES = 'flex flex-col gap-1 text-content-primary text-body-secondary font-medium';

const Label: React.FC<LabelProps> = ({ htmlFor, children, disabled, content }) => {
  const computedClasses = useMemo(() => {
    const disabledClass = disabled ? 'text-content-disable' : '';
    return disabledClass;
  }, [disabled]);

  return (
    <label htmlFor={htmlFor} aria-labelledby={`${htmlFor}-content`} className={`${BASE_LABEL_CLASSES} ${computedClasses}`}>
      {content && <span id={`${htmlFor}-content`}>{content}</span>}
      {children}
    </label>
  );
};

export default Label;
