import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import Toast from '@/core/components/Toast';
import type { ToastStatus } from '@/core/components/Toast/Toast';
import { useDocumentContext } from '@/core/lib/document/document.context';
import { ToastContext } from '@/core/lib/toast/toast.context';

export interface ToastState {
  id: string;
  content: string;
  delay: number;
  status: ToastStatus;
}
// Create a random ID
const generateID = () => {
  let first = (Math.random() * 46656).toString();
  let second = (Math.random() * 46656).toString();
  first = `000${first.slice(-3)}`;
  second = `000${second.slice(-3)}`;

  return first + second;
};

const ToastProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastState[]>([]);
  const { defineDocument } = useDocumentContext();
  const open = (content: string, delay: number, status: ToastStatus) => {
    setToasts(currentToasts => [...currentToasts, { id: generateID(), content, delay, status }]);
  };

  const close = (id: string) => setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id));
  const value = useMemo(() => ({ open }), []);
  if (!defineDocument) {
    return (
      <>
        {children}
        <div className="fixed left-1/2 top-20 z-50 mb-1 w-full -translate-x-1/2 space-y-1 px-1 lg:w-82" />
      </>
    );
  }
  return (
    <ToastContext.Provider value={value}>
      {children}
      {createPortal(
        <div className="fixed left-1/2 top-20 z-50 mb-1 w-full -translate-x-1/2 space-y-1 px-1 lg:w-82">
          {toasts.map(toast => (
            <Toast key={toast.id} close={() => close(toast.id)} delay={toast.delay} content={toast.content} toastStatus={toast.status} />
          ))}
        </div>,
        defineDocument.body,
      )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
