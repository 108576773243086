import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import makeClone from '@/core/lib/makeClone';

export interface ScrollContextType {
  get: (id: string) => number | undefined;
  set: (id: string, data: number) => void;
}

export const ScrollContext = createContext<ScrollContextType | null>(null);

export const useScrollContext = () => useContext(ScrollContext) as ScrollContextType;

const ScrollProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [scrollMap, setScrollMap] = useState<Map<string, number>>(new Map([]));

  const get = useCallback(
    (id: string) => {
      return scrollMap.get(id);
    },
    [scrollMap],
  );

  const set = useCallback(
    (id: string, data: number) => {
      setScrollMap(prev => {
        const clone = makeClone(prev);
        clone.set(id, data);
        return clone;
      });
    },
    [scrollMap],
  );

  const value = useMemo(() => ({ get, set }), [scrollMap]);

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export default ScrollProvider;
