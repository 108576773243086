import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const CornerDownRight: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M25 16.6666L33.3333 25L25 33.3333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66675 6.66663V18.3333C6.66675 20.1014 7.36913 21.7971 8.61937 23.0473C9.86961 24.2976 11.5653 25 13.3334 25H33.3334" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CornerDownRight;
