import React, { useEffect, useState } from 'react';

import { useCatalogueSearchContext } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import Header from '@/core/components/Header';
import Modal from '@/core/components/Modal';
import useDisclosure from '@/core/hooks/useDisclosure';
import type { ModalValues } from '@/core/lib/new-architecture/domain/circular.domain';
import Store from '@/core/lib/new-architecture/store';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const CatalogueSearchCircularModal: React.FC = () => {
  const { t } = useTranslationContext(['common']);

  const { data } = useCatalogueSearchContext();

  const { data: values } = Store.circular.useCircular(data);

  const { isOpen, onOpen, onClose } = useDisclosure(true);

  const [display, setDisplay] = useState<ModalValues | null>(values);

  useEffect(() => {
    setDisplay(values);
    onOpen();
  }, [values]);

  const onHandleClose = () => {
    setDisplay(null);
    onClose();
  };

  if (!values || !display) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onHandleClose}>
      <div>
        <Header content="" iconAfter="close" onAfter={onHandleClose} />
        <div className="flex flex-col gap-4 px-4 pb-7 md:px-7">
          <img src={display.logo} alt={display.alt} className="h-15 object-contain" />
          <p className="text-title text-center font-semibold text-content-secondary">{t('search.modal.welcome', { ns: 'common' })}</p>
          <p className="text-body-primary whitespace-pre-wrap text-pretty text-center font-normal text-content-primary">{display.text}</p>
        </div>
      </div>
    </Modal>
  );
};

export default CatalogueSearchCircularModal;
