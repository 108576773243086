import useMutation from '@/core/hooks/api/useMutation';
import { type APIError, ErrorKind } from '@/core/lib/fetch';
import Api from '@/core/lib/new-architecture/api';
import type { ModifyConversationsState } from '@/core/lib/new-architecture/api/conversations.api';
import Query from '@/core/lib/new-architecture/query';
import getToken from '@/core/lib/new-architecture/query/token';
import Store from '@/core/lib/new-architecture/store';

const useState = () => {
  const { requestToken } = Store.auth.useRequest();
  const { invalidate: invalidateConversation } = Query.conversation.useInvalidate();
  const { invalidate: invalidateConversations } = Query.conversations.useInvalidate();

  const mutation = useMutation<Awaited<ReturnType<typeof Api.mutation.conversations.state>>, APIError, ModifyConversationsState>({
    mutationFn: async (data: ModifyConversationsState) => Api.mutation.conversations.state(data, await getToken(requestToken)),
    onSuccess: () => {
      invalidateConversation();
      invalidateConversations();
    },
  });

  const onUpdateState = async (data: ModifyConversationsState) => {
    try {
      await mutation.mutateAsync(data);
    } catch (err) {
      const error = err as APIError;
      if (error.kind === ErrorKind.Forbidden) {
        await invalidateConversation();
      }

      throw err;
    }
  };

  return { onUpdateState, isLoading: mutation.isPending };
};

export { useState };
