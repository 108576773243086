import type { DehydratedState } from '@tanstack/react-query';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import makeQueryClient from '@/core/lib/query/queryClient';

export const QUERY_KEYS = {
  categories: ['/categories'] as const,
  geolocationReverse: (lat: number | undefined, lon: number | undefined) => ['geolocation-reverse', `${lat}`, `${lon}`] as const,
  geolocation: (search: string, size: string) => ['geolocation', search, size] as const,
  baseToken: ['/token'] as const,
  tokenRenew: (token: string) => [...QUERY_KEYS.baseToken, 'renew', token] as const,
  cgu: ['/cgu'] as const,
  baseDonation: ['/donation'] as const,
  baseDonations: ['/donations'] as const,
  donation: (id: string) => [...QUERY_KEYS.baseDonation, `/${id}`] as const,
  takers: (donationId: string) => [...QUERY_KEYS.donation(donationId), '/takers'],
  donations: (params: string[]) => [...QUERY_KEYS.baseDonations, ...params] as const,
  baseUser: ['/user'] as const,
  user: () => [...QUERY_KEYS.baseUser] as const,
  userControl: () => [...QUERY_KEYS.baseUser, '/control'] as const,
  userPublic: (uuid: string) => [...QUERY_KEYS.baseUser, `/${uuid}`] as const,
  baseUserDonation: ['/user', '/donation'] as const,
  baseUserDonations: ['/user', '/donations'] as const,
  userDonation: (donationId: string) => [...QUERY_KEYS.baseUserDonation, `/${donationId}`] as const,
  userDonations: (params: string[]) => [...QUERY_KEYS.baseUserDonations, ...params] as const,
  baseConversations: ['/conversations'] as const,
  conversations: (params: string) => [...QUERY_KEYS.baseConversations, params] as const,
  baseConversation: ['/conversation'] as const,
  conversation: (id: number, params: string[]) => [...QUERY_KEYS.baseConversation, `/${id}`, ...params] as const,
  baseAuth: ['/auth'] as const,
  authGoogle: () => [...QUERY_KEYS.baseAuth, '/google'],
  authFacebook: () => [...QUERY_KEYS.baseAuth, '/facebook'],
  authApple: () => [...QUERY_KEYS.baseAuth, '/apple'],
};

export const INVALIDATION_KEYS = {
  user: [QUERY_KEYS.baseUser],
  donations: [QUERY_KEYS.baseDonation, QUERY_KEYS.baseDonations, QUERY_KEYS.baseUserDonation, QUERY_KEYS.baseUserDonations],
  tokens: [QUERY_KEYS.baseToken],
  baseConversations: [QUERY_KEYS.baseConversations],
  conversations: [QUERY_KEYS.baseConversation, QUERY_KEYS.baseConversations],
  auth: [QUERY_KEYS.baseAuth],
};

type InvalidationKey = (typeof INVALIDATION_KEYS)[keyof typeof INVALIDATION_KEYS];

export interface QueryContextType {
  invalidate: (invalidationKey: InvalidationKey) => Promise<void>;
  reset: () => Promise<void>;
  contextRetry: number;
}

export const QueryContext = createContext<QueryContextType | null>(null);

export const useQueryContext = () => useContext(QueryContext) as QueryContextType;

interface QueryProviderProps extends React.PropsWithChildren {
  dehydratedState?: DehydratedState;
}

const QueryProvider: React.FC<QueryProviderProps> = ({ children, dehydratedState }) => {
  const [queryClient] = useState(() => makeQueryClient());

  const invalidate = useCallback(
    async (invalidationKey: InvalidationKey) => {
      await Promise.all(invalidationKey.map(key => queryClient.invalidateQueries({ queryKey: key })));
    },
    [queryClient],
  );

  const reset = useCallback(async () => {
    await queryClient.resetQueries();
  }, [queryClient]);

  const value = useMemo(() => ({ invalidate, reset, contextRetry: 0 }), []);

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>
        <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
      </HydrationBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export const makeWrapper = () => {
  const wrapper: React.FC<QueryProviderProps> = ({ children }) => {
    const [queryClient] = useState(
      () =>
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnMount: false,
              refetchOnWindowFocus: false,
              retry: 0,
              staleTime: Infinity,
            },
          },
        }),
    );

    const invalidate = useCallback(
      async (invalidationKey: InvalidationKey) => {
        await Promise.all(invalidationKey.map(key => queryClient.invalidateQueries({ queryKey: key })));
      },
      [queryClient],
    );

    const reset = useCallback(async () => {
      await queryClient.resetQueries();
    }, [queryClient]);

    const value = useMemo(() => ({ invalidate, reset, contextRetry: 0 }), []);

    return (
      <QueryClientProvider client={queryClient}>
        <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
      </QueryClientProvider>
    );
  };

  return { wrapper };
};

export default QueryProvider;
