import { createContext, useContext } from 'react';

import type { PointResult } from '@/core/types/geo';

export interface LocationPermissionError {
  error: string;
}

export interface GeolocationContextType {
  coordinates: () => Promise<PointResult | LocationPermissionError>;
}

export const GeolocationContext = createContext<GeolocationContextType | null>(null);

export const useGeolocationContext = () => useContext(GeolocationContext) as GeolocationContextType;
