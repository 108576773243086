import type { ErrorResult } from '@/core/types/donnons';

export enum ErrorKind {
  BadRequest = 'BAD_REQUEST',
  Unauthorized = 'UNAUTHORIZED',
  Forbidden = 'FORBIDDEN',
  NotFound = 'NOT_FOUND',
  Internal = 'INTERNAL',
  Unknown = 'UNKNOWN',
  Unavailable = 'UNAVAILABLE',
}

export class APIError<TError = ErrorResult> extends Error {
  private response?: Response;

  public json?: TError;

  public kind: ErrorKind = ErrorKind.Unknown;

  public transKey: string = 'errors.unknown';

  constructor(response?: Response, json?: TError) {
    super();
    this.response = response;
    this.json = json;

    if (!this.response) {
      this.kind = ErrorKind.Unknown;
      this.transKey = 'errors.unknown';
    } else {
      switch (this.response.status) {
        case 400:
          this.kind = ErrorKind.BadRequest;
          this.transKey = 'errors.bad-request';
          break;

        case 401:
          this.kind = ErrorKind.Unauthorized;
          this.transKey = 'errors.unauthorized';
          break;

        case 403:
          this.kind = ErrorKind.Forbidden;
          this.transKey = 'errors.forbidden';
          break;

        case 404:
          this.kind = ErrorKind.NotFound;
          this.transKey = 'errors.not-found';
          break;

        case 500:
          this.kind = ErrorKind.Internal;
          this.transKey = 'errors.internal';
          break;

        case 503:
          this.kind = ErrorKind.Unavailable;
          this.transKey = 'errors.unavailable';
          break;

        default:
          this.kind = ErrorKind.Unknown;
          this.transKey = 'errors.unknown';
          break;
      }
    }
  }
}

export const get = async <TRes, TError = void>(url: string, token?: string | null) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    });

    if (response.status === 204) {
      return null as TRes;
    }

    const json = await response.json();

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

export const post = async <TRes, TData, TError = void>(url: string, data?: TData, token?: string | null) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    });

    if (response.status === 204) {
      return null as TRes;
    }

    const json = await response.json();

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

export const image = async <TRes, TData extends FormData, TError = void>(url: string, data?: TData, token?: string | null) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: data,
      headers: {
        Accept: 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    });

    if (response.status === 204) {
      return null as TRes;
    }

    const json = await response.json();

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

export const put = async <TRes, TData, TError = void>(url: string, data?: TData, token?: string | null) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    });

    if (response.status === 204) {
      return null as TRes;
    }

    const json = await response.json();

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

export const patch = async <TRes, TData, TError = void>(url: string, data?: TData, token?: string | null) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    });

    if (response.status === 204) {
      return null as TRes;
    }

    const json = await response.json();

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

export const destroy = async <TRes, TData, TError = void>(url: string, data?: TData, token?: string | null) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    });

    if (response.status === 204) {
      return null as TRes;
    }

    const json = await response.json();

    if (response.status >= 400) {
      throw new APIError<TError>(response, json);
    }

    return json as TRes;
  } catch (err) {
    if ((err as Error).constructor === APIError) {
      throw err;
    }
    throw new APIError();
  }
};

export enum ApiVersions {
  V1 = '/v1',
}

export enum ApiMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface APIDefinition<TData = void> {
  method: ApiMethods;
  url: string;
  data?: TData;
}

export const api = async <TRes, TData = void, TError = void>(apiDefinition: APIDefinition<TData>, token?: string) => {
  switch (apiDefinition.method) {
    case ApiMethods.GET:
      return get<TRes, TError>(apiDefinition.url, token);

    case ApiMethods.POST:
      return post<TRes, TData, TError>(apiDefinition.url, apiDefinition.data, token);

    case ApiMethods.PUT:
      return put<TRes, TData, TError>(apiDefinition.url, apiDefinition.data, token);

    case ApiMethods.PATCH:
      return patch<TRes, TData, TError>(apiDefinition.url, apiDefinition.data, token);
    case ApiMethods.DELETE:
      return destroy<TRes, TData, TError>(apiDefinition.url, apiDefinition.data, token);

    default:
      throw new Error(`API: method not implemented. Implemented methods are ${ApiMethods.GET}, ${ApiMethods.POST}, ${ApiMethods.PUT}, ${ApiMethods.PATCH}`);
  }
};
