import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { ApiVersions } from '@/core/lib/fetch';
import api from '@/core/lib/fetch/api';

const getUrl = () => valuesFromProcess[ACTUAL_ENV as Environment]?.DONNONS_API_URL;

const mutation = {
  register: async (id: string, token: string) =>
    api.put<null, { id: string }>({
      url: `${getUrl()}${ApiVersions.V1}/user/device`,
      token,
      data: { id },
    }),
  unregister: async (id: string, token: string) =>
    api.destroy<null>({
      url: `${getUrl()}${ApiVersions.V1}/user/device?id=${id}`,
      data: undefined,
      token,
    }),
};

export default { mutation };
