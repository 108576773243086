import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import type { Query } from '@/core/lib/new-architecture/query/types.query';
import type { DonationsCategoriesResult } from '@/core/types/donation';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/categories'] as const,
  suggestions: (donationTitle?: string) => [keys.base, donationTitle] as const,
};

const useCategories: Query<DonationsCategoriesResult> = () => {
  return useQuery({
    queryKey: keys.base,
    queryFn: () => Api.query.categories.get(),
  });
};

useCategories.prefetch = async ({ queryClient }) => {
  await queryClient.prefetchQuery({
    queryKey: keys.base,
    queryFn: () => Api.query.categories.get(),
  });
};

interface CategoriesSuggestionsParams {
  donationTitle?: string;
}

const useCategoriesSuggestions: Query<Awaited<ReturnType<typeof Api.query.categories.suggestions>>, CategoriesSuggestionsParams> = ({ data: { donationTitle } }) =>
  useQuery({
    queryKey: keys.suggestions(donationTitle),
    queryFn: () => Api.query.categories.suggestions(donationTitle),
    enabled: !!donationTitle,
  });

export default { useCategories, useCategoriesSuggestions };
