import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';

import type { InputProps } from '@/core/types/components';

interface CheckboxInputProps extends InputProps<boolean>, PropsWithChildren {}

const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(({ id, value, onChange, children }, ref) => {
  const onClick = () => {
    if (onChange) onChange(!value);
  };

  return (
    <label htmlFor={id} className="group flex cursor-pointer items-center gap-3 py-1">
      <input id={id} type="checkbox" className="sr-only size-6" aria-label={`${id}-span`} aria-labelledby={`${id}-span`} onClick={onClick} ref={ref} />
      <div
        className={`flex size-6 items-center justify-center rounded-[2.67px] border border-stroke-button-primary-disable bg-bg-primary group-hover:border-stroke-button-primary-default ${value && 'border-stroke-button-primary-default !bg-bg-button-primary-default'}`}
      >
        {value && (
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.55545 3.33337L4.66656 8.22226L2.44434 6.00004" stroke="white" strokeWidth="1.11333" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}
      </div>
      <span id={`${id}-span`} className="text-body-secondary w-full select-none text-left text-content-placeholder">
        {children}
      </span>
    </label>
  );
});

export default CheckboxInput;
