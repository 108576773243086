import { useQueryClient } from '@tanstack/react-query';

import useQuery from '@/core/hooks/api/useQuery';
import Api from '@/core/lib/new-architecture/api';
import getToken from '@/core/lib/new-architecture/query/token';
import type { Query } from '@/core/lib/new-architecture/query/types.query';

const keys = {
  base: ['KEY_TO_PREVENT_COLLISIONS', '/donations', '/followed'] as const,
};

const useFollowedDonations: Query<Awaited<ReturnType<typeof Api.query.followedDonations.get>>> = ({ token, enabled }) => {
  return useQuery({
    queryKey: keys.base,
    queryFn: async () => Api.query.followedDonations.get(await getToken(token)),

    enabled,
  });
};

const useInvalidate = () => {
  const queryClient = useQueryClient();
  const invalidate = () => queryClient.invalidateQueries({ queryKey: keys.base });
  return { invalidate };
};

export default { useFollowedDonations, useInvalidate };
