import React from 'react';

import type { IconColor, IconName } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import useTimeout from '@/core/hooks/useTimeout';

export enum ToastStatus {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}

interface ToastProps {
  close: () => void;
  delay: number;
  content: string;
  toastStatus: ToastStatus;
}

const Toast: React.FC<ToastProps> = ({ close, delay, content, toastStatus }) => {
  useTimeout(close, delay);
  let icon: IconName;
  let iconColor: IconColor;
  let borderColor: string;
  switch (toastStatus) {
    case 'success':
      icon = 'check-circle';
      iconColor = 'success';
      borderColor = 'border-content-success';
      break;
    case 'danger':
      icon = 'alert-danger';
      iconColor = 'danger';
      borderColor = 'border-content-danger';
      break;
    case 'info':
      icon = 'info';
      iconColor = 'brand';
      borderColor = 'border-[#6F71F1]';
      break;
    case 'warning':
      icon = 'alert-danger';
      iconColor = 'moderation';
      borderColor = 'border-content-tag-status-moderation';
      break;
    default:
      icon = 'check-circle';
      iconColor = 'success';
      borderColor = 'border-content-success';
      break;
  }

  return (
    <div className={`relative flex cursor-pointer items-center justify-start gap-2 border-b-2 border-solid bg-bg-primary px-3 py-4 lg:gap-3 lg:px-4 lg:py-5 ${borderColor}`}>
      <div className="shrink-0">
        <Icons size="20" icon={icon} color={iconColor} />
      </div>
      <p className="text-caption-primary font-semibold text-content-primary">{content}</p>

      <div className="absolute right-1 top-1">
        <Icons size="16" icon="close" onClick={close} color="current" />
      </div>
    </div>
  );
};

export default Toast;
