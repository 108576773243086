import type { BreadcrumbList, WithContext } from 'schema-dts';

import type { Environment } from '@/core/lib/env/env.context';
import { ACTUAL_ENV, valuesFromProcess } from '@/core/lib/env/env.context';
import { SEMVER_REGEX } from '@/core/lib/regex';
import type { TFunction } from '@/core/lib/translation/translation.context';
import type { HomeResult } from '@/core/types/home';

export interface HomeInterface {
  getUserAccountsNumber: () => number;
  getBreadcrumbListSchema(t: TFunction): WithContext<BreadcrumbList>;
}

interface HomeConstructor {
  data: HomeResult;
}

class HomeDomain implements HomeInterface {
  private data: HomeResult;

  constructor({ data }: HomeConstructor) {
    this.data = data;
  }

  public getRewardData() {
    const { reward_is_active, reward_trigger_after } = this.data;
    return { reward_is_active, reward_trigger_after };
  }

  public getUserAccountsNumber() {
    return this.data.nb_users_created;
  }

  public isVersionCompatible(platform: 'web' | 'ios' | 'android' = 'web', currentVersion: string = 'local'): boolean {
    if (platform === 'web') return true;

    if (currentVersion === 'local') return true;

    const latestGroups = this.data[platform === 'ios' ? 'apple_store' : 'play_store'].match(SEMVER_REGEX)?.groups;
    const currentGroups = currentVersion.match(SEMVER_REGEX)?.groups;

    if (!latestGroups || !currentGroups) {
      return false;
    }

    if (Number(currentGroups.major) < Number(latestGroups.major)) {
      return false;
    }

    if (Number(currentGroups.minor) < Number(latestGroups.minor) - 3) {
      return false;
    }

    return true;
  }

  public getBreadcrumbListSchema(t: TFunction): WithContext<BreadcrumbList> {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': `${valuesFromProcess[ACTUAL_ENV as Environment]?.BASE_URL}`,
            name: t('schema.breadcrumb.home', { ns: 'common' }),
          },
        },
      ],
    };
  }
}

export default HomeDomain;
