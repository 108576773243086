import React, { useState } from 'react';

import EmailErrorControlCheck from '@/core/components/UserControl/controls/EmailErrorControl/EmailErrorControlCheck';
import EmailErrorControlCode from '@/core/components/UserControl/controls/EmailErrorControl/EmailErrorControlCode';
import EmailErrorControlEditMail from '@/core/components/UserControl/controls/EmailErrorControl/EmailErrorControlEditMail';

enum EmailErrorStep {
  STEP_1 = 'check',
  STEP_2 = 'editMail',
  STEP_3 = 'codeConfirmation',
}

const EmailErrorControl: React.FC = () => {
  const [step, setStep] = useState<EmailErrorStep>(EmailErrorStep.STEP_1);
  const [newMail, setNewMail] = useState<string>();

  let onPrevious = () => {};
  let onNext = () => {};

  switch (step) {
    case EmailErrorStep.STEP_1:
      onNext = () => setStep(EmailErrorStep.STEP_2);
      return <EmailErrorControlCheck onNext={onNext} />;

    case EmailErrorStep.STEP_2:
      onPrevious = () => setStep(EmailErrorStep.STEP_1);
      onNext = () => setStep(EmailErrorStep.STEP_3);
      return <EmailErrorControlEditMail onPrevious={onPrevious} onNext={onNext} setNewMail={setNewMail} />;

    case EmailErrorStep.STEP_3:
      return <EmailErrorControlCode newMail={newMail} />;

    default:
      return null;
  }
};

export default EmailErrorControl;
