import React, { forwardRef, useMemo } from 'react';

import Button from '@/core/components/Button';
import Header from '@/core/components/Header';
import Icons from '@/core/components/Icons';
import LocateMeButton from '@/core/components/inputs/CityInput/LocateMeButton';
import { NewTextInput } from '@/core/components/inputs/TextInput';
import RadiusLoc from '@/core/components/RadiusLoc';
import ScrollDownItem from '@/core/components/ScrollDownItem';
import Separator from '@/core/components/Separator';
import useGeoInput from '@/core/hooks/inputs/useGeoInput';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import { useNativeContext } from '@/core/lib/native/native.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { NewInputProps } from '@/core/types/components';
import type { LocationForm } from '@/core/types/geo';

export interface CityInputProps extends NewInputProps<LocationForm | null> {
  isFloating?: boolean;
  type: 'search' | 'limited';
  title?: string;
}

const CityInput = forwardRef<HTMLInputElement, CityInputProps>(({ isFloating = true, ...props }, forwardedRef) => {
  const { t } = useTranslationContext(['common']);
  const { isNative } = useNativeContext();

  const {
    id,
    isOpen,
    isSearching,
    isLoading,
    isStageAndSelectedEqual,
    onOpen,
    onClose,
    items,
    onSelect,
    onSearch,
    onClean,
    onChangeDistance,
    selectedItem,
    stagedItem,
    search,
    userItem,
    refs: { inputRef, divRef },
    ...rest
  } = useGeoInput({ ...props, forwardedRef });

  const isLg = useIsLg();

  const mobileModalClasses = isOpen ? 'fixed top-0 left-0 h-screen w-screen z-50 bg-bg-primary px-4' : '';
  const dropdownClasses = `${isFloating ? 'lg:absolute' : ''} ${
    isOpen ? 'flex' : 'hidden'
  } lg:top-12 z-20 h-full lg:h-auto lg:max-h-select w-full min-w-67 flex-col overflow-auto rounded-2 bg-bg-primary space-y-2 py-3 lg:p-3 lg:shadow-md lg:flex`;

  const onSubmit = () => {
    if (isStageAndSelectedEqual) {
      onClose();
    } else {
      props.onChange?.(stagedItem?.getLocationForm() ?? null);
      props.onBlur?.(stagedItem?.getLocationForm() ?? null);
      onClose();
    }
  };

  const selectedLabel = selectedItem?.getLabel();
  const selectedDistance = selectedItem?.getLocationFormDistance();

  const stagedLabel = stagedItem?.getLabel();
  const stagedDistance = stagedItem?.getLocationFormDistance();

  const hasRadius = (stagedItem || selectedItem)?.hasRadius();

  const { inputValue, inputPlaceholder } = useMemo(() => {
    const label = stagedLabel ?? selectedLabel ?? '';
    const distance = stagedDistance ?? selectedDistance;

    if (!selectedItem && !stagedItem) {
      return {
        inputPlaceholder: props.placeholder ?? '',
        inputValue: '',
      };
    }

    let value = label;
    if (hasRadius && distance !== undefined && props.type === 'search') {
      value += ` - ${distance} km`;
    }

    if (isOpen) {
      return {
        inputValue: isStageAndSelectedEqual ? search : label,
        inputPlaceholder: value,
      };
    }

    return {
      inputValue: value,
      inputPlaceholder: value,
    };
  }, [isOpen, selectedLabel, selectedDistance, stagedLabel, stagedDistance, props.placeholder, isStageAndSelectedEqual, search, hasRadius]);

  return (
    <div ref={isLg ? null : divRef} className={isLg ? 'relative' : mobileModalClasses}>
      {isOpen && !isLg && <Header content={props.title ?? props.placeholder ?? ''} iconAfter="close" onAfter={onClose ?? undefined} labelAfter={t('inputs.category.modal.close', { ns: 'common' })} />}
      <label htmlFor={`select-${id}`} className={isOpen && isNative && !isLg ? '[&>*:nth-child(2)]:my-4' : ''}>
        <span className="sr-only">{props.placeholder}</span>
        <NewTextInput
          {...rest}
          ref={inputRef}
          id={`select-${id}`}
          placeholder={inputPlaceholder}
          value={inputValue}
          onClick={!isLg && isOpen ? undefined : onOpen}
          onChange={onSearch}
          onClean={onClean}
          autoComplete="off"
        />
      </label>
      {(isOpen || !isLg) && (
        <div ref={isLg ? divRef : null} className={dropdownClasses}>
          {items?.length === 0 && <p className="text-body-secondary p-3 text-content-primary">{t('inputs.city.modal.no-search-results', { ns: 'common' })}</p>}

          {isLoading && (
            <div aria-label={t('buttons.spinner.aria-valuetext', { ns: 'common' })} className="flex w-full items-center justify-center py-4">
              <Icons icon="spinner" color="button-default" size="24" />
            </div>
          )}

          {!isSearching && userItem && (
            <>
              <p className="text-caption-primary px-2 font-normal text-content-primary">{t('inputs.city.modal.user-item', { ns: 'common' })}</p>
              <ScrollDownItem key={userItem.getLoc()} type="button" icon="pin" content={userItem.getLabel() ?? ''} onClick={onSelect(userItem)} />
            </>
          )}

          {isSearching && !isLoading && items?.map(item => <ScrollDownItem key={item.getLoc()} type="button" icon="pin" content={item.getLabel() ?? ''} onClick={onSelect(item)} />)}

          {!isSearching && <LocateMeButton id={`locate-me-${props.type}`} onSelect={loc => onSelect(loc)()} />}

          {isLg && props.type === 'search' && hasRadius && !isSearching && (
            <>
              <Separator />
              <RadiusLoc min={0} max={100} onChange={onChangeDistance} step={5} value={(stagedItem ?? selectedItem)?.getLocationFormDistance() ?? 20} />
            </>
          )}

          {!isSearching && !isStageAndSelectedEqual && (
            <div className="mt-auto flex w-full justify-end">
              <Button onClick={onSubmit}>{t('inputs.city.modal.submit', { ns: 'common' })}</Button>
            </div>
          )}
        </div>
      )}

      {!isOpen && !isLg && props.type === 'search' && hasRadius && !isSearching && (
        <RadiusLoc min={0} max={100} onChange={onChangeDistance} step={5} value={(stagedItem ?? selectedItem)?.getLocationFormDistance() ?? 20} />
      )}
    </div>
  );
});

export default CityInput;
