import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { usePersistContext } from '@/core/lib/persist/persist.context';

export interface Values {
  BASE_URL: string;
  DONNONS_API_URL: string;
  DONNONS_GEO_API_URL: string;
  GTM_ID: string;
  GTM_AUTH: string;
  GTM_PREVIEW: string;
}

export interface ValuesPerEnv {
  preprod: Values;
  production: Values;
}

export type Environment = keyof ValuesPerEnv;

export interface EnvContextType extends Values {
  env: keyof ValuesPerEnv;
  onChangeEnv: (env: Environment) => void;
}

export const EnvContext = createContext<EnvContextType | null>(null);

export const useEnvContext = () => useContext(EnvContext) as EnvContextType;

export const valuesFromProcess = {
  preprod: {
    BASE_URL: process.env.NEXT_PUBLIC_PREPROD_BASE_URL ?? process.env.REACT_APP_PREPROD_BASE_URL ?? '',
    DONNONS_API_URL: process.env.NEXT_PUBLIC_PREPROD_DONNONS_API_URL ?? process.env.REACT_APP_PREPROD_DONNONS_API_URL ?? '',
    DONNONS_GEO_API_URL: process.env.NEXT_PUBLIC_PREPROD_DONNONS_GEO_API_URL ?? process.env.REACT_APP_PREPROD_DONNONS_GEO_API_URL ?? '',
    GTM_ID: process.env.NEXT_PUBLIC_PREPROD_GTM_ID ?? process.env.REACT_APP_PREPROD_GTM_ID ?? '',
    GTM_AUTH: process.env.NEXT_PUBLIC_PREPROD_GTM_AUTH ?? process.env.REACT_APP_PREPROD_GTM_AUTH ?? '',
    GTM_PREVIEW: process.env.NEXT_PUBLIC_PREPROD_GTM_PREVIEW ?? process.env.REACT_APP_PREPROD_GTM_PREVIEW ?? '',
  },
  production: {
    BASE_URL: process.env.NEXT_PUBLIC_PROD_BASE_URL ?? process.env.REACT_APP_PROD_BASE_URL ?? '',
    DONNONS_API_URL: process.env.NEXT_PUBLIC_PROD_DONNONS_API_URL ?? process.env.REACT_APP_PROD_DONNONS_API_URL ?? '',
    DONNONS_GEO_API_URL: process.env.NEXT_PUBLIC_PROD_DONNONS_GEO_API_URL ?? process.env.REACT_APP_PROD_DONNONS_GEO_API_URL ?? '',
    GTM_ID: process.env.NEXT_PUBLIC_PROD_GTM_ID ?? process.env.REACT_APP_PROD_GTM_ID ?? '',
    GTM_AUTH: process.env.NEXT_PUBLIC_PROD_GTM_AUTH ?? process.env.REACT_APP_PROD_GTM_AUTH ?? '',
    GTM_PREVIEW: process.env.NEXT_PUBLIC_PROD_GTM_PREVIEW ?? process.env.REACT_APP_PROD_GTM_PREVIEW ?? '',
  },
};

export const ENV = process.env.NEXT_PUBLIC_ENV ?? process.env.REACT_APP_ENV;

// eslint-disable-next-line import/no-mutable-exports
export let ACTUAL_ENV = ENV === 'production' ? 'production' : ('preprod' satisfies Environment);

const EnvProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [env, setEnv] = useState<Environment>(ENV === 'production' ? 'production' : 'preprod');
  const [values, setValues] = useState<Values>(ENV === 'production' ? valuesFromProcess.production : valuesFromProcess.preprod);

  const { get, set } = usePersistContext();

  const onChangeEnv = useCallback(async (e: Environment) => {
    const newEnv = valuesFromProcess[e];
    if (newEnv) {
      setEnv(e);
      setValues(newEnv);
      ACTUAL_ENV = e;
      await set('forcedEnv', e);
    }
  }, []);

  useEffect(() => {
    const persistedEnv = async () => {
      const fromStorage = await get('forcedEnv');

      if (fromStorage) {
        const storageEnv = valuesFromProcess[fromStorage as Environment];

        if (storageEnv) {
          setEnv(fromStorage as Environment);
          setValues(storageEnv);
          ACTUAL_ENV = fromStorage;
        }
      }
    };

    persistedEnv();
  }, []);

  const value = useMemo(
    () => ({
      ...values,
      env,
      onChangeEnv,
    }),
    [values, env],
  );

  return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
};

export default EnvProvider;
