import Bugsnag from '@bugsnag/js';
import React, { useEffect, useMemo } from 'react';

import type { NativeContextType } from '@/core/lib/native/native.context';
import { NativeContext } from '@/core/lib/native/native.context';

const NativeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useMemo(
    () =>
      ({
        isNative: false,
        platform: 'web',
        version: 'web',
        isKeyboardOpen: false,
        shouldHideKeyboard: false,
        setShouldHideKeyboard: () => {},
        hideKeyboard: () => Promise.resolve(),
      }) satisfies NativeContextType,
    [],
  );

  const isBugsnagStarted = Bugsnag.isStarted();

  useEffect(() => {
    if (isBugsnagStarted) {
      Bugsnag.addOnError(event => {
        event.addMetadata('device', {
          platform: 'web',
          version: 'web',
        });
      });
    }
  }, [isBugsnagStarted]);

  return <NativeContext.Provider value={value}>{children}</NativeContext.Provider>;
};

export default NativeProvider;
