import React, { createContext, useCallback, useContext, useMemo } from 'react';

export interface DocumentContextType {
  defineDocument: Document | null;
  onUpdateBodyClass: (className: string) => void;
}

export const DocumentContext = createContext<DocumentContextType | null>(null);

export const useDocumentContext = () => useContext(DocumentContext) as DocumentContextType;

const DocumentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const defineDocument = useMemo(() => {
    if (typeof document !== 'undefined') {
      return document;
    }
    return null;
  }, []);

  const onUpdateBodyClass = useCallback(
    (className: string) => {
      if (defineDocument) {
        defineDocument.body.className = className;
      }
    },
    [defineDocument],
  );

  const value = useMemo(() => {
    return {
      defineDocument,
      onUpdateBodyClass,
    };
  }, [defineDocument, onUpdateBodyClass]);

  return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
};

export default DocumentProvider;
