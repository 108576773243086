import type { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useMutation as useRqMutation } from '@tanstack/react-query';

import type { APIError } from '@/core/lib/fetch';

const useMutation = <TData = unknown, TError = APIError, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  return useRqMutation<TData, TError, TVariables, TContext>(options);
};

export default useMutation;
