import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import Button from '@/core/components/Button';
import type { CatalogueSearchSchema } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import { catalogueSearchSchema, useCatalogueSearchContext } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';
import Header from '@/core/components/Header';
import HintMessage from '@/core/components/HintMessage';
import Icons from '@/core/components/Icons';
import CategoryInput from '@/core/components/inputs/CategoryInput';
import CityInput from '@/core/components/inputs/CityInput';
import { NewTextInput } from '@/core/components/inputs/TextInput';
import Label from '@/core/components/Label';
import useForm from '@/core/hooks/useForm';
import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import useOnClickOutside from '@/core/hooks/useOnClickOutside';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useNativeContext } from '@/core/lib/native/native.context';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useScrollContext } from '@/core/lib/scroll/scroll.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const CatalogueSearchDialog: React.FC = () => {
  const { t } = useTranslationContext(['common']);
  const { data, isOpen, onClose } = useCatalogueSearchContext();
  const { send } = useAnalyticsContext();
  const { push } = useRouterContext();
  const { isNative, hideKeyboard } = useNativeContext();
  const { common } = AbstractRoute.useCommonParams();

  const dialogRef = useRef<HTMLDialogElement>(null);

  useOnClickOutside([dialogRef], () => {
    if (!isNative) {
      onClose();
    }
  });

  const { data: catalogue } = Store.catalogue.useCatalogue();
  const { invalidate: invalidateDonations } = Query.donations.useInvalidate();

  const { set } = useScrollContext();

  const [oldSearch, setOldSearch] = useState(catalogue?.search);

  useEffect(() => {
    if (!isEqual(oldSearch, catalogue?.search)) {
      setOldSearch(catalogue?.search);
    }
  }, [catalogue?.search]);

  const { control, errors, onSubmit, isValid, watch } = useForm<CatalogueSearchSchema, CatalogueSearchSchema>({
    schema: catalogueSearchSchema,
    mutationFn: mutationData => Promise.resolve(mutationData),
    onSuccess: async res => {
      await send({ event: AnalyticsEventType.SEARCH_AN_OBJECT, title: res.title ?? null, loc_name: res.loc?.label ?? null, category_name: res.cat?.label ?? null });
      set(JSON.stringify(oldSearch), 0);
      window.document.scrollingElement?.scrollTo({ top: 0 });
      push(
        new Routes.CatalogueRoute(
          {
            cat: res.cat?.alias,
            loc: res.loc?.loc,
            distance: res.loc?.distance,
            title: res.title ?? undefined,
          },
          common,
        ),
      );
      onClose();
    },
    values: data,
  });

  const [tmpSearch, setTmpSearch] = useState(catalogue?.search ?? null);

  const { data: count, isLoading: isCountLoading } = Store.catalogue.useCount(tmpSearch);

  useEffect(() => {
    const debouncedCb = debounce(formValue => setTmpSearch(formValue), 500);

    const subscription = watch(debouncedCb);

    return () => subscription.unsubscribe();
  }, [watch]);

  const isLg = useIsLg();

  useKeyDownHandler([], 'Enter', () => {
    if (!isNative && isOpen) {
      onSubmit();
    } else if (isNative && isOpen && document.activeElement?.id === 'title') {
      hideKeyboard();
    }
  });

  if (!isOpen) return null;

  const onCategoryNotFound = async (search: string) => {
    await send({ event: AnalyticsEventType.CATEGORY_OBJECT_NOT_FOUND, category_name: search });
  };

  const onHandleSubmit = () => {
    invalidateDonations();
    onSubmit();
  };

  return (
    <>
      <dialog
        className={`fixed left-0 top-0 z-[9999] flex h-dvh w-full flex-col items-center justify-start overflow-y-auto border-t border-stroke-secondary bg-bg-primary px-4 pb-safe-bottom ${isNative ? 'lg:top-0 lg:pb-0 lg:pt-10' : 'lg:top-19'} lg:h-auto lg:justify-center lg:overflow-visible lg:px-0`}
        aria-hidden={!isOpen}
        ref={dialogRef}
      >
        {!isLg && <Header content={t('search.title', { ns: 'common' })} iconBefore="arrow-left" onBefore={onClose} />}
        <div className="flex size-full flex-col gap-4 pb-7 sm:h-min lg:flex-row lg:px-6 xl:px-24">
          <form className="flex size-full flex-col justify-start gap-5 py-4 md:gap-4 lg:grid lg:grid-cols-4 lg:pb-8">
            <Controller
              control={control}
              render={({ field: { ref, onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.loc;
                return (
                  <div className="w-full">
                    <Label htmlFor="loc" content={t('search.dialog.loc.label', { ns: 'common' })}>
                      <CityInput
                        onChange={onChange}
                        onBlur={onBlur}
                        id="location"
                        value={value}
                        placeholder={t('search.dialog.loc.placeholder', { ns: 'common' })}
                        title={t('search.dialog.loc.title', { ns: 'common' })}
                        ref={ref}
                        type="search"
                      />
                      {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                    </Label>
                  </div>
                );
              }}
              name="loc"
            />

            <Controller
              control={control}
              defaultValue=""
              render={({ field: { ref, onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.title;
                return (
                  <div className="w-full">
                    <Label htmlFor="title" content={t('search.dialog.title.label', { ns: 'common' })}>
                      <NewTextInput
                        id="title"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        placeholder={t('search.dialog.title.placeholder', { ns: 'common' })}
                        inputMode="text"
                        validation={{ isError, isSuccess }}
                        ref={ref}
                        enterKeyHint="done"
                        noCheck
                      />
                      {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                    </Label>
                  </div>
                );
              }}
              name="title"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                const { isError, message, isSuccess } = errors.cat;
                return (
                  <div className="w-full">
                    <Label htmlFor="cat" content={t('search.dialog.cat.label', { ns: 'common' })}>
                      <CategoryInput
                        id="cat"
                        value={value ?? null}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={t('search.dialog.cat.placeholder', { ns: 'common' })}
                        ref={ref}
                        onNotFound={onCategoryNotFound}
                      />
                      {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                    </Label>
                  </div>
                );
              }}
              name="cat"
            />

            <div className="mt-auto min-w-max">
              <Button type="submit" onClick={onHandleSubmit} size={`${isLg ? 'medium' : 'large'}`} isDisabled={!isValid}>
                {isCountLoading ? (
                  <Icons icon="spinner" size="20" color="button-primary" weight="bold" />
                ) : (
                  <>
                    <Icons icon="search" size="20" color="button-primary" weight="bold" />
                    <span className="ml-2 inline-block text-content-button-primary">{t('search.dialog.search', { ns: 'common' })}</span>
                    {count ? <span>&nbsp;{t('search.dialog.n-results', { ns: 'common', total: count.getTotal().toString(), plural: count.getTotal() > 1 ? 's' : '' })}</span> : null}
                  </>
                )}
              </Button>
            </div>
          </form>
        </div>
        <button
          type="button"
          className="absolute -bottom-5 hidden size-10 items-center justify-center rounded-full bg-bg-button-primary-default lg:inline-flex"
          onClick={onClose}
          aria-label={t('search.dialog.close', { ns: 'common' })}
        >
          <Icons icon="arrow-up" weight="bold" size="20" color="button-primary" />
        </button>
      </dialog>

      <div className="fixed left-0 top-0 z-10 h-screen w-screen overflow-hidden bg-bg-dark opacity-50" />
    </>
  );
};

export default CatalogueSearchDialog;
