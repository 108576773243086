import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import renewOrRefresh from '@/core/lib/auth/renewOrRefresh';
import Actions from '@/core/lib/new-architecture/actions';
import Context from '@/core/lib/new-architecture/context';
import type { AuthTokens } from '@/core/lib/new-architecture/context/auth.context';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import { usePersistContext } from '@/core/lib/persist/persist.context';
import { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';

export const useLogin = () => {
  const { get, set } = usePersistContext();

  const { register } = Actions.push.useRegister();

  const { setTokens } = Context.auth.useAuthContext();

  const login = async (data: AuthTokens) => {
    const [localRefreshToken, localRefreshExpiresAt] = await Promise.all([get('refreshToken'), get('refreshExpiresAt')]);

    await Promise.all([
      data.accessToken ? set('accessToken', data.accessToken) : Promise.resolve(),
      data.accessExpiresAt ? set('accessExpiresAt', data.accessExpiresAt?.toISOString()) : Promise.resolve(),
      data.refreshToken ? set('refreshToken', data.refreshToken) : Promise.resolve(),
      data.refreshExpiresAt ? set('refreshExpiresAt', data.refreshExpiresAt.toISOString()) : Promise.resolve(),
    ]);

    setTokens(prev => ({
      accessToken: data.accessToken,
      accessExpiresAt: data.accessExpiresAt ?? null,
      refreshToken: data.refreshToken ?? prev.refreshToken ?? localRefreshToken,
      refreshExpiresAt: data.refreshExpiresAt ?? prev.refreshExpiresAt ?? (localRefreshExpiresAt ? dayjs(localRefreshExpiresAt) : null),
    }));

    await register();
  };

  return { login };
};

export const useLogout = () => {
  const { remove } = usePersistContext();
  const { push } = useRouterContext();
  const { reset } = Query.useReset();

  const { unregister } = Actions.push.useUnregister();

  const { isReady: _isReady, setTokens, setIsReady, ...tokens } = Context.auth.useAuthContext();

  const localTokens = useRef<AuthTokens | null>(tokens);

  useEffect(() => {
    // we only keep the tokens when the are updating to keep a local copy
    // at delete time
    if (tokens.accessToken) {
      localTokens.current = tokens;
    }
  }, [tokens]);

  const [logoutEmitted, emitLogout] = useState(false);

  useEffect(() => {
    const onLogoutEmitted = async () => {
      await reset();
      setIsReady(true);
      emitLogout(false);
    };

    if (logoutEmitted) {
      onLogoutEmitted();
    }
  }, [logoutEmitted]);

  const logout = async () => {
    setIsReady(false);
    setTokens({
      accessToken: null,
      refreshToken: null,
      accessExpiresAt: null,
      refreshExpiresAt: null,
    });
    emitLogout(true);

    window.dispatchEvent(new CustomEvent('donnons-logout'));

    await unregister(localTokens.current?.accessToken as string);
    localTokens.current = null;

    await Promise.all([remove('accessToken'), remove('accessExpiresAt'), remove('refreshToken'), remove('refreshExpiresAt'), remove('notificationReminder')]);
    push(new Routes.CatalogueRoute({}));
  };

  return { logout };
};

export const useModal = () => {
  const { isLoggedIn } = Store.auth.useRequest();
  const { replace, currentPage } = useRouterContext();
  const {
    common: { redirectTo, openAuth: openAuthParams },
  } = AbstractRoute.useCommonParams();

  const openAuth = () => {
    currentPage.updateCommon(prev => ({ ...prev, openAuth: true }));
    replace(currentPage);
  };

  const closeAuth = (success: boolean = false) => {
    if (redirectTo && success) {
      const url = new URL(`http://doesnotmatter.com${decodeURIComponent(redirectTo)}`);
      const Route = Object.values(Routes).find(route => route.test(url.pathname))!;
      const route = Route.init(url);
      route.updateCommon(prev => ({ ...prev, openAuth: undefined, redirectTo: undefined }));
      replace(route);
    } else {
      currentPage.updateCommon(prev => ({ ...prev, openAuth: undefined, redirectTo: undefined }));
      replace(currentPage);
    }
  };

  useEffect(() => {
    if (isLoggedIn && openAuthParams) {
      currentPage.updateCommon(prev => ({ ...prev, openAuth: undefined, redirectTo: undefined }));
      replace(currentPage);
    }
  }, [isLoggedIn, openAuthParams]);

  return { openAuth, closeAuth };
};

export const AuthActions: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { get, remove } = usePersistContext();

  const { setIsReady } = Context.auth.useAuthContext();

  const { login } = useLogin();

  const renew = Query.auth.useRenew({});
  const refresh = Query.auth.useRefresh({});

  useEffect(() => {
    const initAuth = async () => {
      const [localAccessToken, localAccessExpiresAt, localRefreshToken, localRefreshExpiresAt] = await Promise.all([
        get('accessToken'),
        get('accessExpiresAt'),
        get('refreshToken'),
        get('refreshExpiresAt'),
      ]);

      const { shouldRenew, shouldRefresh, isAccessValid, isRefreshValid } = renewOrRefresh({ accessExpiresAt: dayjs(localAccessExpiresAt), refreshExpiresAt: dayjs(localRefreshExpiresAt) });

      if (shouldRenew && localAccessToken) {
        renew.mutate(localAccessToken, {
          onSuccess: async data =>
            login({
              accessToken: data.access_token,
              accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
              refreshToken: data.refresh_token ?? null,
              refreshExpiresAt: data.refresh_expires_in ? dayjs().add(data.refresh_expires_in, 'second') : null,
            }),
          onError: async () => {
            await Promise.all([remove('accessToken'), remove('accessExpiresAt'), remove('refreshToken'), remove('refreshExpiresAt')]);
          },
          onSettled: () => setIsReady(true),
        });
      } else if (shouldRefresh && localRefreshToken) {
        refresh.mutate(localRefreshToken, {
          onSuccess: async data =>
            login({
              accessToken: data.access_token,
              accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
              refreshToken: data.refresh_token ?? null,
              refreshExpiresAt: data.refresh_expires_in ? dayjs().add(data.refresh_expires_in, 'second') : null,
            }),
          onError: async () => {
            await Promise.all([remove('accessToken'), remove('accessExpiresAt'), remove('refreshToken'), remove('refreshExpiresAt')]);
          },
          onSettled: () => setIsReady(true),
        });
      } else if (!shouldRenew && !shouldRefresh && localAccessToken && isAccessValid) {
        await login({
          accessToken: localAccessToken,
          accessExpiresAt: dayjs(localAccessExpiresAt),
          refreshToken: isRefreshValid && localRefreshToken ? localRefreshToken : null,
          refreshExpiresAt: isRefreshValid && localRefreshExpiresAt ? dayjs(localRefreshExpiresAt) : null,
        });
        setIsReady(true);
      } else {
        setIsReady(true);
      }
    };

    initAuth();
  }, []);

  return children;
};
