import type { QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery as useRqQuery } from '@tanstack/react-query';

import type { APIError } from '@/core/lib/fetch';

const useQuery = <TQueryFnData = unknown, TError = APIError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> => {
  return useRqQuery<TQueryFnData, TError, TData, TQueryKey>(options);
};

export default useQuery;
