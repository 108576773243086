import { useEffect, useMemo } from 'react';

import { ErrorKind } from '@/core/lib/fetch';
import Actions from '@/core/lib/new-architecture/actions';
import CurrentDomain from '@/core/lib/new-architecture/domain/current.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const useCurrent = (): StoreData<CurrentDomain> => {
  const { logout } = Actions.auth.useLogout();
  const { requestToken, isLoggedIn, isReady } = Store.auth.useRequest();

  const enabled = !!isLoggedIn && isReady;

  const {
    data,
    isLoading: currentIsLoading,
    isError: currentIsError,
    isSuccess: currentIsSuccess,
    error,
  } = Query.user.useCurrent({
    token: requestToken,
    enabled,
  });

  useEffect(() => {
    if (error?.kind === ErrorKind.Unauthorized) {
      logout();
    }
  }, [error]);

  const search = useMemo(() => {
    if (!data) return undefined;

    const sub = data.subs?.find(c => c.id === data.sub);

    if (sub) {
      return sub.alias;
    }

    const city = data.cities?.find(c => c.id === data.city);

    if (city) {
      return city.alias;
    }

    return undefined;
  }, [data]);

  const { data: loc } = Store.locs.useDetail(search);

  const {
    data: control,
    isLoading: controlIsLoading,
    isError: controlIsError,
    isSuccess: controlIsSuccess,
  } = Query.user.useControl({
    token: requestToken,
    enabled,
  });

  const {
    data: contacts,
    isLoading: contactsIsLoading,
    isError: contactsIsError,
    isSuccess: contactsIsSuccess,
  } = Query.user.useContacts({
    token: requestToken,
    enabled,
  });

  const queriesAreNotInSuccess = !currentIsSuccess && !controlIsSuccess && !contactsIsSuccess;
  const queriesAreNotInError = !currentIsError && !controlIsError && !contactsIsError;
  const queriesAreLoading = currentIsLoading || controlIsLoading || contactsIsLoading;

  const isLoading = (enabled && queriesAreNotInError && queriesAreNotInSuccess) || queriesAreLoading;

  const user = useMemo(() => {
    if (!data) return null;

    return new CurrentDomain({ data, control, contacts, loc });
  }, [data, control, contacts, loc]);

  return {
    data: user,
    isLoading,
    notFound: false,
  };
};

export default { useCurrent };
