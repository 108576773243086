import NextLink from 'next/link';
import React from 'react';

import { useAuthContext } from '@/core/lib/auth/auth.context';
import { type LinkProps, useRouterContext } from '@/core/lib/router/router.context';

const Link: React.FC<LinkProps> = ({ href, className, children, shallow, ...aria }) => {
  const { currentPage } = useRouterContext();
  const { isLoggedIn } = useAuthContext();

  if (href.isPrivate() && !isLoggedIn) {
    currentPage.updateCommon(prev => ({ ...prev, openAuth: true, redirectTo: href.resolve() }));
    return (
      <NextLink href={currentPage.resolve()} className={className} {...aria} prefetch={false} shallow={shallow}>
        {children}
      </NextLink>
    );
  }

  return (
    <NextLink href={href.resolve()} className={className} {...aria} prefetch={false} shallow={shallow}>
      {children}
    </NextLink>
  );
};

export default Link;
