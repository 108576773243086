import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const ChevronRight: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" className={className}>
    <path d="M15 30L25 20L15 10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronRight;
