import React from 'react';

import type { IconProps } from '@/core/components/Icons/Icons';

const Spinner: React.FC<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`${className} animate-spin !stroke-[0.5]`}>
    <path d="M1.2 12C0.537258 12 -0.0062278 11.4614 0.0599168 10.802C0.335015 8.05935 1.54845 5.48098 3.51472 3.51472C5.48099 1.54845 8.05935 0.335014 10.802 0.0599166C11.4614 -0.00622788 12 0.537258 12 1.2C12 1.86274 11.4607 2.39225 10.8031 2.47487C8.69832 2.73931 6.72767 3.69588 5.21177 5.21177C3.69588 6.72767 2.73931 8.69832 2.47487 10.8031C2.39225 11.4607 1.86274 12 1.2 12Z" />
  </svg>
);

export default Spinner;
