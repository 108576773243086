import { useMemo } from 'react';

import UserDomain from '@/core/lib/new-architecture/domain/user.domain';
import Query from '@/core/lib/new-architecture/query';
import Store from '@/core/lib/new-architecture/store';
import type { StoreData } from '@/core/lib/new-architecture/store/store.types';

const usePublicProfile = (uuid?: string): StoreData<UserDomain> => {
  const { requestToken } = Store.auth.useRequest();

  const { data: current, isLoading: isCurrentLoading } = Store.current.useCurrent();
  const { data: catalogue, isLoading: isDonationsLoading } = Store.catalogue.useCatalogue({ giver: uuid });
  const donations = catalogue?.donations;

  const { data: publicUser, isLoading: isPublicLoading } = Query.user.usePublicProfile({ data: { uuid }, token: requestToken });

  const isLoading = isCurrentLoading || isPublicLoading || isDonationsLoading;

  const data = useMemo(() => {
    if (!publicUser) {
      return null;
    }

    return new UserDomain({ data: publicUser.uuid === current?.uuid ? current.data : publicUser, current, donations: donations ?? [] });
  }, [publicUser, current, donations]);

  const notFound = useMemo(() => !publicUser && !isLoading, [publicUser, isLoading]);

  return {
    data,
    isLoading,
    notFound,
  };
};

export default { usePublicProfile };
