import React, { useMemo } from 'react';

import type { IconName, IconSize } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import type { LinkProps as DefaultLinkProps } from '@/core/lib/router/router.context';
import { useRouterContext } from '@/core/lib/router/router.context';

export type LinkVariant = 'primary' | 'secondary' | 'ghost' | 'underline-primary' | 'no-underline-primary';

export type LinkSize = 'small' | 'medium' | 'large';

const BASE_LINK_CLASSES = 'relative flex items-center justify-center text-center border-solid border-1.5 focus-visible:outline-none';

const variants = {
  primary: `${BASE_LINK_CLASSES} bg-bg-button-primary-default text-content-button-primary border-stroke-button-primary-default hover:bg-bg-button-primary-hover hover:border-stroke-button-primary-hover focus-visible:bg-bg-button-primary-pressed focus-visible:border-stroke-button-primary-pressed`,
  secondary: `${BASE_LINK_CLASSES} bg-bg-button-secondary text-content-button-secondary-default border-stroke-button-secondary-default hover:bg-bg-button-secondary-hover hover:border-stroke-button-secondary-hover hover:text-content-button-secondary-hover focus-visible:border-stroke-button-secondary-pressed focus-visible:text-content-button-secondary-pressed`,
  ghost: `${BASE_LINK_CLASSES} bg-bg-button-ghost text-content-button-ghost-default border-transparent hover:bg-bg-button-ghost-hover hover:border-stroke-button-ghost-hover`,
  'underline-primary': 'text-body-secondary text-content-secondary underline !p-0',
  'no-underline-primary': 'text-body-secondary text-content-secondary !p-0',
} satisfies { [key in LinkVariant]: string };

const sizes = {
  small: 'text-button-small font-medium rounded-1',
  medium: 'text-button-medium font-medium rounded-1',
  large: 'text-button-large font-semibold rounded-2',
} satisfies { [key in LinkSize]: string };

export interface LinkProps extends Omit<DefaultLinkProps, 'className'> {
  variant?: LinkVariant;
  size?: LinkSize;
  iconLeft?: IconName;
  iconRight?: IconName;
}

const Link: React.FC<LinkProps> = ({ variant = 'primary', size = 'large', children, href, iconLeft, iconRight }) => {
  const { Link: RouterLink } = useRouterContext();

  const paddingClasses = `${size === 'small' && 'p-1'} ${size === 'medium' && 'px-3 py-2'} ${size === 'large' && 'px-8 py-3'}`;

  const computedClasses = useMemo(() => {
    const variantClass = variants[variant];
    const sizeClass = sizes[size];

    return [variantClass, sizeClass].join(' ');
  }, [variant, size]);

  let iconSize: IconSize | undefined;
  if (size === 'large') {
    iconSize = '20';
  } else if (size === 'medium') {
    iconSize = '16';
  } else {
    iconSize = '12';
  }

  return (
    <RouterLink href={href} className={`${computedClasses} ${paddingClasses}`}>
      {iconLeft && (
        <span data-testid="left-icon">
          <Icons icon={iconLeft} weight="bold" color="current" size={iconSize} />
        </span>
      )}
      <span className={`${iconLeft || iconRight ? `${size === 'large' ? 'mx-2' : 'mx-1'}` : ''} inline-flex items-center justify-center text-nowrap`}>{children}</span>
      {iconRight && (
        <span data-testid="right-icon">
          <Icons icon={iconRight} weight="bold" color="current" size={iconSize} />
        </span>
      )}
    </RouterLink>
  );
};

export default Link;
