import type { CatalogueSearchSchema } from '@/core/components/CatalogueSearch/CatalogueSearchProvider';

export interface ModalValues {
  text: string;
  alt: string;
  logo: string;
}

interface ModalDomainInterface {
  id: string;
  getCityCodes: () => string[];
  getValues: () => ModalValues;
}

const maubeugeDomain = {
  id: 'maubeuge',
  getCityCodes: () => {
    return [
      '59003', // Aibes
      '59076', // Bettignies
      '59142', // Cerfontaine
      '59187', // Eclaibes
      '59264', // Gognies Chaussee
      '59467', // Pont Sur Sambre
      '59618', // Vieux Reng
      '59627', // Villers Sire Nicole
      '59104', // Boussois
      '59188', // Ecuelin
      '59190', // Elesmes
      '59225', // Feignies
      '59291', // Hautmont
      '59385', // Marpent
      '59392', // Maubeuge
      '59495', // Recquignies
      '59542', // St Remy Chaussee
      '59617', // Vieux Mesnil
      '59033', // Aulnoye Aymeries
      '59068', // Berlaimont
      '59101', // Bousignies Sur Roc
      '59157', // Cousolre
      '59324', // Jeumont
      '59365', // Louvroil
      '59439', // Noyelles Sur Sambre
      '59543', // St Remy Du Nord
      '59041', // Bachant
      '59072', // Bersillies
      '59231', // Ferriere La Petite
      '59406', // Monceau St Waast
      '59424', // Neuf Mesnil
      '59442', // Obrechies
      '59514', // Rousies
      '59556', // Sassegnies
      '59021', // Assevent
      '59058', // Beaufort
      '59103', // Boussieres Sur Sambre
      '59151', // Colleret
      '59230', // Ferriere La Grande
      '59344', // Leval
      '59351', // Limont Fontaine
      '59370', // Mairieux
      '59483', // Quievelon
    ];
  },
  getValues: () => {
    return {
      text: 'Votre agglomération Maubeuge-Val de Sambre agit pour la réduction et la valorisation des déchets sur le territoire\n\nTous les objets disponibles sur cette appli sont gratuits !',
      logo: '/assets/circular/maubeuge.png',
      alt: 'Logo de Maubeuge',
    };
  },
} satisfies ModalDomainInterface;

const graulhetDomain = {
  id: 'graulhet',
  getCityCodes: () => {
    return [
      '81105', // Graulhet
    ];
  },
  getValues: () => {
    return {
      text: "Bonjour !\n\nBienvenue sur Donnons Graulhet\n\nUne application simple et citoyenne pour une communauté locale de dons d'objets.\n\nZéro euro, que de bonnes pratiques durables et écolos.\n\nOn recycle, on se rend service et on s’entraide.\n\nGraulhet, une ville durable et responsable.",
      logo: '/assets/circular/graulhet.png',
      alt: 'Logo de Graulhet',
    };
  },
} satisfies ModalDomainInterface;

const wimilleDomain = {
  id: 'wimille',
  getCityCodes: () => {
    return [
      '62894', // Wimille
    ];
  },
  getValues: () => {
    return {
      text: 'Bonjour ! 🙂\n\nBienvenue sur Donnons Wimille, une application qui nous met en lien dans une belle énergie.\n\nDons d’objets, c’est ICI !\n\nTous les objets disponibles sur cette appli sont gratuits !',
      logo: '/assets/circular/wimille.png',
      alt: 'Logo de Wimille',
    };
  },
} satisfies ModalDomainInterface;

const cannesDomain = {
  id: 'cannes',
  getCityCodes: () => {
    return [
      '06029', // Cannes
      '06030', // Le Cannet
      '06079', // Mandelieu La Napoule
      '06085', // Mougins
      '06138', // Theoule Sur Mer
    ];
  },
  getValues: () => {
    return {
      text: 'L’agglomération Cannes Lérins s’engage pour donner une seconde vie à vos objets et favoriser l’entraide locale.\n\nNe jetez plus, donnez, récupérez, partagez !',
      logo: '/assets/circular/cannes.png',
      alt: 'Logo de Cannes',
    };
  },
} satisfies ModalDomainInterface;

const paysDeLorDomain = {
  id: 'paysDeLor',
  getCityCodes: () => {
    return [
      '34050', // Candillargues
      '34127', // Lansargues
      '34154', // Mauguio
      '34176', // Mudaison
      '34192', // Palavas Les Flots
      '34240', // St Aunes
      '34321', // Valergues
      '34344', // La Grande Motte
    ];
  },
  getValues: () => {
    return {
      text: 'Bienvenue sur Donnons Pays de l’Or, une application vertueuse ouverte à tous les habitants de l’agglomération !\n\nRejoignez notre action solidaire en faveur du réemploi.\n\nNe jetons plus, Donnons !',
      logo: '/assets/circular/paysDeLor.png',
      alt: "Logo de Pays de L'or",
    };
  },
} satisfies ModalDomainInterface;

const rhonyVistreVidourleDomain = {
  id: 'rhonyVistreVidourle',
  getCityCodes: () => {
    return [
      '30004', // Aigues Vives
      '30019', // Aubais
      '30043', // Boissieres
      '30083', // Codognan
      '30123', // Gallargues Le Montueux
      '30185', // Mus
      '30186', // Nages Et Solorgues
      '30333', // Uchaud
      '30344', // Vergeze
      '30347', // Vestric Et Candiac
    ];
  },
  getValues: () => {
    return {
      text: 'Bienvenue sur Donnons Communauté de communes Rhôny-Vistre Vidourle, une plateforme qui nous met en lien dans une belle énergie.\n\nTous les objets disponibles sur cette appli sont gratuits !',
      logo: '/assets/circular/rhonyVistreVidourle.png',
      alt: 'Logo de Rhony Vistre Vidourle',
    };
  },
} satisfies ModalDomainInterface;

export interface CircularDomainInterface {
  modal: (search: CatalogueSearchSchema) => ModalDomainInterface | null;
}

class CircularDomain implements CircularDomainInterface {
  private map: Map<string, ModalDomainInterface>;

  constructor() {
    this.map = new Map([
      ...maubeugeDomain.getCityCodes().map(cityCode => [cityCode, maubeugeDomain] as [string, ModalDomainInterface]),
      ...graulhetDomain.getCityCodes().map(cityCode => [cityCode, graulhetDomain] as [string, ModalDomainInterface]),
      ...wimilleDomain.getCityCodes().map(cityCode => [cityCode, wimilleDomain] as [string, ModalDomainInterface]),
      ...cannesDomain.getCityCodes().map(cityCode => [cityCode, cannesDomain] as [string, ModalDomainInterface]),
      ...paysDeLorDomain.getCityCodes().map(cityCode => [cityCode, paysDeLorDomain] as [string, ModalDomainInterface]),
      ...rhonyVistreVidourleDomain.getCityCodes().map(cityCode => [cityCode, rhonyVistreVidourleDomain] as [string, ModalDomainInterface]),
    ]);
  }

  public modal(search: CatalogueSearchSchema): ModalDomainInterface | null {
    // when the geo api will return the city code, check the city code

    if (search.loc?.refId) {
      const res = this.map.get(search.loc.refId);

      if (res) {
        return res;
      }
    }

    return null;
  }
}

export default CircularDomain;
