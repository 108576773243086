export const getCurrentPosition = () =>
  new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

export const watchPosition = () =>
  new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.watchPosition(resolve, reject);
  });

export const getPermission = async () => {
  if ('permissions' in navigator) {
    return navigator.permissions.query({ name: 'geolocation' });
  }

  return { state: 'denied' };
};
